import React, { useState, useRef } from "react";
import { withNamespaces } from "react-i18next";

// Components
import Slider from "react-slick";
import CenteredTitle from "@components/partials/CenteredTitle";
import BreadCrumb from "@components/partials/BreadCrumbs";

// Images
import seatsImg from "@assets/pictures/about-us.png";
import seatsImg1 from "@assets/pictures/about-us-1.png";
import seatsImg2 from "@assets/pictures/about-us-2.png";
import seatsImg3 from "@assets/pictures/about-us-3.png";
import seatsImg4 from "@assets/pictures/about-us-4.png";

const AboutUsHowItWorks = (props) => {
  const { t, classes = "" } = props;
  const [swiping, setSwiping] = useState(false);
  const howItWorksSliderRef = useRef();
  const stepsLength = 4;

  const handleSwipe = () => {
    setSwiping(true);
  };

  const handleClick = () => {
    if (swiping) {
      setSwiping(false);
    } else {
      // Click actions here
    }
  };

  // Slider Settings
  const heroSlider = {
    pauseOnFocus: true,
    onSwipe: handleSwipe,
    arrows: true,
    dots: false,
    infinite: true,
    useTransform: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  let images = [
    seatsImg1,
    seatsImg2,
    seatsImg3,
    seatsImg4
  ];

  return (
    <>
    
      <section className={`container-fluid about_us_how_it_works ${classes}`}>
      <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t('AboutUs.howItWorksHeader')}/>
        <CenteredTitle firstHalf={t('AboutUs.howItWorksHeader')} secondHalf={''} />
        <div className="row">
          <article className="col-xl-10 ml-auto">
            <section className="row">
              <article className="col-md-6 mb-4 mb-md-0">
                <p className="about_us_how_it_works_synopsis">
                  {t('AboutUs.howItWorksText')}
                </p>
                <Slider {...heroSlider} ref={howItWorksSliderRef}>
                  {images.map((item, index) => (
                    <article key={index}>
                      <figure onClick={handleClick}>
                        <img src={item} alt="" />
                      </figure>
                    </article>
                  ))}
                </Slider>
              </article>
             <article className="col-md-6">
                <ul className="about_us_how_it_works_steps list-unstyled">
                  <li>
                    <h6 onClick={() => howItWorksSliderRef.current?.slickGoTo(0)}>
                      {t("common.Select Movie")}
                    </h6>
                    <p>
                      {t('AboutUs.SelectMoviesMessage')}
                    </p>
                  </li>
                  <li>
                    <h6 onClick={() => howItWorksSliderRef.current?.slickGoTo(1)}>
                      {t('Select Date, Language & Time Slots')}
                    </h6>
                    <p>
                      {t('AboutUs.SelectDateTimeMessage')}
                    </p>
                  </li>
                  <li>
                    <h6 onClick={() => howItWorksSliderRef.current?.slickGoTo(2)}>
                      {t('AboutUs.PreOrderBeverages')}
                    </h6>
                    <p>
                      {t('AboutUs.PreOrderBeveragesMessage')}
                    </p>
                  </li>
                  <li>
                    <h6 onClick={() => howItWorksSliderRef.current?.slickGoTo(3)}>
                      {t('Checkout')}
                    </h6>
                    <p>
                      {t('AboutUs.CheckoutMessage')}
                    </p>
                  </li>
                </ul>
                  </article>
            </section>
          </article>
        </div>
      </section>
    </>
  );
};

export default withNamespaces()(AboutUsHowItWorks);
