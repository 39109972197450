import React, { useEffect, useState, useLayoutEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import useIsMobile from "../../customHooks/useIsMobile";
import { getLangSpecificAttribute } from "@helper/languages";
import { useSelector, useDispatch } from "react-redux";
import { motion } from "framer-motion";

// Components
import Slider from "react-slick";

// Images
import banner from "@assets/pictures/sample-banner-new.png";
import placeholderBanner from "@assets/pictures/banner-placeholder-new.png";
import reel from "@assets/pictures/svgs/reel.svg";
import clockWhite from "@assets/pictures/svgs/clock-white.svg";

const Banner = (props) => {
  const { t, banners, isLoading, showOnly = null } = props;
  const isMobile = useIsMobile();
  const history = useHistory();
  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );
  const { iso_2, lang_id, lang_name } = selected_language;
  const {pathname} = useLocation();

  // STATE
  const [swiping, setSwiping] = useState(false);

  // Slider Settings
  const heroSlider = {
    pauseOnFocus: true,
    onSwipe: () => setSwiping(true),
    arrows: true,
    dots: true,
    infinite: true,
    useTransform: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [loading, setLoading] = useState(true);
  const bannersLoading = useSelector((state) => state.homepage.bannersLoading);
  useLayoutEffect(() => {
    if (!banners || !!bannersLoading) {
      setLoading(true)
    }
    else {
      window.scrollTo(0, 0);
      setLoading(false)
    }
  }, [banners, bannersLoading])

  const handleBannerClick = (banner) => {
    console.log("handleBannerClick", banner)
    if (swiping) {
      setSwiping(false);
    } else {
      if (banner?.movie_id) {
        if (banner?.movie_banner_type_id?.split(',')?.includes('1') && showOnly !== 2) {
          // history.push(
          //   `/movie-details/${banner?.movie_id}/${banner?.content_lang_id}/`
          // );
          history.push(
            `/now-showing-booking/${banner?.movie_id}/${banner?.content_lang_id}/`
          );
        } else if (banner?.movie_banner_type_id?.split(',')?.includes('2') && showOnly !== 1) {
          history.push(`/private-booking-flow/${banner?.movie_id}/${banner?.content_lang_id}/`)
        } else if (banner?.movie_banner_type_id?.split(',')?.includes('3') && showOnly !== 1) {
          history.push(`/movie-details/${banner?.movie_id}/${banner?.content_lang_id}/3`)
        } else {
          history.push(
            `/now-showing-booking/${banner?.movie_id}/${banner?.content_lang_id}/`
          );
        }
      }
      else if (banner?.banner_generic_url) {
        window.location.href = (banner.banner_generic_url)
      }
      // Actual Click Actions

    }
  }
  return (
    <>
      <Slider {...heroSlider} className="home_banner">
        {banners?.length > 0 && banners?.map((banner, index) => (
          <div className="" key={index}>
            <section className="hero-section" onClick={() => handleBannerClick(banner)}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}>
              <article className="hero-img-section">
                <img
                  src={getLangSpecificAttribute(
                    banner?.languageSpecificImages,
                    lang_id,
                    "banner_image_url",
                    "lang_id"
                  ) || placeholderBanner}
                  alt="Movie Banner"
                  className="hero-img-1"
                />
              </article>
              <article className="hero-text align-items-end"
              >
                <div className="container-fluid">
                  {banner && banner.movie_id && !(pathname === "/now-showing" || pathname === "/new-on-demand" || pathname === "/upcoming") &&  (
                    banner?.movie_banner_type_id?.split(',')?.includes('1') && showOnly !== 2 && showOnly !== 3 ? (<h6>
                      {t("Now Showing")}
                    </h6>) : banner?.movie_banner_type_id?.split(',')?.includes('2') && showOnly !== 1 && showOnly !== 3 ? (
                      <h6 className="text-warning">
                        {t('On-demand')}
                      </h6>
                    ) : banner?.movie_banner_type_id?.split(',')?.includes('3') && showOnly !== 1 && showOnly !== 2 ? (
                      <h6>
                        {t("Upcoming")}
                      </h6>
                    ) : (
                      <></>
                    )
                  )}
                  <h1>
                    {getLangSpecificAttribute(
                      banner?.languageSpecificImages,
                      lang_id,
                      "banner_header",
                      "lang_id"
                    )}
                  </h1>
                  {!isMobile && banner && !banner.movie_id && (
                    <p
                      className="text-limit-1 mb-md-3"
                      id={`desc${index}`}
                    >
                      {/* {banner?.banner_subheader} */}
                      {getLangSpecificAttribute(
                        banner?.languageSpecificImages,
                        lang_id,
                        "banner_subheader",
                        "lang_id"
                      ) || banner?.banner_subheader}
                    </p>
                  )}
                  {!isMobile && banner.movie_id && <div className={
                    `home_banner_movie_versions ${
                      (
                        !(banner?.movie_banner_type_id?.split(',')?.includes('1')) &&
                        banner?.movie_banner_type_id?.split(',')?.includes('2') && showOnly !== 1
                      ) ?
                        "home_banner_movie_versions_on_demand" 
                        : ""
                    }`
                  }>
                    {banner?.languages_with_translations?.map((item, index) => (
                      item[iso_2] || item?.lang_name
                    ))
                      .filter((item, pos, arr) => arr.indexOf(item) == pos)
                      .map((item, index, arr) => (
                        <span key={index}>{t(item)}</span>
                      ))
                    }
                  </div>}
                </div>
              </article>
            </section>
          </div>
        ))}


        {(!!loading || (!banners || banners?.length === 0)) && (<>
          <div className="">
            <section className="hero-section" onClick={() => { }}>
              <article className="hero-img-section">
                <img
                  src={placeholderBanner}
                  alt="Movie Banner"
                  className="hero-img-1"
                />
              </article>
              <article className="hero-text align-items-end"
              >
                <div className="container-fluid">
                  <div className="container-fluid container-xl text-center">
                    <section className="placeholder-banner">
                      <figure className="">
                        <img src={reel} alt="" />
                      </figure>
                      <p className="">
                        {/*<img src={clockWhite} alt="" className="mr-2" />*/}
                        {/*{t("Loading")} ...*/}
                      </p>
                    </section>
                  </div>
                </div>
              </article>
            </section>
          </div>
        </>)}
      </Slider>
    </>
  );
}

export default withNamespaces()(Banner);
