import React, { useState, useContext, useEffect } from "react";
import MovieShow from "./partials/movieShow.jsx";
import ScreeningCard from "./partials/screeningCard.jsx";
import ScreeningCardNew from "./partials/screeningCardNew.jsx";

import DatePicker from "react-date-picker";
// import DatePicker from 'react-date-picker/dist/entry.nostyle';

import ShowDetailsPopup from "./partials/popups/showDetailsPopup.jsx";
import SeatTypePopup from "./partials/popups/SeatTypePopup.jsx";
import {ReactComponent as ArrowBack} from "@assets/pictures/svgs/arrow-back.svg";
import {ReactComponent as ArrowBackActive} from "@assets/pictures/svgs/arrow-back-active.svg";
import calendarWhite from "@assets/pictures/svgs/calendar-white.svg";
import crowd from "@assets/pictures/svgs/crowd.svg";
import screenSl from "@assets/pictures/svgs/screen-sl.svg";
import standardSeat from "@assets/pictures/svgs/seat-standard.svg";
import loungerSeat from "@assets/pictures/svgs/seat-lounger.svg";
import selectedSeat from "@assets/pictures/svgs/seat-standard-selected.svg";
import unavailableSeat from "@assets/pictures/svgs/seat-standard-unavailable.svg";
import infoCircle from "@assets/pictures/svgs/info-circle.svg";
import cinemaBanner from "@assets/pictures/cinema-banner.png";

import poster2 from "@assets/pictures/poster-2.png";

import { slImageOnhover } from "../helper/formatting.js";

import {
  Row,
  Container,
  Col,
  Dropdown,
  Modal,
  Alert,
  Spinner,
} from "react-bootstrap";
import Slider from "react-slick";

export default function Checkout() {
  const [modalShow, setModalShow] = useState(false);
  const [value, onChange] = useState(new Date());
  const [calendarPopup, setCalendarPopup] = useState(false);
  const [seatSelector, setSeatSelector] = useState(null);
  const [back, setBack] = useState(false);

  const [stage, setStage] = useState(3);

  const dateSlider = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
  };

  const showDetails = {
    movieImg: poster2,
    tags: ["Family", "Children", "3D MAX"],
    features: ["2019", "FSK 18", "1h10min", "DE"],
    languages: ["English", "French", "Spanish", "Greek"],
    formats: ["2D", "3D", "IMAX"],
  };

  useEffect(slImageOnhover);

  return (
    <div>
      <ShowDetailsPopup
        show={modalShow}
        showDetails={showDetails}
        closeFunc={setModalShow}
        onHide={() => setModalShow(false)}
      />

      <div className="container-fluid container-xl header-space">
        <p className="mx-auto big-heading text-center">
          Select Time of your Movie
        </p>

        <section className="row justify-content-center for-prev-step">
          {stage !== 1 && (
            <div className="prev-step">
              <button
                className="btn-main mx-auto mt-3 mt-xl-4"
                onClick={() => setStage(stage - 1)}
                onMouseOver={() => setBack(true)}
                onMouseOut={() => setBack(false)}
              >
                {/* <img src={back ? arrowBackActive : arrowBack} className="mr3" /> */}
                {/* Back */}
                {back ? <ArrowBackActive/> : <ArrowBack/>}
              </button>
            </div>
          )}
          <div className="col-sm-9">
            <div className="steps">
              <div
                className={`step ticket ${
                  stage === 1
                    ? "complete"
                    : stage === 2
                    ? "complete"
                    : stage === 3
                    ? "complete"
                    : ""
                }`}
              >
                {/* <img src={ticketRed} /> */}
              </div>
              <div
                className={`step screen ${
                  stage === 2 ? "complete" : stage === 3 ? "complete" : ""
                }`}
              >
                {/* <img src={ticketRed} /> */}
              </div>
              <div className={`step seat ${stage === 3 ? "complete" : ""}`}>
                {/* <img src={ticketRed} /> */}
              </div>
            </div>
          </div>
        </section>

        {stage === 1 && (
          <>
            <section className="row date-picker-section">
              <div className="col-xl-5 mb-4 mb-xl-0 px-xl-4">
                <Slider {...dateSlider} className="date-slider">
                  {[1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
                    <div>
                      <div className="">
                        <span key={index} className="date-section">
                          <p className="month">Mar</p>
                          <p className="date">05</p>
                          <p className="day">Tuesday</p>
                        </span>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>

              <div className="col-xl-7">
                <div className="d-flex justify-content-between align-items-center h-100">
                  <div className="config">
                    <DatePicker
                      onChange={onChange}
                      value={value}
                      className="date-picker-popup"
                      isOpen={calendarPopup}
                    />
                    <img src={calendarWhite} className="md-icon mr-2 mr-md-3" />
                    <span
                      className="title"
                      onClick={() => {
                        calendarPopup
                          ? setCalendarPopup(false)
                          : setCalendarPopup(true);
                      }}
                    >
                      Calendar
                    </span>
                  </div>

                  <div className="config">
                    <div className="dropdown-bg">
                      <Dropdown className="multiselect">
                        <Dropdown.Toggle
                          className="dropdownClassic"
                          id="dropdown-basic"
                        >
                          <span className="title">Choose Cinema</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu align="right" className="dropdownItems">
                          <Dropdown.Item href="#/action-1" className="">
                            ENGLISH
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2" className="">
                            SANSKRIT
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">ARAB</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>

                  <div className="config d-none d-md-flex">
                    {/*<div className="dropdown-bg">
                      <Dropdown className="multiselect">
                        <Dropdown.Toggle
                          className="dropdownClassic"
                          id="dropdown-basic"
                        >
                          <span className="title">English 2D</span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu align="right" className="dropdownItems">
                          <Dropdown.Item href="#/action-1" className="">
                            ENGLISH
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2" className="">
                            SANSKRIT
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">ARAB</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>*/}
                    <span
                      className="title show-details-trigger"
                      onClick={() => setModalShow(true)}
                    >
                      English 2D
                    </span>
                  </div>

                  {/* fixed settings icon for mobile starts */}
                  <div
                    className="settings-icon"
                    onClick={() => setModalShow(true)}
                  >
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.5 0C12.2239 0 12 0.223857 12 0.5V3.627C11.0002 3.95 10.0533 4.39081 9.17575 4.93309L7.16115 2.91853C6.9659 2.72327 6.6493 2.72327 6.45405 2.91853L2.21142 7.16115C2.01616 7.35645 2.01616 7.673 2.21142 7.8683L4.4231 10.08C3.95389 10.9957 3.59014 11.9743 3.34787 13H0.5C0.223857 13 0 13.2239 0 13.5V19.5C0 19.7761 0.223857 20 0.5 20H3.627C3.84184 20.6651 4.10881 21.3066 4.4231 21.92L2.21142 24.1318C2.01616 24.327 2.01616 24.6436 2.21142 24.8389L6.45405 29.0814C6.6493 29.2767 6.9659 29.2767 7.16115 29.0814L9.17575 27.0669C10.0533 27.6092 11.0002 28.05 12 28.373V31.5C12 31.7761 12.2239 32 12.5 32H18.5C18.7761 32 19 31.7761 19 31.5V28.6521C20.0257 28.4098 21.0043 28.0461 21.92 27.5769L24.1318 29.7886C24.327 29.9839 24.6436 29.9839 24.8389 29.7886L29.0814 25.5459C29.2767 25.3507 29.2767 25.0341 29.0814 24.8389L27.0669 22.8242C27.6092 21.9467 28.05 20.9998 28.373 20H31.5C31.7761 20 32 19.7761 32 19.5V13.5C32 13.2239 31.7761 13 31.5 13H28.6521C28.3281 11.6286 27.787 10.341 27.0669 9.17575L29.0814 7.16115C29.2767 6.9659 29.2767 6.6493 29.0814 6.45405L24.8389 2.21142C24.6436 2.01616 24.327 2.01616 24.1318 2.21142L21.92 4.4231C21.0043 3.95389 20.0257 3.59014 19 3.34787V0.5C19 0.223857 18.7761 0 18.5 0H12.5ZM16 24C20.4183 24 24 20.4183 24 16C24 11.5817 20.4183 8 16 8C11.5817 8 8 11.5817 8 16C8 20.4183 11.5817 24 16 24Z"
                        fill="#E70D00"
                      />
                    </svg>
                  </div>
                  {/* settings icon ends */}
                </div>
              </div>
            </section>

            <MovieShow
              theatre="Capitol Theatre"
              theatreAddress="13, Stamford Street"
              languages={["Auditorium 1", "Auditorium 2"]}
              shows={[
                { time: "09:00 AM", seats: "15", exp: "4DX" },
                { time: "09:00 AM", seats: "15" },
                { time: "09:00 AM", seats: "15" },
                { time: "09:00 AM", seats: "15" },
                { time: "09:00 AM", seats: "15" },
                { time: "09:00 AM", seats: "15" },
                { time: "09:00 AM", seats: "15" },
                { time: "09:00 AM", seats: "15" },
                { time: "09:00 AM", seats: "15" },
                { time: "09:00 AM", seats: "15" },
              ]}
            />

            <button
              className="btn-main btn-main-lg mx-auto"
              onClick={() => setStage(2)}
            >
              Confirm
            </button>
          </>
        )}

        {stage === 2 && (
          <>
            {/* <section className="row">
              <ScreeningCard cardType="basic" />
              <ScreeningCard cardType="regular" />
              <ScreeningCard cardType="premium" />
            </section> */}
            <ScreeningCardNew></ScreeningCardNew>
          </>
        )}

        {stage === 3 && (
          <>
            <section className="row">
              <div className="px-3 mx-auto">
                <div className="seat-selection-heading">
                  <p className="">How many Seats?</p>
                </div>
              </div>
            </section>

            <section className="row mb-4">
              <div className="col mx-auto">
                <div className="seat-selection-row list-inline">
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((index) => (
                    <div className="list-inline-item">
                      <div
                        className={`seat-selection-btn ${
                          seatSelector === index ? "selected" : ""
                        }`}
                        onClick={() => setSeatSelector(index)}
                      >
                        {index}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>

            {seatSelector !== null && (
              <>
                <section className="row">
                  <div className="px-3 mx-auto">
                    <div className="seat-layout-screen">
                      <img src={screenSl} className="img-fluid" />
                    </div>
                  </div>
                </section>

                <section className="row">
                  <div className="col">
                    <div className="table-responsive">
                      <table className="master-table table table-borderless">
                        {[1, 2].map((index) => (
                          <tr>
                            <td className="category">
                              <tr>
                                <td className="category-price">
                                  <p>standard</p>
                                  <p>$40</p>
                                </td>

                                <td className="category-range"></td>

                                <table className="actual-table">
                                  <tr>
                                    <td>
                                      <table>
                                        {[1, 2, 3, 4, 5].map((index) => (
                                          <tr>
                                            <td className="">
                                              <div className="cell">
                                                {index}
                                              </div>
                                            </td>
                                          </tr>
                                        ))}
                                      </table>
                                    </td>
                                    <td>
                                      <table>
                                        {[1, 2, 3, 4, 5].map((index) => (
                                          <tr>
                                            {[
                                              1,
                                              2,
                                              3,
                                              4,
                                              5,
                                              6,
                                              7,
                                              8,
                                              9,
                                              10,
                                            ].map(() => (
                                              <td className="">
                                                <div className="cell seat"></div>
                                              </td>
                                            ))}
                                          </tr>
                                        ))}
                                      </table>
                                    </td>
                                    <td>
                                      <table>
                                        {[1, 2, 3, 4, 5].map((index) => (
                                          <tr>
                                            <td className="">
                                              <div className="cell">
                                                {index}
                                              </div>
                                            </td>
                                          </tr>
                                        ))}
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </tr>
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td colSpan="3">
                            <div className="seat-type">
                              <p className="seat-type">
                                standard
                                <span className="hover-image-span">
                                  <img src={infoCircle} id="hover-image" />
                                  <div className="seat-type-image">
                                    <img src={cinemaBanner} />
                                  </div>
                                </span>
                              </p>
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </section>

                <section className="row">
                  <div className="px-3 seat-layout-icons">
                    <p className="">
                      <img src={standardSeat} className="mr-3" />
                      Available
                    </p>
                    <p className="">
                      <img src={unavailableSeat} className="mr-3" />
                      Unavailable
                    </p>
                    <p>
                      <img src={selectedSeat} className="mr-3" />
                      Selected
                    </p>
                  </div>
                </section>

                <button className="btn-main mx-auto">Confirm</button>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
