import { withNamespaces } from "react-i18next";
import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import {
  fetchTodaysScreenings,
  fetchCreateOwnShow,
  fetchComingSoon,
  fetchFormatList,
  fetchLanguageList,
  fetchFestivalList,
  fetchMiniFestivals,
  fetchBanners
} from "../store/homepage/homepageActions";

// Components
import Banner from "@components/partials/Banner";
import MoviesNFestsNew from "@components/partials/MoviesNFestsNew";
import OnDemandInfo from "@components/partials/OnDemandInfo";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import useIsMobile from "../customHooks/useIsMobile";
import useIsDesktop from "../customHooks/useIsDesktop";
import { getLangSpecificAttribute } from "@helper/languages";
import { Dropdown, Modal, Spinner } from "react-bootstrap";

// Images

const NowShowingContainer = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const todaysScreeningsMovies = useSelector(
    (state) => state.homepage.todaysScreenings
  );
  const todaysScreeningsLoading = useSelector(
    (state) => state.homepage.todaysScreeningsLoading
  );
  const organization = useSelector((state) => state.movies.organizationDetails);
  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );
  const { iso_2, lang_id, lang_name } = selected_language;

  const uniqueTodaysScreeningMovies = {
    ...todaysScreeningsMovies,
    data: [
      ...new Map(
        todaysScreeningsMovies?.data?.map((item) => [item["movie_id"], item])
      ).values(),
    ],
  };

  const todaysScreeningsLabeledMovies = useSelector(
    (state) => state.homepage.todaysScreeningsLabeledMovies
  );

  const banners = useSelector((state) => state.homepage.banners);

  const nowShowingBanners = banners.filter((x) => {
    if (x?.movie_id) {
      if (x?.movie_banner_type_id?.split(',')?.includes('1')) {
        return x;
      }
    } else {
      return x;
    }
  })

  useEffect(() => {
    dispatch({ type: `FETCH_TODAYS_SCREENINGS` });
    dispatch(fetchBanners());
    dispatch(
      fetchTodaysScreenings({
        limit: 1000,
        currentPage: 1
      })
    );
  }, []);

  return (
    <>
      <Banner banners={nowShowingBanners} showOnly={1} />

      <div className="container-fluid">

        {/* Movie Sections */}
        {uniqueTodaysScreeningMovies?.data?.length > 0 ? (
          <MoviesNFestsNew
            section={1}
            moviesArray={uniqueTodaysScreeningMovies?.data}
            subCategory={''}
            category={t("Now Showing")}
            movieCount={uniqueTodaysScreeningMovies?.data?.length}
            type="now_showing"
            slider
            showMovieList={{category_id: 1}}
          />
        ) : todaysScreeningsLoading ? (
          <section className="row">
            <div className="col-12 text-center">
              <Spinner
                animation="border"
                role="status"
                className="mx-auto"
              />
            </div>
          </section>
        ) :
          (
            <section className="row">
              <div className="col-12 text-center">

                <h6 className="mx-auto mb-4">
                  {t("common.No movies found")}
                </h6>
              </div>
            </section>
          )
        }
        <OnDemandInfo/>
      </div>
    </>);
}

export default withNamespaces()(NowShowingContainer);
