import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Row,
  Nav,
  Tabs,
  Tab,
  Container,
  Col,
  Dropdown,
  Modal,
  Alert,
  Button,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import code from "@assets/pictures/svgs/code.svg";
import poster2 from "@assets/pictures/poster-2.png";
import filtericon from "@assets/pictures/svgs/filters.svg";
import { fetchUserHistory, login } from "../../store/auth/authActions";
import moment from "moment";
import Barcode from "react-barcode";
import QRCode from "qrcode.react";
import { sendEmail, sendSms } from "../../store/movies/movieActions";
import { withNamespaces } from "react-i18next";
import { currencyFormatter } from "../../helper/currencyFormatter";
import dummyPosterStatic from "@assets/pictures/dummyPoster.png";
import { getLangSpecificAttribute } from "@helper/languages";
import Spinner from ".././partials/Spinner";

const Transactions = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loginDetails = useSelector((state) => state.auth);
  const userHistoryLoading = useSelector(
    (state) => state.auth.userHistoryLoading
  );
  const { loggedInUser, userUnpaidHistory } = loginDetails;
  const resendSMSError = useSelector((state) => state.movies.resendSMSError);
  const resendEmailError = useSelector(
    (state) => state.movies.resendEmailError
  );
  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );
  const organization = useSelector((state) => state.movies.organizationDetails);
  const { dummy_poster: dummyPosterDynamic } = organization;

  const dummyPoster = window.localStorage.getItem("placeholder_image")
    ? window.localStorage.getItem("placeholder_image")
    : dummyPosterStatic;

  const [loading, setLoading] = useState({
    email: false,
    sms: false,
  });
  const [ticketIndex, setTicketIndex] = useState(null);
  const { lang_name, iso_2, lang_id } = selected_language;
  // useEffect(() => {
  //   const user = JSON.parse(localStorage.getItem("user"));
  //   console.log("login :>> ", login);
  //   dispatch(
  //     login({ email: user.email, password: user.password, callback: () => {} })
  //   );
  // }, []);
  useEffect(() => {
    // if (loggedInUser?.cust_id) {
    //   dispatch(fetchUserHistory({ customer_id: loggedInUser?.cust_id }));
    // } else {
    //   history.push(`/`);
    // }
  }, [dispatch, history, loggedInUser]);
  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;
  return (
    <div className="transactions">
      {/* <div className="header">
        <div className="d-flex align-items-center pb-1">
          <img src={filtericon} className="img-fluid" alt="" />
          <label className="pl-2 pt-2">Filters</label>
        </div>
        <Dropdown className="user-custom-dropdown">
          <Dropdown.Toggle className="dropdownClassic" id="dropdown-basic">
            Select
          </Dropdown.Toggle>
          <Dropdown.Menu align="right" className="dropdownItems">
            <Dropdown.Item className="selected">All</Dropdown.Item>
            <Dropdown.Item>Coming</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div> */}
      {userHistoryLoading && (
        <div className="row mt-5">
          <div className="col-12 text-center">
            <Spinner />
          </div>
        </div>
      )}
      {userUnpaidHistory?.length > 0 && (
        <div className="booked-tickets">
          {userUnpaidHistory?.map((transaction, index) => (
            <>
              {index !== 0 && <hr className="custom-border" />}
              <div className="book-ticket">
                <div className="ticket">
                  <div className="header">
                    <div className="left">
                      <div className="movie-img">
                        <img
                          src={
                            getLangSpecificAttribute(
                              transaction?.Seats[0]?.languageSpecificImages,
                              lang_id,
                              "artwork"
                            ) || dummyPoster
                          }
                          className="img-fluid"
                          alt="movie-img"
                        />

                        {/* <img src="https://cinematic-server-gdc-3.brij.tech/uploads/gallery/1609780139976-ramprasad.jpg" className="img-fluid" /> */}
                      </div>
                      <div className="movie-content">
                        <h4 className="heading modelica-bold">
                          {getLangSpecificAttribute(
                            transaction?.Seats[0]?.movieTranslations
                             ? transaction?.Seats[0]?.movieTranslations
                             : transaction?.Seats[0]?.altMovieContent,
                            lang_id,
                            "mc_title"
                          ) || transaction?.Seats[0]?.movie_name || transaction?.Seats[0]?.movie_title}
                        </h4>
                        <div className="features">
                          <p>
                            {
                              transaction?.Seats[0]?.version_name
                                  ? (transaction?.Seats[0]?.version_name)
                                  : <>{transaction?.Seats[0]?.movie_language}{" "}
                                    {transaction?.Seats[0]?.movie_format}</>
                            }
                          </p>
                          <p>
                            {transaction?.Seats[0]?.genreTranslations?.map(
                              (genre, index) =>
                                genre[iso_2?.toLowerCase()] && (
                                  <span key={index}>
                                    {" "}
                                    {genre[iso_2?.toLowerCase()]} |
                                  </span>
                                )
                            )}{" "}
                            {transaction?.Seats[0]?.movie_rating || transaction?.Seats[0]?.rating}
                          </p>
                        </div>
                        <div className="booked-details">
                          <div>
                            <p className="fsd-18 text-success modelica-bold">
                              {transaction?.Seats[0]?.cine_name}
                            </p>
                            <p className="fsd-14">
                              {transaction?.Seats[0]?.cine_address}
                              {/* {transaction?.Seats[0]?.cine_location} */}
                            </p>
                          </div>
                          <div>
                            <p className="fsd-14">{t(`Seats Selected`)}:</p>
                            <p className="fsd-16 font-weight-bold text-break">
                              {transaction?.Seats[0]
                                ?.mycinema_booking_type_id === 3
                                ? t("entire-hall-booked")
                                : transaction?.seatsString?.join(", ")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="right">
                      <div class="py-2 d-flex align-items-center">
                        <div className="date-day d-flex align-items-center pr-3">
                          <h3 className="modelica-bold mb-0">
                            {moment(
                              transaction?.Seats[0]?.ss_actual_start_date
                            ).format("DD")}
                          </h3>
                          <p className="fsd-14 pl-2">
                            {moment(
                              transaction?.Seats[0]?.ss_actual_start_date
                            ).format("MMMM")}{" "}
                            <br />{" "}
                            {moment(
                              transaction?.Seats[0]?.ss_actual_start_date
                            ).format("dddd")}
                          </p>
                        </div>
                        <div className="date-time pl-3">
                          <h3 className="modelica-bold mb-0 pr-4">
                            {moment(
                              transaction?.Seats[0]?.ss_start_show_time,
                              "HH:mm"
                            ).format("HH:mm")}{" "}
                          </h3>
                          <div>
                            {/* {moment(
                              transaction?.Seats[0]?.ss_actual_start_date_time
                            ).format("A")} */}
                          </div>
                        </div>
                      </div>
                      <div className="pt-md-4 pt-1 text-center">
                        {!transaction?.Seats[0]?.t_refund_date_time_stamp && (
                          <>
                            <p className="text-grey fs-12 pb-2">
                              {t(`QR Code`)}
                            </p>
                            {/* <img src={code} className="img-fluid" alt="" /> */}
                            <div className="">
                              {/* trans-barcode */}
                              {/* <Barcode
                              value={transaction?.Seats[0]?.sb_booking_code}
                            /> */}
                              {transaction?.reservation_id && (
                                  <QRCode
                                      value={transaction?.reservation_id}
                                      className="mx-auto qr-border"
                                  />
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* {transaction?.Seats[0]?.fnb_items?.length > 0 && (*/}
                  <div className=" concessions">
                    {/* <p className="comma-separated">
                        {transaction?.Seats[0]?.fnb_items.map((item) => (
                          <span>
                            {item?.fst_quantity}{" "}
                            {getLangSpecificAttribute(
                              item?.FnbContent,
                              lang_id,
                              "item_lang_name"
                            ) || item.item_name}
                          </span>
                        ))}
                      </p>
                    
                  )} */}
                    {transaction?.Seats[0]?.fnb_items &&
                      transaction?.Seats[0]?.fnb_items.length > 0 &&
                      transaction?.Seats[0]?.fnb_items.map((item) => (
                        <>
                          <p className="">{t("FnB")}:</p>
                          <article className="grid-25">
                            <div>
                              <p className="">
                                {getLangSpecificAttribute(
                                  item?.FnbContent,
                                  lang_id,
                                  "item_lang_name"
                                ) || item.item_name}
                              </p>
                            </div>
                            <div>
                              <p className="">x{item.fst_quantity}</p>
                            </div>
                            <div>
                              <p className="">
                                {curr_code}{" "}
                                {currencyFormatter(item.fp_total_amount)}
                              </p>
                            </div>
                            <div>
                              <p className="">
                                {curr_code}{" "}
                                {currencyFormatter(item.total_price)}
                              </p>
                            </div>
                          </article>
                        </>
                      ))}
                  </div>
                  {!!transaction?.Seats[0]?.private_rate_card_detail?.length &&
                    !!transaction?.Seats[0]?.private_rate_card_detail[0]?.slab
                      .length && (
                      <div className=" concessions">
                        {transaction?.Seats[0]?.private_rate_card_detail[0].slab.map(
                          (item) => (
                            <>
                              <p className="">{t("Tickets")}:</p>
                              <article className="grid-25">
                                <div>
                                  <p className="">{"Private booking"}</p>
                                </div>
                                <div>
                                  <p className="">
                                    x
                                    {
                                      transaction?.Seats[0]
                                        ?.private_rate_card_detail[0]
                                        ?.customSeatCount
                                    }
                                  </p>
                                </div>
                                <div>
                                  <p className="">
                                  </p>
                                </div>
                                <div>
                                  <p className="">
                                    {curr_code}{" "}
                                    {currencyFormatter(
                                      transaction?.Seats[0]
                                        ?.private_rate_card_detail[0]
                                        ?.customSeatCount * item.price
                                    )}
                                  </p>
                                </div>
                              </article>
                            </>
                          )
                        )}
                      </div>
                    )}
                  {!!transaction?.Seats[0]?.private_rate_card_detail?.length &&
                    !!transaction?.Seats[0]?.private_rate_card_detail[0]?.fnb
                      .length && (
                      <div className=" concessions">
                        {transaction?.Seats[0]?.private_rate_card_detail[0].fnb.map(
                          (item) => (
                            <>
                              <p className="">{t("Concessions")}:</p>
                              <article className="grid-25">
                                <div>
                                  <p className="">{item.item_name}</p>
                                </div>
                                <div>
                                  <p className="">
                                    x
                                    {
                                      transaction?.Seats[0]
                                        ?.private_rate_card_detail[0]
                                        ?.customSeatCount
                                    }
                                  </p>
                                </div>
                                <div>
                                  <p className="">
                                    {curr_code} {currencyFormatter(item.price)}
                                  </p>
                                </div>
                                <div>
                                  <p className="">
                                    {curr_code}{" "}
                                    {currencyFormatter(
                                      transaction?.Seats[0]
                                        ?.private_rate_card_detail[0]
                                        ?.customSeatCount * item.price
                                    )}
                                  </p>
                                </div>
                              </article>
                            </>
                          )
                        )}
                      </div>
                    )}
                  {!!transaction?.Seats[0]?.private_rate_card_detail?.length &&
                    !!transaction?.Seats[0]?.private_rate_card_detail[0]?.addon
                      .length && (
                      <div className=" concessions">
                        {transaction?.Seats[0]?.private_rate_card_detail[0].addon.map(
                          (item) => (
                            <>
                              <p className="">{t("Add Ons")}:</p>
                              <article className="grid-25">
                                <div>
                                  <p className="">{item.item_name}</p>
                                </div>
                                <div>
                                  <p className="">x{item.quantity}</p>
                                </div>
                                <div>
                                  <p className="">
                                    {curr_code} {currencyFormatter(item.price)}
                                  </p>
                                </div>
                                <div>
                                  <p className="">
                                    {curr_code}{" "}
                                    {currencyFormatter(
                                      item.quantity * item.price
                                    )}
                                  </p>
                                </div>
                              </article>
                            </>
                          )
                        )}
                      </div>
                    )}
                  <div className="footer">
                    <div>
                      <p>{t(`Booking Date & Time`)}:</p>
                      <p>
                        {" "}
                        {moment(transaction?.Seats[0]?.reservation_date_time_fallback).format(
                          "ll"
                        )}{" "}
                        |{" "}
                        {moment(transaction?.Seats[0]?.reservation_date_time_fallback).format(
                          "HH:mm"
                        )}{" "}
                      </p>
                    </div>
                    <div>
                      <p>{t(`Payment Method`)}:</p>
                      <p>
                        {transaction?.Seats[0]?.payment_mode ||
                          transaction?.Seats[0]?.stripeDetails?.card ||
                          t("Unpaid Booking")}
                      </p>
                    </div>
                    {transaction?.Seats[0]?.voucher_code && (
                      <div>
                        <p>{t(`Voucher Code`)}:</p>
                        <p>{transaction?.Seats[0]?.voucher_code}</p>
                      </div>
                    )}
                    {transaction?.Seats[0]?.t_refund_date_time_stamp && (
                      <div>
                        <p>{t(`Status`)}:</p>
                        <p>{t("Refunded")}</p>
                      </div>
                    )}
                    {transaction?.Seats[0]?.rr_reason && (
                      <div>
                        <p>{t(`Refund Reason`)}:</p>
                        <p>{t(transaction?.Seats[0]?.rr_reason)}</p>
                      </div>
                    )}
                    <div>
                      <p>{t(`Reservation ID`)}:</p>
                      <p>{transaction?.Seats[0]?.reservation_id}</p>
                    </div>
                    <div>
                      <p>{t(`Screening Type`)}:</p>
                      <p>{t(transaction?.Seats[0]?.screening_name) || transaction?.Seats[0]?.screening_name}</p>
                    </div>
                    <div>
                      <p>{t(`Total Amount Paid`)}</p>
                      <p>
                        {curr_code}{" "}
                        {currencyFormatter(
                          parseFloat(
                              (transaction?.Seats[0]?.amount || 0)
                          )
                        )}
                      </p>
                    </div>

                    {transaction?.Seats[0].ss_actual_start_date_time >
                      moment().valueOf() && (
                      <div className="footer-actions">
                        {(loading.email || loading.sms) &&
                        index === ticketIndex ? (
                          <Spinner animation="border" />
                        ) : (
                          <>
                            <p className="text-primary fs-12">
                              <span
                                style={{ cursor: "pointer" }}
                                className="loader-text"
                                onClick={() => {
                                  !resendEmailError && setTicketIndex(index);
                                  setLoading({ ...loading, email: true });
                                  dispatch(
                                    sendEmail({
                                      sb_booking_code:
                                        transaction?.Seats[0].sb_booking_code,
                                    })
                                  ).then((response) => {
                                    setLoading({ ...loading, email: false });
                                  });
                                }}
                              >
                                {t(`Resend Email`)}
                              </span>{" "}
                              |{" "}
                              {/* | {loading.email ? <Spinner size="sm" /> : null} */}
                              <span
                                style={{ cursor: "pointer" }}
                                className={` ${loading.sms && "loader-text"}`}
                                onClick={() => {
                                  !resendSMSError && setTicketIndex(index);
                                  setLoading({ ...loading, sms: true });
                                  dispatch(
                                    sendSms({
                                      sb_booking_code:
                                        transaction?.Seats[0].sb_booking_code,
                                    })
                                  ).then((response) => {
                                    setLoading({ ...loading, sms: false });
                                  });
                                }}
                              >
                                {t(`Resend M-ticket`)}
                              </span>{" "}
                              {/* {loading.sms ? <Spinner size="sm" /> : null} */}
                            </p>
                            {resendSMSError && (
                              <p className="error mt-2 mb-0">
                                {resendSMSError}
                              </p>
                            )}
                            {resendEmailError && (
                              <p className="error mt-2 mb-0">
                                {resendEmailError}
                              </p>
                            )}
                            <p></p>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      )}
      {/* <div className="booked-tickets d-flex justify-content-center align-items-center"> */}
      {!userHistoryLoading && userUnpaidHistory?.length === 0 && (
        <p className="text-center my-5 py-5">{t(`No Transactions Found`)}</p>
      )}
      {/* </div> */}
    </div>
  );
};

export default withNamespaces()(Transactions);
