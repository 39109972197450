import React from "react";
import {withNamespaces} from "react-i18next";

// Images
import {ReactComponent as Screen} from "@assets/pictures/svgs/screen_new.svg"
import {ReactComponent as Profile} from "@assets/pictures/svgs/profile_new.svg"
import {ReactComponent as NextArrow} from "@assets/pictures/svgs/next_circled_arrow.svg"
import {currencyFormatter} from "../../../helper/currencyFormatter";
import {useSelector} from "react-redux";


const CreatePrivateScreen = (props) => {
  const {t, onClick} = props;


  const createYourShowPrice = useSelector((state) => state.movies.createYourShowPrice)
  const currency = useSelector((state) => state.movies.currency);
  const {curr_code, curr_id} = currency;

  return (
    <>
      <div className="movie_info_create_private_screening" onClick={onClick}>
        {/* <section className="d-flex flex-column align-items-center">
          <Screen className="" width="40px" height="22px"/>
          <Profile className="" width="12px" height="14px"/>

        </section> */}
        <section>
          <h6>{t('Create private screening')}</h6>
          {(createYourShowPrice && createYourShowPrice > 0) && (
            <p>{t('Starting at')} {curr_code} {currencyFormatter(createYourShowPrice)}</p>)
          }
        </section>
        {/* <NextArrow className="" width="40px" height="40px"/> */}
      </div>
    </>
  );
}

export default withNamespaces()(CreatePrivateScreen);
