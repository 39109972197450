import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";

// Components
import CenteredTitle from "@components/partials/CenteredTitle";
import useIsMobile from "../customHooks/useIsMobile";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "./partials/Spinner";
import { currencyFormatter } from "../helper/currencyFormatter";

//Images
import failIcon from "@assets/pictures/svgs/failIcon.svg";
import giftCardImg from "@assets/pictures/gift-card.png";

const PaymentFail = ({ t }) => {
  const isMobile = useIsMobile()
  const history = useHistory()
  const dispatch = useDispatch()
  const { payment_for } = useParams()

  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="success_main">
        
          <div className="child_success">
            <div>
          <img
            src={failIcon}
            alt="tick"
            className="suc_img"
            />
             
            </div>
            <div className="suc_heading my-2">
              <span>{t("FAIL")} !</span>
            </div>
        <p>
          {t("CashCard.FailMsg")}
        </p>
       
        {/*<img
            src={giftCardImg}
            alt="card"
            className="suc_img_main mt-4"
            />*/}
             <button
               className="suc_btn mt-4"
               onClick={() => {
                 history.push(`/`);
               }}
             >
               {t("Back to home")}
             </button>
          </div>
        
      </div>
    </>
  );
};

export default withNamespaces()(PaymentFail);
