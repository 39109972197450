import React, { useState, useRef, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import useIsMobile from "../customHooks/useIsMobile";

// Components
import CenteredTitle from "@components/partials/CenteredTitle";
import BreadCrumb from "@components/partials/BreadCrumbs";

// Images
import openingHoursPattern from "@assets/pictures/openingHoursPattern.png";
import classImg from "@assets/pictures/classs_img.png";
const OpeningHours = ({ t }) => {

  /* let openings = [
    {
      openingsFor: [
        'Opening Hours',
      ],
      hours: [
        'Monday to Thursday - 11:30-21:00',
        'Friday and Saturday - 11:30-23:00',
        'Sunday - 13:00-21:00',
      ]
    },
    {
      openingsFor: [
        'Opening Hours for',
        'Screening'
      ],
      hours: [
        'Monday to Thursday - 09:00-21:00',
        'Friday - 09:00-01:30',
        'Saturday - 10:30-01:30',
        'Sunday - 12:00-21:00',
      ]
    }
  ] */

  return (
    <>
      <div className="container-fluid align_new">
      <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t("Opening Hours")}/>
        <CenteredTitle firstHalf={t("Opening Hours")} secondHalf={''} />
      </div>
      <div className="container-fluid opening_hours">
        <section className="row">
          <article className="col-md-5 mb-4 mb-md-0">
            <figure className="opening_hours_pattern">
              <img src={classImg} alt="Pattern" />
            </figure>
          </article>
          <article className="col-md-7">
            <section className="d-flex flex-column flex-md-row" style={{justifyContent:"space-between"}}>
              <div className="mb-4 mb-md-0 pr-md-3">
                <h3>
                  <p>
                    {t('Opening Hours for')}
                  </p>
                  <p>{t('OpeningHrs.Admin')}</p>
                </h3>
                <p>
                  {t('Monday')} {t('to')} {t('Thursday')} - 11:30-21:00
                </p>
                <p>
                  {t('Friday')} {t('and')} {t('Saturday')} - 11:30-23:00
                </p>
                <p>
                  {t('Sunday')} - 13:00-21:00
                </p>
              </div>
              <div className="pl-md-3">
                <h3>
                  <p>
                    {t('Opening Hours for')}
                  </p>
                  <p>{t('OpeningHrs.Screening')}</p>
                </h3>
                <p>
                  {t('Monday')} {t('to')} {t('Thursday')} - 09:00-21:00
                </p>
                <p>
                  {t('Friday')} - 09:00-01:30
                </p>
                <p>
                  {t('Saturday')} - 10:30-01:30
                </p>
                <p>
                  {t('Sunday')} - 12:00-21:00
                </p>
              </div>
            </section>
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(OpeningHours);
