export const FETCH_CASH_CARD_DETAILS = "FETCH_CASH_CARD_DETAILS";
export const FETCH_CASH_CARD_DETAILS_SUCCESS = "FETCH_CASH_CARD_DETAILS_SUCCESS";
export const FETCH_CASH_CARD_DETAILS_ERROR = "FETCH_CASH_CARD_DETAILS_ERROR";

export const SET_CASHCARD_TOPUP_AMOUNT = "SET_CASHCARD_TOPUP_AMOUNT"

export const SET_CASHCARD_BUY_AMOUNT = "SET_CASHCARD_BUY_AMOUNT"
export const SET_CASHCARD_NAME_TO_PRINT = "SET_CASHCARD_NAME_TO_PRINT"
export const SET_CASHCARD_BUY_QUANTITY = "SET_CASHCARD_BUY_QUANTITY"

export const SET_CASHCARD_REDEMPTION_AMOUNT = "SET_CASHCARD_REDEMPTION_AMOUNT"

export const FETCH_CASH_CARD_GALLERY = "FETCH_CASH_CARD_GALLERY"
export const FETCH_CASH_CARD_GALLERY_SUCCESS = "FETCH_CASH_CARD_GALLERY_SUCCESS"
export const FETCH_CASH_CARD_GALLERY_ERROR = "FETCH_CASH_CARD_GALLERY_ERROR"

export const FETCH_WEBSITE_CASH_CARD = "FETCH_WEBSITE_CASH_CARD"
export const FETCH_WEBSITE_CASH_CARD_SUCCESS = "FETCH_WEBSITE_CASH_CARD_SUCCESS"
export const FETCH_WEBSITE_CASH_CARD_ERROR = "FETCH_WEBSITE_CASH_CARD_ERROR"

export const FETCH_CASH_CARD_RESERVATION = "FETCH_CASH_CARD_RESERVATION"
export const FETCH_CASH_CARD_RESERVATION_SUCCESS = "FETCH_CASH_CARD_RESERVATION_SUCCESS"
export const FETCH_CASH_CARD_RESERVATION_ERROR = "FETCH_CASH_CARD_RESERVATION_ERROR"

export const FETCH_CASH_CARD_BUY_TRX = 'FETCH_CASH_CARD_BUY_TRX'
export const FETCH_CASH_CARD_BUY_TRX_SUCCESS = 'FETCH_CASH_CARD_BUY_TRX_SUCCESS'
export const FETCH_CASH_CARD_BUY_TRX_ERROR = 'FETCH_CASH_CARD_BUY_TRX_ERROR'
