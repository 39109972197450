import React from "react";
import { useHistory } from "react-router-dom";
import poster from "@assets/pictures/poster.png";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setStage } from "../../store/movies/movieActions";
import { motion } from "framer-motion";
import { withNamespaces } from "react-i18next";
import useIsMobile from "../../customHooks/useIsMobile";
import dummyPosterStatic from "@assets/pictures/dummyPoster.png";
import { BASEURL } from "../../config";
import { checkIfURLSame } from "@helper/formatting";
import { getLangSpecificAttribute } from "@helper/languages";
import moment from "moment";
const MovieCard = (props) => {
  const { pathname } = useLocation();
  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );
  const { lang_id } = selected_language;

  const organization = useSelector((state) => state.movies.organizationDetails);
  const {
    org_logo_url,
    org_mycinema_logo,
    org_name,
    dummy_poster: dummyPosterDynamic,
  } = organization;

  const dummyPoster = window.localStorage.getItem("placeholder_image")
    ? window.localStorage.getItem("placeholder_image")
    : dummyPosterStatic;

  const abc = () => {};
  const { iso_2 } = selected_language;
  const history = useHistory();
  const t = props.t;
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  let type1 = "yellow"; // now showing
  let type2 = "yellow"; // on demand
  let type3 = "yellow"; // coming soon

  if (props?.now_showing_count > 0) {
    type1 = "red";
  }
  if (props?.now_demand_count > 0) {
    type2 = "yellow";
  }

  const movieSchedules = useSelector((state) => state.movies.movieSchedules);
  const isCreateShowEnabled = useSelector(
    (state) => state.movies.createYourShowEnabled
  );

  console.log(props.type, movieSchedules?.isShowScreening, isCreateShowEnabled);

  return (
    <motion.div
      className={`movie-card ${
        props.slider
          ? "col mx-auto slider"
          : "col-6 col-md-4 col-lg-3 col-xl-2 "
      } ${
        (isMobile && props.index % 2 === 0 && "pr-2") ||
        (isMobile && props.index % 2 === 1 && "pl-2")
      }`}
      initial={{ x: -75, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.75 }}
      onClick={props.clickFunc && props.clickFunc}
    >
      <div
        onClick={() => {
          if (pathname === "/on-demand") {
            //  if(props.type != 3 &&
            //           movieSchedules?.isShowScreening &&
            //           isCreateShowEnabled){
            history.push(
              `/private-booking-flow/${props.movie_id}/${props.content_lang_id}/`
            );
            //  }
          } else {
            dispatch(setStage({ stage: 1, show: null }));
            history.push(
              `/movie-details/${props.movie_id}/${props.content_lang_id}/${
                props.type == 3 ? props.type : ""
              }`
            );
          }
        }}
      >
        <div className="img-card">
          <img
            src={
              getLangSpecificAttribute(
                props?.languageSpecificImages || props?.altMovieContent,
                lang_id,
                "artwork"
              ) || dummyPoster
            }
            className="img-fluid"
            alt={t("alt.Movie Poster")}
          />
          <div className="book-now d-none d-md-flex">
            <button className="btn-main">
              {props?.type == 3 ? t("Coming Soon") : t("Book Now")}
            </button>
          </div>
        </div>
        <div>
          {props?.showMovieType === true &&
          (props?.now_showing_count > 0 ||
            props?.now_demand_count > 0 ||
            props?.is_upcomimg === true) ? (
            <>
              <div
                className={`my-2 btn-main tag ${type1} ${
                  props?.now_showing_count > 0 ? "" : "d-none"
                }`}
              >
                {props?.now_showing_count > 0 ? t(`Now Showing`) : ``}
              </div>
              <div
                className={`my-2 btn-main tag ${type2} ${
                  props?.now_demand_count > 0 ? "" : "d-none"
                }`}
              >
                {props?.now_demand_count > 0 ? t(`On Demand`) : ``}
              </div>
              <div
                className={`my-2 btn-main tag ${type3} ${
                  props?.is_upcomimg === true ? "" : "d-none"
                }`}
              >
                {props?.is_upcomimg === true ? t("Upcoming") : ``}
              </div>
            </>
          ) : null}
          <div className="d-flex">
            <span className="movie-name">
              {getLangSpecificAttribute(
                props?.altMovieContent,
                lang_id,
                "mc_title"
              ) || props?.movie_title}
            </span>
            {/* <span className="cinema-name">{props.cine_name}</span> */}
          </div>
          {props?.is_upcomimg === true && props?.original_release_date && (
            <div className="pt-1">
              <span className="genre-n-rating">
                {t("From")}&nbsp;
                {moment(props?.original_release_date).format("Do MMM, YYYY")}
              </span>
            </div>
          )}
          <div className="d-flex pt-1 movie-card__tooltip__wrapper">
            <span className="genre-n-rating ">
              {props?.genres_with_translations
                ?.filter((genre) => genre[iso_2.toLowerCase()])
                ?.map(
                  (genre, index, arr) =>
                    genre[iso_2.toLowerCase()] && (
                      <React.Fragment key={index}>
                        {genre[iso_2.toLowerCase()]}
                        {arr.length - 1 !== index && ","}
                        &nbsp;
                      </React.Fragment>
                    )
              )}
             
            </span>
             <article className="movie-card__tooltip">
              <p>
                {props?.genres_with_translations
                ?.filter((genre) => genre[iso_2.toLowerCase()])
                ?.map(
                  (genre, index, arr) =>
                    genre[iso_2.toLowerCase()] && (
                      <React.Fragment key={index}>
                        {genre[iso_2.toLowerCase()]}
                        {arr.length - 1 !== index && ","}
                        &nbsp;
                      </React.Fragment>
                    )
                )}
              </p>
            </article>
          </div>
          <div className="d-flex pt-1">
            <span className="cinema-name">{props.rating}</span>
            <span className="experiences">
              {/* {props?.genres_with_translations?.map((genre, index) => (
                <>
                  {genre[iso_2.toLowerCase()]}
                  {props.genres_with_translations.length - 1 !== index && ","}
                  &nbsp;
                </>
              ))}{" "}
              , */}
              {props.mf_name}
            </span>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default withNamespaces()(MovieCard);
