import React from "react";
import { withNamespaces } from "react-i18next";

const HowItWorks = (props) => {
    const {t, classes} = props

  let howItWorks = []

    return (
        <>
          {
            howItWorks.length == 0
              ? <>
                <div className="static_page">
                  <section className="row">
                    <article className="col-xl-10 mx-auto">
                      <p>
                        {t("From now on you can buy our gift and loyalty cards in different designs at our box office or online.")}
                      </p>
                      <p>
                        {t("For yourself or for your loved ones. There is no place like your cinema...")}
                      </p>
                    </article>
                  </section>
                </div>
              </>
              :
            <div className="static_page">
                <section className="row">
                    <article className="col-xl-10 mx-auto">
                         <p>
                     You have preferably completed an apprenticeship in the hospitality industry or
have good knowledge and professional experience.
               </p>
                <p>
                    You love customer contact and are interested in working together with a
motivated team to give our guests an unforgettable cinema experience.
</p>

                <p className="static_page_highlight my-3 my-xl-4">
                    Your main tasks include:
</p>

<ul className="pl-4">
                    <li>
                        Independent operation of bar and kiosk with a comprehensive offer
    </li>
                    <li>
                        Preparation of small dishes
    </li>
                    <li>
                        Cinema ticket sale
    </li>
                    <li>
                        Issuing telephone information (German/English)
                    </li>
                    <li>
Catering at events
                    </li>
</ul>
                    </article>
               </section>
        </div>
          }
        </>
     );
}

export default withNamespaces()(HowItWorks);
