import {
    FETCH_TRANSLATION,
    FETCH_TRANSLATION_FAILED,
    FETCH_TRANSLATION_SUCCESSFUL,
    SET_SHOW_LOGIN_POPUP
} from "./configTypes"

let initialState = {
    showLoginPopup: false,
    translationsLoading: undefined,
}

const configReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_SHOW_LOGIN_POPUP:
            return {
                ...state,
                showLoginPopup: action.payload,
            }
        case FETCH_TRANSLATION:
            return {
                ...state,
                translationsLoading: true
            }
        case FETCH_TRANSLATION_FAILED:
        case FETCH_TRANSLATION_SUCCESSFUL:
            return {
                ...state,
                translationsLoading: false
            }
        default: return state;
    }
}

export default configReducer;