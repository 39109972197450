import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { useSelector, useDispatch } from "react-redux";
import back from "@assets/pictures/svgs/back-modal.svg";
import close from "@assets/pictures/svgs/close.svg";
import { withNamespaces } from "react-i18next";
import { verifyLoginOTP } from "../../../store/auth/authActions";
import { otpValidation } from "@helper/formatting";
import { motion } from "framer-motion";

// const handleChangeOtp = (value) => {
//   setOtp(value);
// };
const LoginOtpPopup = (props) => {
  const { t, allowResendLogin, onClickResendLogin, loadingResendOtp } = props;

  const dispatch = useDispatch();
  const [otp, setOtp] = useState(``);
  const [newPassword, setNewPassword] = useState(``);
  const state = useSelector((state) => state);
  const {
    auth: {
      loginOtpSuccess,
      forgotPasswordError,
      forgotPasswordUserDetails,
      otpError,
      timerCompleted,
      timer: { seconds, minutes },
    },
  } = state;
  // useEffect(() => setInterval(() => setAllowResendLogin(true), 5000), []);
  const [step, setStep] = useState(1);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [field, setField] = useState(``);
  const onClickConfirm = () => {
    setSubmitted(true);
    if (!otp) return;
    setLoading(true);
    dispatch(
      verifyLoginOTP({
        otp,
        callback: setLoading,
        successCallback: () => {
          props.closeFunc(false)
          setOtp('')
          setSubmitted(false)
        },
      })
    );
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      className="otp-popup"
      centered
      onHide={() => {
        setOtp('')
        setSubmitted(false)
        props.closeFunc(false);
        props.loginModalCloseFunc(false);
      }}
    >
      <section className="wrapper container-fluid">
        <img
          // src={back}
          src={step > 1 ? back : close}
          className={`back-btn-main ${step < 2 ? "__to_right" : ""}`}
          onClick={() => {
            step > 1 ? setStep(step - 1) : props.closeFunc(false);
          }}
          alt=""
        />
        <p className="title">{t("Verify OTP")}</p>
        <>
          <p className="msg">
            {t(`Enter OTP sent to`)} {loginOtpSuccess?.cust_email}{" "}
            {loginOtpSuccess?.cust_email !== "" ? t(`and`) : null}{" "}
            {loginOtpSuccess?.cust_mobile}
          </p>
          <form
            onSubmit={(e) => e.preventDefault()}
            className="d-block otp-input mb-1"
          >
            {!timerCompleted && (
              <input
                type="text"
                value={otp}
                onChange={(e) => {
                  if (otpValidation(e.target.value)) {
                    setOtp(e.target.value);
                  }
                }}
              />
            )}
          </form>
          {submitted && !otp && <p className="error">{t("field_required")}</p>}
        </>
        {!timerCompleted && (
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            {minutes.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
            :
            {seconds.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
            &nbsp;
          </motion.p>
        )}
        {timerCompleted && (
          <>
            {" "}
            <div className="d-flex align-items-center justify-content-between mt-2">
              <p>{t(`otp_message`)}&nbsp;</p>
              <button
                onClick={() => {
                  setOtp('')
                  onClickResendLogin && onClickResendLogin();
                }}
                className={`btn-basic ml-2 ${
                  loadingResendOtp ? "loader-text" : ""
                }`}
                // className={`btn-main mx-auto ${!allowResendLogin && `inactive`}`}
              >
                {t(`Resend`)}
              </button>
            </div>
          </>
        )}
        {forgotPasswordError && <p className="error">{t(forgotPasswordError)}</p>}
        {otpError && <p className="error">{t(otpError)}</p>}
        <button
          className={`btn-main mt-4 ${loading && `loader-text`}`}
          onClick={onClickConfirm}
        >
          {step === 4 ? t(`Close`) : t(`login_form.Confirm`)}
        </button>
      </section>
    </Modal>
  );
};

export default withNamespaces()(LoginOtpPopup);
