import React, { useState } from "react";
import { withNamespaces } from "react-i18next";

// Components
import Slider from "react-slick";
import CenteredTitle from "@components/partials/CenteredTitle";
import AboutUsCTA from "./AboutUsCTA";

// Images
import seatsImg from "@assets/pictures/about-us.png";
import seatsImg1 from "@assets/pictures/about-us-1.png";
import seatsImg2 from "@assets/pictures/about-us-2.png";
import seatsImg3 from "@assets/pictures/about-us-3.png";
import seatsImg4 from "@assets/pictures/about-us-4.png";

const AboutUsBookingType = (props) => {
  const {
    t,
    classes = "",
    type = "",
    titleFirstHalf = "",
    titleSecondHalf = "",
    description = "",
    onGetStarted,
    reverse = false
  } = props;
  const [swiping, setSwiping] = useState(false);

  const handleSwipe = () => {
    setSwiping(true);
  };

  const handleClick = () => {
    if (swiping) {
      setSwiping(false);
    } else {
      // Click actions here
    }
  };

  // Slider Settings
  const heroSlider = {
    pauseOnFocus: true,
    onSwipe: handleSwipe,
    arrows: true,
    dots: false,
    infinite: true,
    useTransform: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const getImage = (type = '') => {
    switch (type) {
      case "crowdsourced":
        return seatsImg1
      case "private":
        return seatsImg2
      case "watch_party":
        return seatsImg3
      default:
        return seatsImg4
    }
  }

  return (
    <>
      <section className={`container-fluid about_us_booking_type ${classes} ${type}`}>
        <div className="row">
          <article className="col-xl-10 ml-auto">
            <section className={`row ${reverse ? "flex-row-reverse" : ""}`}>
              <article className={`col-md-6 mb-4 mb-md-0 ${reverse ? "pl-md-5" : "pr-md-5"}`}>
                <div className={`${reverse ? "pl-3" : "pr-3"}`}>
                  <CenteredTitle firstHalf={titleFirstHalf} secondHalf={titleSecondHalf} />
                  <p className="about_us_booking_type_synopsis">
                    {description}
                  </p>
                  <AboutUsCTA onClick={onGetStarted} text={t("Get Started")} />
                </div>
              </article>
              <article className="col-md-6">
                {/* <Slider {...heroSlider}>
                            {Array(4).fill(0).map((item, index) => (
                                <article key={index}> */}
                <figure onClick={handleClick}>
                  <img src={getImage(type)} alt="" />
                </figure>
                {/* </article>
                            ))}
                        </Slider> */}
              </article>
            </section>
          </article>
        </div>
      </section>
    </>
  );
};

export default withNamespaces()(AboutUsBookingType);
