import React, { useEffect, useState, useRef, useContext } from "react";
import { withNamespaces } from "react-i18next";
import {
  Row,
  Container,
  Col,
  Dropdown,
  Modal,
  Alert,
  Spinner,
} from "react-bootstrap";
import cinemaBg from "@assets/pictures/cinema-banner.png";
import cinema from "@assets/pictures/cinema.png";
import whiteArrowDown from "@assets/pictures/svgs/white-arrow-down.svg";
import map from "@assets/pictures/svgs/map.svg";
import Slider from "react-slick";
import AuditoriumCard from "./partials/AuditoriumCard.jsx";
import AncilliaryHeroSection from "./partials/AncilliaryHeroSection.jsx";
import SeatLayoutPopup from "./partials/popups/SeatLayoutPopup.jsx";
import { fetchAuditoriums } from "../store/ancilliary/ancilliaryActions";
import { useSelector, useDispatch } from "react-redux";

const AuditoriumListing = ({ t }) => {
  // const heroSlider = {
  //   arrows: false,
  //   dots: false,
  //   infinite: true,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   speed: 500,
  //   autoplay: true,
  //   autoplaySpeed: 5000,
  //   responsive: [
  //     {
  //       breakpoint: 767,
  //       settings: {
  //         arrows: false,
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };
  const dispatch = useDispatch();
  useEffect(() => window.scrollTo(0, 0), []);
  useEffect(() => dispatch(fetchAuditoriums()), []);
  const heroSectionObj = {
    arr: [1, 2],
    backgroundImg: cinemaBg,
    title: t("Auditorium_listing.maintitle"),
    msg: t("Auditorium_listing.maindesc"),
  };

  const [seatLayout, setSeatLayout] = useState(false);
  const auditoriums = useSelector((state) => state.ancilliary.auditoriums);
  return (
    <div>
      <SeatLayoutPopup show={seatLayout} closeFunc={setSeatLayout} />
      <div className="px-0">
        {/* Hero Section */}
        {/* <Slider {...heroSlider} className="">
          {[1, 2].map((banner, index) => (
            <section className="cinema-hero-section" key={index}>
              <div className="hero-img-section">
                <img src={cinemaBg} alt="Movie Banner" className="hero-img-1" />
              </div>
              <div className="hero-text justify-content-center">
                <div className="container-fluid container-xl">
                  <div className="row">
                    <div className="col-10 col-sm-8 col-lg-6 mx-auto text-center">
                      <p className="title">Auditoriums</p>
                      <p className="msg">
                        We have the biggest cinema loyalty scheme, we - offering
                        guests the chance to see all the films they want, as
                        often as they like.
                      </p>
                      <div className="d-flex justify-content-center">
                        <img src={whiteArrowDown} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ))}
        </Slider> */}
        <AncilliaryHeroSection sectionObj={heroSectionObj} />
        {/* Hero Section Ends */}

        <section className="container-fluid container-xl mt-3">
          <div className="row">
            {auditoriums?.map((audi, index) => (
              <AuditoriumCard
                key={index}
                sideIcon={map}
                feature="IMAX"
                clickFunc={setSeatLayout}
                audi={audi}
              />
            ))}
          </div>
        </section>
      </div>
    </div>
  );
};

export default withNamespaces()(AuditoriumListing);
