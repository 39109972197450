import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";

import { sendemail } from "@store/auth/authActions";
import BreadCrumb from "@components/partials/BreadCrumbs";
import MovieShow from "./partials/movieShow.jsx";

import ShowDetailsPopup from "./partials/popups/showDetailsPopup.jsx";

import SeatTypePopup from "./partials/popups/SeatTypePopup.jsx";
import { motion } from "framer-motion";

import {
  Row,
  Container,
  Col,
  Dropdown,
  Modal,
  Alert,
  Spinner,
} from "react-bootstrap";
import Slider from "react-slick";

function Feedback({ t }) {
  const dispatch = useDispatch();

  const [email, setEmail] = useState(``);
  const [suggestions, setSuggestions] = useState(``);
  const [submitted, setSubmitted] = useState(false);
  const [status, setStatus] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const { pathname } = useLocation();

  const isBookEvent = pathname === "/book-event";

  let globalOptions = useSelector((state) => state.movies.globalOptions);
  const stc_id = globalOptions.find(g => g.go_key === `MYCINEMA_FEEDBACK_SUPPORT_TICKET_CATEGORY_ID`)?.go_value ?? 0;
  console.log({stc_id})

  const auth = useSelector((state) => state.auth);
  const cinema = useSelector((state) => state.movies.selectedCinema);
  const { loggedInUser } = auth;
  let customer_id = loggedInUser?.cust_id || null
  let contact_no = loggedInUser?.cust_mobile || null
  let cinema_id = cinema.cinema_id || null

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(function fieldMarginReducer() {
    const fields = document.querySelectorAll(".fields");
    fields.forEach(function (field) {
      console.log(field);
      if (
        field.nextElementSibling &&
        field.nextElementSibling.classList.contains("error")
      ) {
        field.style.marginBottom = "3px";
      }
    });
  });

  const submitForm = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (email && suggestions) {
      setValidForm(true);
    }
  };

  useEffect(() => {
    if (validForm) {
      dispatch(
        sendemail({
          name: "User from Mycinema",
          email,
          contact_no,
          message: suggestions,
          customer_id,
          cinema_id,
          stc_id,
        })
      ).then(() => {
        setStatus(true);
      });
    }
  }, [validForm]);

  useEffect(() => {
    if (status) {
      setEmail("");
      setSuggestions("");
    }
  }, [status]);

  return (
    <>  
           <div  className="contact_breadcrumb">
         <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t("Contact Us")} />
         </div>
      <div className="header-space container-fluid container-xl feedback-container">
        {/* <motion.p
          className="title"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5 }}
        >
          {pathname === "/book-event" ? "Booking" : "Contact"}
        </motion.p> */}
 
        <motion.div
          className="feedback"
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.75 }}
        >
          <p className="sub-title mx-auto">
            {pathname === "/book-event" ? t("Book an Event") : t("Contact Us")}
          </p>

          {status && (
            <div className="email-success-message">
              {t("Message sent successfully")}{" "}
            </div>
          )}

          <form onSubmit={submitForm}>
            <div className="row">
              <div className="col-12 col-md-12">
                <input
                  className="fields"
                  type="email"
                  placeholder={
                    pathname === "/book-event"
                      ? t("Please provide event details")
                      : t("Enter Email Address")
                  }
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <p className="error">
                  {submitted &&
                    !status &&
                    email === "" &&
                    t("Please enter your email")}
                </p>
              </div>
              {/* <div className="col-12 col-md-6">
                <div className="fields fb">
                  <Dropdown className="">
                    <Dropdown.Toggle
                      className="dropdownClassic"
                      id="dropdown-basic"
                    >
                      Select Cinema
                    </Dropdown.Toggle>

                    <Dropdown.Menu align="right" className="dropdownItems">
                      <Dropdown.Item href="#/action-1" className="selected">
                        Ticketing
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2" className="">
                        Food &amp; Baverages
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Customer Experience
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="fields fb">
                  <Dropdown className="fb">
                    <Dropdown.Toggle
                      className="dropdownClassic"
                      id="dropdown-basic"
                    >
                      Subject
                    </Dropdown.Toggle>

                    <Dropdown.Menu align="right" className="dropdownItems">
                      <Dropdown.Item href="#/action-1" className="selected">
                        Ticketing
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2" className="">
                        Food &amp; Baverages
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-3">
                        Customer Experience
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <input
                  className="fields"
                  type="text"
                  placeholder="Showtime and Ticket"
                />
              </div> */}
              <div className="col-12">
                <textarea
                  className="fields"
                  rows="8"
                  placeholder={t("type-suggestions")}
                  value={suggestions}
                  onChange={(e) => setSuggestions(e.target.value)}
                />

                <p className="error">
                  {submitted &&
                    !status &&
                    suggestions === "" &&
                    t("Please enter suggestions")}
                </p>
              </div>
              <div className="col-12 col-sm-5 mx-auto">
                <button className="btn-main mx-auto" type="submit">
                  {t("Submit")}
                </button>
              </div>
            </div>
          </form>
        </motion.div>
      </div>
    </>
  );
}

export default withNamespaces()(Feedback);
