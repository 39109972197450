import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setStage } from "../../store/movies/movieActions";
import { motion } from "framer-motion";
import { withNamespaces } from "react-i18next";
import useIsMobile from "../../customHooks/useIsMobile";
import dummyPosterStatic from "@assets/pictures/dummyPoster.png";
import { BASEURL } from "../../config";
import { checkIfURLSame } from "@helper/formatting";
import { getLangSpecificAttribute } from "@helper/languages";
import moment from "moment";

// Images
import poster from "@assets/pictures/sample-poster-new.png";
const _ = require("lodash");

const MovieCardNew = (props) => {
  const { slider, onClick, classes } = props;
  const { pathname } = useLocation();
  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );
  const { lang_id } = selected_language;

  const organization = useSelector((state) => state.movies.organizationDetails);
  const {
    org_logo_url,
    org_mycinema_logo,
    org_name,
    dummy_poster: dummyPosterDynamic,
  } = organization;

  const dummyPoster = window.localStorage.getItem("placeholder_image")
    ? window.localStorage.getItem("placeholder_image")
    : dummyPosterStatic;

  const abc = () => { };
  const { iso_2 } = selected_language;
  const history = useHistory();
  const t = props.t;
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const getLabelToshow = (labels) => {
    let tempArray = [];
    if (labels?.length > 0) {
      let sortedArray = _.sortBy(labels, (e) => e.order)
      let first_label_id = sortedArray[0]?.label_id;
      tempArray = labels?.filter((x) => x.label_id == first_label_id)
    } else {
      tempArray = [];
    }
    return tempArray
  }

  const labelsToShow = getLabelToshow(props?.label_to_show);

  return (<>
    <motion.article className={`${slider ? "slider_spacing" : ""} ${classes}`}
    initial={{ x: -75, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.75 }}>
      <div className={`${"movie_card_new"} `} onClick={onClick}>
        {/* Image */}
        <figure>
          <img
            src={
              getLangSpecificAttribute(
                props?.languageSpecificImages || props?.altMovieContent,
                lang_id,
                "artwork"
              ) || dummyPoster
            }
            className="img-fluid"
            alt={t("alt.Movie Poster")}
          />
        </figure>
        {/* Label */}
        {labelsToShow?.length > 0 && (
          <div className="movie_card_new_label_wrapper">
          <span className="movie_card_new_label_background" />
          <span className="movie_card_new_label">{getLangSpecificAttribute(
            labelsToShow,
            lang_id,
            "label_name",
            "lang_id"
          ) || labelsToShow[0]?.label || ''}</span>
          </div>
         )}
      </div>
    </motion.article>
  </>);
}

// Default Props
MovieCardNew.defaultProps = {
  img: poster,
  slider: false,
  classes: "",
  onClick: () => { },
};

export default withNamespaces()(MovieCardNew);