import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";
import HttpApi from "i18next-http-backend";
import MovieService from "../ApiServices/MovieService";
import { TOKEN, BASEURL } from "../config/index";
import "moment/locale/de";
import "moment/locale/fr";
import "moment/locale/it";
import moment from "moment";
import store from "../store";
import {
  FETCH_TRANSLATION,
  FETCH_TRANSLATION_FAILED,
  FETCH_TRANSLATION_SUCCESSFUL,
} from "../store/config/configTypes";

// import translationEN from "../helper/localizations/en.json";
// import translationDE from "../helper/localizations/de.json";
// import translationFR from "../helper/localizations/fr.json";
// import translationIT from "../helper/localizations/it.json";

// the translations
// const resources = {
//   English: {
//     translation: translationEN,
//   },
//   German: {
//     translation: translationDE,
//   },
//   French: { translation: translationFR },
//   Italian: { translation: translationIT },
// };

i18n
  .use(HttpApi)
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init(
    {
      lng: "en",
      fallbackLng: "en",
      keySeparator: false, // we do not use keys in form messages.welcome

      interpolation: {
        escapeValue: false, // react already safes from xss
      },

      backend: {
        // path where resources get loaded from, or a function
        // returning a path:
        // function(lngs, namespaces) { return customPath; }
        // the returned path will interpolate lng, ns if provided like giving a static path
        //
        // If allowMultiLoading is false, lngs and namespaces will have only one element each,
        // If allowMultiLoading is true, lngs and namespaces can have multiple elements
        loadPath: `${BASEURL}/api/external/translations?module=mycinema&lng={{lng}}&ns={{ns}}`,

        // path to post missing resources, or a function
        // function(lng, namespace) { return customPath; }
        // the returned path will interpolate lng, ns if provided like giving a static path
        addPath: `${BASEURL}/api/external/translations?module=mycinema&lng={{lng}}&ns={{ns}}`,

        // your backend server supports multiloading
        // /locales/resources.json?lng=de+en&ns=ns1+ns2
        // Adapter is needed to enable MultiLoading https://github.com/i18next/i18next-multiload-backend-adapter
        // Returned JSON structure in this case is
        // {
        //  lang : {
        //   namespaceA: {},
        //   namespaceB: {},
        //   ...etc
        //  }
        // }
        // allowMultiLoading: false, // set loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}' to adapt to multiLoading

        // parse data after it has been fetched
        // in example use https://www.npmjs.com/package/json5
        // here it removes the letter a from the json (bad idea)
        parse: function (data) {
          let parsedData = JSON.parse(data);
          return parsedData;
        },

        //parse data before it has been sent by addPath
        parsePayload: function (namespace, key, fallbackValue) {},

        // allow cross domain requests
        // crossDomain: true,

        // // allow credentials on cross domain requests
        // withCredentials: false,

        // // overrideMimeType sets request.overrideMimeType("application/json")
        // overrideMimeType: false,

        // // custom request headers sets request.setRequestHeader(key, value)
        // customHeaders: {
        //   Authorization: TOKEN,
        //   // ...
        // },
        // // can also be a function, that returns the headers
        // customHeaders: () => ({
        //   authorization: 'foo',
        //   // ...
        // }),

        // requestOptions: { // used for fetch, can also be a function (payload) => ({ method: 'GET' })
        //   mode: 'cors',
        //   credentials: 'same-origin',
        //   cache: 'default'
        // },

        // define a custom request function
        // can be used to support XDomainRequest in IE 8 and 9
        //
        // 'options' will be this entire options object
        // 'url' will be passed the value of 'loadPath'
        // 'payload' will be a key:value object used when saving missing translations
        // 'callback' is a function that takes two parameters, 'err' and 'res'.
        //            'err' should be an error
        //            'res' should be an object with a 'status' property and a 'data' property containing a stringified object instance beeing the key:value translation pairs for the
        //            requested language and namespace, or null in case of an error.
        request: function (options, url, payload, callback) {
          let url2 = new URL(url);
          const lang = url2.searchParams.get("lng");

          store.dispatch({ type: FETCH_TRANSLATION });

          MovieService.GetTranslations()
            .then((resp) => {
              const { data } = resp;

              if (data.status) {
                const translations = data.Records[lang].translation;
                callback(null, {
                  status: 200,
                  data: JSON.stringify(translations),
                });
                store.dispatch({ type: FETCH_TRANSLATION_SUCCESSFUL });
              } else {
                callback(new Error("something went wrong"), null);
                store.dispatch({ type: FETCH_TRANSLATION_FAILED });
                // setTimeout(() => { window.location.reload() });
              }
            })
            .catch((err) => {
              callback(err, null);
              store.dispatch({ type: FETCH_TRANSLATION_FAILED });
              // setTimeout(() => { window.location.reload() });
            });
        },

        // adds parameters to resource URL. 'example.com' -> 'example.com?v=1.3.5'
        // queryStringParams: { v: "1.3.5" },

        // reloadInterval: false, // can be used to reload resources in a specific interval (useful in server environments)
      },

      react: {
        wait: true,
      },
    },
    () => {
      console.log("react-i18next loaded successfully");
    }
  )
  .catch(console.log);

export default i18n;
