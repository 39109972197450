import React from "react";
import {withNamespaces} from "react-i18next";

// Components
import CenteredTitle from "@components/partials/CenteredTitle";
import BreadCrumb from "@components/partials/BreadCrumbs";
const Jobs = ({t}) => {

  let jobs = []

  return (
    <>
      <div className="jobs_wrapper align_new">
        <div className="left_right_align">
      <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t("Job at") + ' Stuessihof'}/>
        <CenteredTitle firstHalf={t("Job at") + ' Stuessihof'} secondHalf=""/>
        </div>
        <section className="row job_back">
          <article className="col-xl-10 mx-auto">
            <section className="row">
              {
                jobs.length == 0 ? (
                  <article className="col-lg-10 mx-auto">
                    <h6>
                      {t('There are no Job vacancies at the moment.')}
                    </h6>
                  </article>
                ) : (
              <article className="col-lg-10 mx-auto">
                <h6>
                  {t('Cinema')} | {t('Bar')} | {t('Garden bistro')} | {t('Take Out')} | {t('Event Location')} | {t('Kiosk')}
                </h6>

                <p>
                  {t('stussihofkinoInfo1')}
                </p>
                <p className="mb-4">
                  {t('stussihofkinoInfo2')}
                </p>

                <h6>
                  {t('Immediately or by appointment, we are looking for')}:
                </h6>

                <ul className="pl-4">
                  <li className="text-primary">
                    Specialist staff for operation in bar-kiosk and cinema operations
                  </li>
                </ul>
                <p>
                  Pensum approx. 40-60%
                </p>
                <p>
                  You have preferably completed an apprenticeship in the hospitality industry or
                  have good knowledge and professional experience.
                </p>
                <p className="mb-4">
                  You love customer contact and are interested in working together with a
                  motivated team to give our guests an unforgettable cinema experience.
                </p>

                <h6>
                  {t('Your main tasks include')}::
                </h6>

                <ul className="pl-4 mb-4">
                  <li>
                    Independent operation of bar and kiosk with a comprehensive offer
                  </li>
                  <li>
                    Preparation of small dishes
                  </li>
                  <li>
                    Cinema ticket sale
                  </li>
                  <li>
                    Issuing telephone information (German/English)
                  </li>
                  <li>
                    Catering at events
                  </li>
                </ul>


                <h6>
                  {t('Your profile includes')}:
                </h6>

                <ul className="pl-4 mb-4">
                  <li>
                    Good knowledge at the bar

                  </li>
                  <li>
                    a quick comprehension

                  </li>
                  <li>
                    Team player qualities, as well as an exact and independent way of working

                  </li>
                  <li>
                    Reliability and flexibility

                  </li>
                </ul>

                <p>
                  We offer you exciting areas of responsibility, a set up team and the
                  Opportunity to contribute your own ideas.
                </p>
                <p>
                  Are you ready to work together with the team for our operation?
                  and to feel responsible? Then please send us your complete
                  Application documents with photo by post or e-mail to:
                </p>

                <p>
                  Stüssihof cinema
                </p>
                <p>
                  Mrs Caroline Stirnemann
                </p>
                <p>
                  Stüssihofstatt 13
                </p>
                <p>
                  8001 Zurich
                </p>
                <p>
                  caroline@stuessihof.ch
                </p>
                <p>
                  www.stuessihof.ch
                </p>
                <p>
                  {t("Telephone")}: 079 286 70 42
                </p>

                <hr/>

                <h6>
                  {t('Wine trade')} | {t('Wine bar')} | {t('Erotic art gallery')}
                </h6>

                <p>
                  {t('stussihofbarInfo1')}
                </p>

                <p className="mb-4">
                  {t('stussihofbarInfo2')}
                </p>


                <h6>
                  {t('Immediately or by appointment, we are looking for')}:
                </h6>

                <ul className="pl-4">
                  <li>
                    Employee for the bar
                  </li>
                </ul>
                <p>
                  (Weekend and weekdays)
                </p>

                <p className="mb-4">
                  You preferably have professional experience at the bar and have good wine knowledge. You love customer
                  contact and are interested in giving our guests an unforgettable stay in our beautiful wine bar
                  together with a motivated team.
                </p>

                <h6>
                  {t('Your main tasks include')}:
                </h6>

                <ul className="pl-4 mb-4">
                  <li>
                    Advice and sale of wines

                  </li>
                  <li>
                    Independent service of our guests at the bar with a wide range of wines

                  </li>
                  <li>
                    Presentation of the goods and maintenance of the sales room

                  </li>
                  <li>
                    Inventory control and cash accounting

                  </li>
                  <li>
                    Advice and sale of art objects

                  </li>
                </ul>


                <h6>
                  {t('Your profile includes')}:

                </h6>

                <ul className="pl-4 mb-4">
                  <li>
                    Knowledge at the bar and quick comprehension

                  </li>
                  <li>
                    Team player qualities as well as an exact and independent way of working

                  </li>
                  <li>
                    Reliability and flexibility

                  </li>
                  <li>
                    Very good knowledge of German
                  </li>
                </ul>


                <p className="mb-4">
                  We offer you exciting areas of responsibility, a motivated and positioned team as well as the
                  opportunity to contribute your own ideas.
                </p>

                <p className="mb-4">
                  Are you ready to work together with the team for our operation and feel responsible? Then please send
                  us your complete application documents with photo by post or e-mail to:
                </p>

                <p className="mb-4">
                  Edi's Weinstube, Mrs. Caroline Stirnemann Stüssihofstatt 14, 8001 Zurich
                  <span className="text-primary">info@weinstube.ch</span>
                </p>

                <p>
                  {t('Telephone')}: <span className="text-primary">079 286 70 42</span>

                </p>
              </article>
                )
              }
            </section>
          </article>
        </section>
      </div>
    </>
  );
}

export default withNamespaces()(Jobs);
