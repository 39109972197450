export const FETCH_AUDITORIUM = "FETCH_AUDITORIUM";
export const FETCH_AUDITORIUM_SUCCESS = "FETCH_AUDITORIUM_LIST_SUCCESS";
export const FETCH_AUDITORIUM_ERROR = "FETCH_AUDITORIUM_ERROR";

export const FETCH_AUDI_SEAT_LAYOUT = "FETCH_AUDI_SEAT_LAYOUT";
export const FETCH_AUDI_SEAT_LAYOUT_ERROR = "FETCH_AUDI_SEAT_LAYOUT_ERROR";
export const FETCH_AUDI_SEAT_LAYOUT_SUCCESS = "FETCH_AUDI_SEAT_LAYOUT_SUCCESS";

export const FETCH_OFFERS = "FETCH_OFFERS";
export const FETCH_OFFERS_SUCCESS = "FETCH_OFFERS_SUCCESS";
export const FETCH_OFFERS_ERROR = "FETCH_OFFERS_ERROR";
export const FETCH_CMS = "FETCH_CMS";
export const FETCH_CMS_SUCCESS = "FETCH_CMS_SUCCESS";
export const FETCH_CMS_ERROR = "FETCH_CMS_ERROR";
export const FETCH_PAGE = "FETCH_PAGE";
export const FETCH_PAGE_SUCCESS = "FETCH_PAGE_SUCCESS";
export const FETCH_PAGE_ERROR = "FETCH_PAGE_ERROR";

export const SET_SEARCHED_QUERY = "SET_SEARCHED_QUERY";
