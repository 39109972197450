import React from "react";
import { withNamespaces } from "react-i18next";

// Images
import {ReactComponent as GetStartedArrow} from "@assets/pictures/svgs/get-started-arrow.svg";

const AboutUsCTA = (props) => {
    const { t, classes = "",text="", onClick = () => { } } = props;
    return (<>
     <div className={`about_us_cta ${classes}`} onClick={onClick}>
                <span>
                    {text}
                </span>
                <GetStartedArrow/>
            </div>
    </>);
}
 
export default withNamespaces()(AboutUsCTA);