import React from "react";
import { withNamespaces } from "react-i18next";

import CenteredTitle from "@components/partials/CenteredTitle";
import BreadCrumb from "@components/partials/BreadCrumbs";

const Advertise = ({ t }) => {
  return (
    <>
      <div className="static_page align_new">
      <div className="left_right_align">
        <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t("Advertising in the Cinema")} />
        <CenteredTitle firstHalf={t("Advertising in the Cinema")} />
        </div>
      
       
        <section className="row">
          <article className="col-xl-12 mx-auto event_back">
            <p className="static_page_highlight mb-4 mb-xl-5">
              {t("Great emotions in the Stüssihof cinema")}
            </p>
            <p>
              {t("Advertise.info1")}
            </p>

            <p className="static_page_highlight my-3 my-xl-4">
              {t("We offer you")}:
            </p>

            <ul className="pl-4">
              <li>
                {t("2 cinema halls")}
              </li>
              <li>
                {t("Foyer monitors")}
              </li>
              <li>
                {t("Outdoor monitors")}
              </li>
              <li>
                {t("innovative film and advertising opportunities")}
              </li>
              <li>
                {t("our partnership with Arena Cinemas AG")}
              </li>
            </ul>

            <p>
              {t("Would you like to book advertising on our screens or are you planning a promotion")}
            </p>
            <p>
              {t("Then our advertising partner Weischer.Cinema Schweiz GmbH appreciates hearing from you")}
            </p>
            <p className="mb-4 mb-xl-5">
              {t("More regional advertising")}:
            </p>
            <p className="mt-4 mt-xl-5">
              Weischer.Cinema Switzerland GmbH
            </p>
            <p>
              Zurich
            </p>
            <p>
              Rämistrasse 6
            </p>
            <p>
              8004 Zurich
            </p>
            <p>
              T: +41 44 577 30 00
            </p>
            <p className="text-primary">
              info@weischer-cinema.ch
            </p>
            <p className="text-primary">
              weischer-cinema.ch
            </p>
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(Advertise);
