import {
  FETCH_AUDITORIUM,
  FETCH_AUDITORIUM_SUCCESS,
  FETCH_AUDITORIUM_ERROR,
  FETCH_AUDI_SEAT_LAYOUT,
  FETCH_AUDI_SEAT_LAYOUT_ERROR,
  FETCH_AUDI_SEAT_LAYOUT_SUCCESS,
  FETCH_OFFERS,
  FETCH_OFFERS_SUCCESS,
  FETCH_OFFERS_ERROR,
  FETCH_CMS,
  FETCH_CMS_SUCCESS,
  FETCH_CMS_ERROR,
  FETCH_PAGE,
  FETCH_PAGE_SUCCESS,
  FETCH_PAGE_ERROR,
  SET_SEARCHED_QUERY
} from "./ancilliaryTypes";

let initialState = {
  auditoriums: [],
  audiSeatsLayout: [],
  offers: [],
  offersLoading: false,
  cmsData: [],
  cmsLoading: false,
  ms_page: [],
  pageLoading: false,
  searchedQuery: "",
};

const ancilliaryReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_AUDITORIUM:
      return {
        ...state,
        auditoriums: [],
      };
    case FETCH_AUDITORIUM_ERROR:
      return {
        ...state,
        auditoriums: [],
      };
    case FETCH_AUDITORIUM_SUCCESS:
      return {
        ...state,
        auditoriums: payload,
      };
    case FETCH_CMS_ERROR:
      return {
        ...state,
        cmsData: [],
        cmsLoading: false,
      };
    case FETCH_CMS:
      return {
        ...state,
        cmsData: [],
        cmsLoading: true,
      };
    case FETCH_CMS_SUCCESS:
      return {
        ...state,
        cmsData: payload,
        cmsLoading: false,
      };
    case FETCH_AUDI_SEAT_LAYOUT_SUCCESS:
      return {
        ...state,
        audiSeatsLayout: payload,
      };
    case FETCH_AUDI_SEAT_LAYOUT_ERROR:
      return {
        ...state,
        audiSeatsLayout: [],
      };
    case FETCH_OFFERS:
      return {
        ...state,
        offers: [],
        offersLoading: true,
      };
    case FETCH_OFFERS_ERROR:
      return {
        ...state,
        offers: [],
        offersLoading: false,
      };
    case FETCH_OFFERS_SUCCESS:
      return {
        ...state,
        offers: payload,
        offersLoading: false,
      };
    case FETCH_PAGE:
      return {
        ...state,
        ms_page: [],
        pageLoading: true,
      };
    case FETCH_PAGE_ERROR:
      return {
        ...state,
        ms_page: [],
        pageLoading: false,
      };
    case FETCH_PAGE_SUCCESS:
      return {
        ...state,
        ms_page: payload,
        pageLoading: false,
      };
    case SET_SEARCHED_QUERY:
      return {
        ...state,
        searchedQuery: payload,
      }

    default:
      return state;
  }
};

export default ancilliaryReducer;
