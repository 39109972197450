import React from "react";
import {withNamespaces} from "react-i18next";

// Images
// import {ReactComponent as StandardSeat} from "@assets/pictures/svgs/seat-standard.svg";
// import {ReactComponent as SelectedSeat} from "@assets/pictures/svgs/seat-standard-selected.svg";
// import { ReactComponent as UnavailableSeat} from "@assets/pictures/svgs/seat-standard-unavailable.svg";
import {ReactComponent as Seat} from "@assets/pictures/svgs/seat_new.svg";
import {useSelector} from "react-redux";
import {sortSeatsString} from "../../../store/auth/authActions";

const SeatPlanIcons = (props) => {
  const {t, classes} = props;

  let userSelection = useSelector(state => state.movies.userSelection)
  let seats = useSelector((state) => state.seatLayout.seats_selected)
  const seatString = seats.map((seat) => seat.sl_seat_name)

  return (
    <>
      <div className={`seat_plan_icons ${classes}`}>
        <article>
          <p className="">
            <figure className="">
              <Seat/>
            </figure>
            <p>
              {t("Available")}
            </p>
          </p>

          <p className="seat_plan_icons_selected">
            <figure>
              <Seat/>
            </figure>
            <p>
              {seats.length > 0 ? t("Selected") : t("Pick Seats")}{" "}
              {seats.length > 0 && `(${seats?.length})`}
            </p>
          </p>

          <p className="seat_plan_icons_unavailable">
            <figure>
              <Seat/>
            </figure>
            <p>
              {t("Unavailable")}
            </p>
          </p>

        </article>
        {userSelection?.selectedScreeningType?.booking_type_id != 3 && seats && seats.length > 0 && (
          <p>
            (
            {seatString.sort(sortSeatsString).map((seat, index) => (
              <span key={index}>{seat}</span>
            ))}
            )
          </p>
        )}
        {userSelection?.selectedScreeningType?.booking_type_id == 3 && (
          <p>
            (
            <span>{t("All")}</span>
            )
          </p>
        )}
      </div>
    </>
  );
}

// Default Props
SeatPlanIcons.defaultProps = {
  classes: "",
};

export default withNamespaces()(SeatPlanIcons);
