import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";

// Images
import giftCardImg from "@assets/pictures/gift-card.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchCashCardGallery } from "../../../store/cashCard/cashCardActions";
import Spinner from "../../partials/Spinner";

const CashCardsGallery = (props) => {
  const { t, classes } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const [activeTitle, setActiveTitle] = useState("Cash Cards");
  const cashCardGallery = useSelector(s => s.cashCard.cashCardGallery) ?? [];
  const cashCardGalleryLoading = useSelector(s => s.cashCard.cashCardGalleryLoading) ?? false;

  if (cashCardGalleryLoading)
    return <div className="row">
      <div className="col-12 text-center">
        <Spinner />
      </div>
    </div>;

  return (
    <>
      <div className="gift_cards">
        <h4>{t("Pick a Cash Card of your choice")}</h4>
        <section className="row">
          {cashCardGallery.map((card, index) => (
              <article className="col-6 col-md-4 col-lg-3" key={index}>
                <figure onClick={() => history.push(`/cash-card-details/${card?.w_cash_card_unique_id}`)}>
                  <img src={card?.w_cash_card_image_url || giftCardImg} alt={card?.w_cash_card_image_url} />
                </figure>
              </article>
            )
          )}
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(CashCardsGallery);
