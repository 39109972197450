/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Container,
  Col,
  Dropdown,
  Modal,
  Alert,
  Spinner,
} from "react-bootstrap";
import debounce from "lodash/debounce";
import { globalConfigContext } from "@context/GlobalConfigContext";
import * as yup from "yup";
// import { string, object } from 'yup'; // can be used for optimization
import AuthService from "@apiService/AuthService";
import {
  searchMovie,
  setSearchMovies,
} from "../../store/homepage/homepageActions";
import {
  setSelectedLanguage,
  setSelectedCinema,
  setLanguageSpecificMovieFunction,
} from "../../store/movies/movieActions";
import useCountries from "../../customHooks/useCountries";
import { setShowLoginSignup } from "@store/config/configActions";
import { useHistory, useLocation } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import close from "@assets/pictures/svgs/close.svg";
import poster from "@assets/pictures/poster.png";
import gogoLogo from "@assets/pictures/svgs/gogo-logo.svg";
import logoColored from "@assets/pictures/logo-colored.png";
import leftArrow from "@assets/pictures/svgs/left-arrow.svg";
import mobileMenu from "@assets/pictures/svgs/hamburger.svg";
import search from "@assets/pictures/svgs/search.svg";
import { ReactComponent as LoginIcon } from "@assets/pictures/svgs/Profile.svg";
import { ReactComponent as ActiveLoginIcon } from "@assets/pictures/svgs/profile-active.svg";
import map from "@assets/pictures/svgs/map.svg";
import activeMap from "@assets/pictures/svgs/map-active.svg";
import RegisterPopup from "./popups/RegisterPopup.jsx";
import LoginPopup from "./popups/LoginPopup.jsx";
import OtpPopup from "./popups/OtpPopup.jsx";
import Timeout from "./popups/Timeout.jsx";
import {
  logout,
  setShowLoginPopup,
  guestLogout,
  fetchGuestHistory,
  updateUserLang,
} from "../../store/auth/authActions";
import i18n from "../../plugins/i18n";
import useIsMobile from "../../customHooks/useIsMobile";
import MobileSideMenu from "../MobileMenu";
import MovieCard from "./MovieCard";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/fr";
import "moment/locale/it";
import { motion } from "framer-motion";
import dummyPosterStatic from "@assets/pictures/dummyPoster.png";
import HorizontalCard from "./HorizontalCard";

import {
  headerBg,
  titleCase,
  getLangISOCode,
} from "../../helper/formatting.js";

const Header = ({ t, location }) => {
  const cinemas = useSelector((state) => state.movies.cinemas);
  const organization = useSelector((state) => state.movies.organizationDetails);
  const HEADERS_CMS = useSelector((state) => state.homepage.headers_cms);
  const CMSLOADER = useSelector((state) => state.homepage.cmsLoader);
  const miniFestivalsData = useSelector(
    (state) => state.homepage.miniFestivals
  );
  const {
    org_logo_url,
    org_mycinema_logo,
    org_name,
    dummy_poster: dummyPosterDynamic,
  } = organization;

  const dummyPoster = window.localStorage.getItem("placeholder_image")
    ? window.localStorage.getItem("placeholder_image")
    : dummyPosterStatic;

  useEffect(() => {
    if (cinemas?.length === 1) {
      dispatch(setSelectedCinema(cinemas[0], history)).then((_) => {
        // window.location.href = '/'
      });
    }
  }, [cinemas]);
  const selectedCinema = useSelector((state) => state.movies.selectedCinema);
  const isMobile = useIsMobile();
  const { arabic, country_data } = useContext(globalConfigContext);
  const [searchTerm, setSearchedTerm] = useState("");
  const [cinemaSelected, setCinemaSelected] = useState(false);
  const searchMoviesLoading = useSelector(
    (state) => state.homepage.searchMoviesLoading
  );
  const showLoginPopup = useSelector((state) => state.auth.showLoginPopup);
  const onChangeSearch = (search) => {
    setSearchedTerm(search);
    searchMovieCallback(search);
  };

  const handleLoginPopup = (toShow) => {
    setRegister(toShow);
    dispatch(setShowLoginPopup(toShow));
  };

  const searchMovieCallback = useCallback(
    debounce((searchTerm2) => {
      if (!searchTerm2 || searchTerm2.length === 0) {
        dispatch(setSearchMovies([]));
      } else {
        dispatch(searchMovie({ search: searchTerm2 }));
      }
    }, 1000),
    []
  );
  const isNavItemActive = (item) => {
    if (!CMSLOADER) {
      if (HEADERS_CMS && HEADERS_CMS.length > 0) {
        if (HEADERS_CMS[0][item] == "Y") {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  useEffect(() => {
    isSearchClicked && setSearchBar(false);
  }, [isSearchClicked]);
  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );
  const showSearch = useRef(false);
  // React Bootstrap Modal
  const handleShow = () => dispatch(setShowLoginSignup(true));
  const [showLoginModal, setShowLoginModal] = useState(true);
  const [showSignupModal, setShowSignupModal] = useState(false);
  // Dispatch
  const dispatch = useDispatch();
  // Login Fields
  const [loginUsername, setLoginUsername] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [loginOtp, setLoginOtp] = useState("");
  const [loginErrors, setLoginErrors] = useState(null);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const countries = useCountries(countryCode, setCountryCode);
  const loginSchema = yup.object().shape({
    loginUsername: yup
      .string()
      .required()
      .matches(/(\S+@\S+\.\S+)|(^\+?\d{8,12}$)/),
    loginPassword: yup.string().required(),
  });
  const handleClose = () => {
    if (showResetPassword && !showSignupModal) {
      setShowResetPassword(false);
    } else {
      setShowResetPassword(false);
      setShowLoginModal(true);
      setShowSignupModal(false);
      setLoginErrors(null);
      setSignupErrors(null);
      dispatch(setShowLoginSignup(false));
    }
    setShowMigratedUserMessage(false);
  };
  // Signup Fields
  const [signupFirstName, setSignupFirstName] = useState("");
  const [signupLastName, setSignupLastName] = useState("");
  const [signupEmail, setSignupEmail] = useState("");
  const [signupMobile, setSignupMobile] = useState("");
  const [signupPassword, setSignupPassword] = useState("");
  const [signupConfirmPassword, setSignupConfirmPassword] = useState("");
  const [signupOtp, setSignupOtp] = useState("");
  const [signupErrors, setSignupErrors] = useState(null);
  const signupSchema = yup.object().shape({
    signupFirstName: yup.string().required(),
    signupLastName: yup.string().required(),
    signupEmail: yup.string().email().required(),
    signupMobile: yup
      .string()
      .matches(/^\d{8,9}$/)
      .required(),
    signupPassword: yup.string().required(),
    signupConfirmPassword: yup
      .string()
      .matches(new RegExp(String.raw`${signupPassword}`))
      .required(),
    countryCode: yup.string().required(),
  });
  const searchResult = useSelector((state) => state.homepage.searchMovies);
  const resetPasswordInputRef = useRef();
  const searchInputRef = useRef();
  const mobileSearchInputEle = useRef();
  const [resetPasswordError, setResetPasswordError] = useState(null);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(null);
  const [
    resetPasswordValidationError,
    setResetPasswordValidationError,
  ] = useState(null);
  const [resetPasswordLoader, setResetPasswordLoader] = useState(false);
  const resetPasswordHandler = () => {
    if (resetPasswordLoader) {
      return;
    }
    setResetPasswordError(null);
    setResetPasswordSuccess(null);
    setResetPasswordValidationError(null);
    yup
      .string()
      .required()
      .email()
      .validate(resetPasswordInputRef.current.value)
      .then((valid) => {
        setResetPasswordLoader(true);
        AuthService.SendForgotPasswordEmail({
          user_email: resetPasswordInputRef.current.value,
        })
          .then((response) => {
            if (response && response.status === 200) {
              setResetPasswordSuccess(
                "Reset Password Email Sent To Your Inbox Successfully!"
              );
            }
          })
          .catch((err) => {
            setResetPasswordError(
              err.response ? err.response.data.message : err.message
            );
          })
          .finally(() => setResetPasswordLoader(false));
      })
      .catch((err) => {
        setResetPasswordValidationError("Enter a valid Email Address");
      });
  };
  // Selectors
  const user = "fsdfdsf";
  const show = useSelector((state) => state.config.showLoginPopup);
  const temp_user = "fdsf";
  const migrated_user = "ss";
  const [showMigratedUserMessage, setShowMigratedUserMessage] = useState(null);
  useEffect(() => {
    if (migrated_user) {
      // history.push(`/reset-password/${migrated_user.user_id}/${migrated_user.reset_password_token}/?migrated=yes`)
      setShowMigratedUserMessage(true);
      setLoginUsername("");
      setLoginPassword("");
    }
  }, [migrated_user]);
  const handleLogin = () => {
    setLoginErrors(null);

    loginSchema
      .validate(
        {
          loginUsername,
          loginPassword,
        },
        { abortEarly: false }
      )
      .then((valid) => {
        setLoginErrors(null);
      })
      .catch((errs) => {
        let errorsObj = Object.assign(
          {},
          ...errs.inner.map((obj) => ({
            [obj.path]: obj,
          }))
        );
        setLoginErrors(errorsObj);
      });
  };
  const handleSignup = () => {
    setSignupErrors(null);
    signupSchema
      .validate(
        {
          signupFirstName,
          signupLastName,
          signupEmail,
          signupPassword,
          signupConfirmPassword,
          signupMobile,
          countryCode,
        },
        { abortEarly: false }
      )
      .then((valid) => {
        setSignupErrors(null);
      })
      .catch((errs) => {
        let errorsObj = Object.assign(
          {},
          ...errs.inner.map((obj) => ({
            [obj.path]: obj,
          }))
        );
        setSignupErrors(errorsObj);
      });
  };
  const handleLogout = () => {
    if (window.location.pathname === "/my-profile") {
      history.replace("/");
    }
  };
  const handleVerify = (type) => {};
  const redirectToResetPassword = (data) => {
    history.push(`/reset-password/${data.user_id}/${data.reset_token}`);
  };
  let showLogin = (e) => {
    setShowLoginModal(true);
    setShowSignupModal(false);
  };
  let showSignUp = (e) => {
    setShowSignupModal(true);
    setShowLoginModal(false);
  };

  const history = useHistory();
  const onClickMainLogo = () => {
    history.push("/");
    // dispatch(stopTimer());
  };
  const handleSocialLogin = (type, data) => {
    console.log(data);
  };
  const handleSocialLoginFailure = (type, data) => {
    console.log(data);
    dispatch(setShowLoginSignup(true));
    // console.log(Object.keys(data));
    // dispatch(setLoginError(data.ORIGINAL_ERROR.error));
    // dispatch(setSignupError(data.ORIGINAL_ERROR.error))
  };
  const globalOptions = useSelector((state) => state.movies.globalOptions);
  const languageArray = globalOptions.find(
    (go) => go.go_key === `KIOSK_LANGUAGE`
  )?.go_value;

  const [languages, setLanguages] = useState(null);
  useEffect(() => {
    languageArray && setLanguages(JSON.parse(languageArray));
  }, [languageArray]);

  const [language, setLanguage] = useState("ENGLISH");

  const onSelectLanguage = (language) => {
    dispatch(setSelectedLanguage(language));
    let lang = language.iso_2
      ? language.iso_2.toLowerCase()
      : getLangISOCode(language.lang_name);
    console.log("found lang = ", lang);
    i18n.changeLanguage(lang);
    moment.locale(lang.toLowerCase());
    dispatch(setLanguageSpecificMovieFunction());
    dispatch(updateUserLang(language));
    // window.location.reload();
  };
  const [login, setLogin] = useState(false);
  const [register, setRegister] = useState(false);
  const [otp, setOtp] = useState(false);
  const loginDetails = useSelector((state) => state.auth);
  const { loginError, loggedInUser, guestUser, userHistory } = loginDetails;

  useEffect(() => {
    if (guestUser) {
      dispatch(fetchGuestHistory({ guest_user_id: guestUser.guest_user_id }));
    }
  }, [guestUser]);

  // const ShowMobileSearchBar = function () {
  //   document.querySelector(".mobile-search-bar").classList.add("is-active");
  // };
  // const HideMobileSearchBar = function () {
  //   document.querySelector(".mobile-search-bar").classList.remove("is-active");
  // };

  const [searchBar, setSearchBar] = useState(false);

  useEffect(() => {
    if (
      searchBar === true &&
      mobileSearchInputEle &&
      mobileSearchInputEle.current
    ) {
      mobileSearchInputEle.current.focus();
    }
  }, [searchBar]);

  // function linksContentConditions() {
  //   let windowWidth = window.innerWidth;
  //   let element = document.getElementById("links");
  //   if (element) {
  //     if (windowWidth > "1500") {
  //       element.style.setProperty("justify-content", "flex-end", "important");
  //     } else if (windowWidth < "1500") {
  //       element.style.setProperty(
  //         "justify-content",
  //         "space-between",
  //         "important"
  //       );
  //     }
  //   }
  // };

  const [, reRender] = useState({});
  function sideSearchForTabs(e) {
    const nextSibling = e.target.nextElementSibling;
    if (window.innerWidth > 767 && window.innerWidth < 992) {
      if (nextSibling && nextSibling.tagName === "ASIDE") {
        nextSibling.style.display = "none";
      }
      // ShowMobileSearchBar();
      setSearchBar(true);
    } else {
      if (nextSibling && nextSibling.tagName === "ASIDE") {
        nextSibling.style.display = "";
      } else if (nextSibling && nextSibling.tagName === "INPUT") {
        nextSibling.focus();
      }
    }
  }

  // function linksContent() {
  //   linksContentConditions();
  //   window.addEventListener("resize", linksContentConditions);
  // }

  useEffect(headerBg, []);
  // useEffect(linksContent, []);

  // window.onResize = linksContentConditions;
  const timeoutError = useSelector((state) => state.movies.timeoutError);
  const [sidebar, setSidebar] = useState(false);
  const [loginIconBool, setLoginIconBool] = useState(false);
  const [searchComp, setSearchComp] = useState(false);

  const routelocation = useLocation();

  useEffect(() => {
    setSidebar(false);
    handleLoginPopup(false);
    setLogin(false);
  }, [routelocation]);

  useEffect(() => {
    if (searchComp && searchInputRef.current) searchInputRef.current.focus();
  }, [searchComp]);

  let nav = false;
  let movieDetails = false;
  let cinemaDrop = false;

  const { pathname } = useLocation();

  {
    /* || pathname === "/cinema-listing" ||
  pathname === "/promotions-and-offers" ||
pathname === "/menu" */
  }
  if (
    pathname === "/" ||
    pathname === "/cinema-listing" ||
    pathname === "/mini-festivals" ||
    pathname === "/menu" ||
    pathname.startsWith("/final-ticket")
  ) {
    nav = true;
  } else if (pathname.startsWith("/movie-details")) {
    nav = false;
    movieDetails = true;
  } else {
    nav = false;
  }

  if (pathname === "/" || pathname.startsWith("/movie-details")) {
    cinemaDrop = true;
  }
  //  pathname.startsWith("/movie-details") ||
  // pathname.startsWith("/create-show-details")
  function sideBarClickHandler(e) {
    const elem = e.target;
    if (
      elem.tagName === "IMG" ||
      elem.tagName === "P" ||
      elem.tagName === "BUTTON"
    ) {
      setSidebar(false);
    }
  }

  if (searchComp) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "";
  }
  return (
    <header className={`${isMobile ? "sticky-top" : "sticky-top"}`}>
      <RegisterPopup show={showLoginPopup} closeFunc={handleLoginPopup} />
      <LoginPopup show={login} closeFunc={setLogin} />
      {/* <OtpPopup show={otp} closeFunc={setOtp} /> */}
      <Timeout show={timeoutError?.show} />
      {/* Start Desktop Header */}
      <motion.div
        className={`main-menu d-none d-md-block ${searchComp && "scrolled"}`}
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div
          className="container-fluid container-xl h-100"
          onClick={() => {
            onChangeSearch("");
            if (searchComp) {
              setSearchComp(false);
            }
          }}
        >
          <div className="row align-items-center h-100">
            {/* col-xl-4 col-lg-5 col-md-3 */}
            <div className="col-xl-2 col-lg-3 col-md-2">
              <div className="row align-items-center">
                {/* col-4 col-md-7 col-lg-5 col-xl-5 */}
                <div className="col-12">
                  {/* <ul
                id="links"
                className="list-inline mb-0 d-flex justify-content-between align-items-center"
              >
                <li className="list-inline-item"> */}
                  <Link to="/">
                    <img
                      title="Home"
                      src={org_mycinema_logo || org_logo_url} //gogoLogo
                      alt={org_name}
                      className="brand-logo"
                    />
                  </Link>
                  {/* </li> */}
                </div>
                {/* <div className="col-8 col-md-5 col-lg-7 col-xl-7"> */}
                {/* <li className="list-inline-item w-100"> */}
                {/* <div className="inputWrapper d-none d-md-block w-100">
                    <img
                      src={search}
                      alt="search"
                      className="search-svg"
                      onClick={sideSearchForTabs}
                    />
                    <input
                      type="text"
                      className="w-100"
                      value={searchTerm}
                      onChange={(e) => {
                        onChangeSearch(e.target.value);
                      }}
                      onClick={sideSearchForTabs}
                    />
                    {searchTerm.length > 1 && (
                      <aside className="">
                        {searchTerm.length > 1 && searchResult?.length > 0 ? (
                          searchResult.map((search, index) => (
                            <SearchedMovie
                              type="yellow"
                              movie={search}
                              key={index}
                              onChangeSearch={onChangeSearch}
                              t={t}
                            />
                          ))
                        ) : searchTerm &&
                          searchResult &&
                          searchResult.length === 0 ? (
                          <h6 className="mx-auto mb-0">
                            {t("common.No movies found")}
                          </h6>
                        ) : searchTerm.length > 1 && searchMoviesLoading ? (
                          <Spinner
                            animation="border"
                            role="status"
                            className="mx-auto"
                          />
                        ) : null}
                      </aside>
                    )}
                  </div> */}
                {/* </li> */}
                {/* </div> */}
              </div>
            </div>
            <div className="d-none d-md-block col-xl-10 col-lg-9 col-md-10">
              <ul
                id="links"
                className="list-inline mb-0 d-flex justify-content-end align-items-center"
              >
                <li className="list-inline-item">
                  <img
                    src={search}
                    alt="search"
                    className="search-movies-icon"
                    title="search"
                    onClick={() => setSearchComp(true)}
                  />
                </li>
                {isNavItemActive("Cinemas") && cinemas?.length > 1 && (
                  <li className="list-inline-item">
                    <Link className="jod" to="/cinema-listing">
                      {t("header-footer-menu.cinemas2")}
                    </Link>
                  </li>
                )}
                {isNavItemActive("Book An Event") && (
                  <li className="list-inline-item">
                    <Link className="jod" to="/book-event">
                      {t("header-footer-menu.Book-An-Event")}
                    </Link>
                  </li>
                )}
                {/* <li className="list-inline-item">
                  <Link className="jod" to="/">
                    {t("common.Coming Soon")}
                  </Link>
                </li> */}
                {isNavItemActive("Mini Festivals") &&
                  miniFestivalsData?.Records?.length > 0 && (
                    <li className="list-inline-item">
                      <Link to="/mini-festivals" className="jod">
                        {t("common.mini-fests")}
                      </Link>
                    </li>
                  )}

                {isNavItemActive("Schedules") && (
                  <li className="list-inline-item">
                    <Link to="/schedules" className="jod">
                      {t("Schedules")}
                    </Link>
                  </li>
                )}

                {isNavItemActive("On Demand") && (
                  <li className="list-inline-item">
                    <Link to="/on-demand" className="jod">
                      {t("On Demand")}
                    </Link>
                  </li>
                )}

                {isNavItemActive("Languages") && (
                  <li className="list-inline-item">
                    <div className="dropdown-bg">
                      <Dropdown className="">
                        <Dropdown.Toggle
                          className="dropdownClassic"
                          id="dropdown-basic"
                        >
                          <span className="jod">
                            {titleCase(
                              selected_language?.alternate_lang_name ??
                                selected_language?.lang_name
                            )}
                          </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          align="right"
                          className="dropdownItems align-right"
                        >
                          {languages?.map((language, index) => (
                            <Dropdown.Item
                              key={index}
                              onClick={() => {
                                onSelectLanguage(language);
                              }}
                              className="selected"
                            >
                              {language?.alternate_lang_name ??
                                language?.lang_name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>
                )}

                {isNavItemActive("Show Selected Cinema") &&
                  cinemas?.length > 1 && (
                    <li className="list-inline-item">
                      <div className="dropdown-bg">
                        <Dropdown className="">
                          <Dropdown.Toggle
                            className="dropdownClassic"
                            id="dropdown-basic"
                          >
                            <span className="jod">
                              {selectedCinema
                                ? selectedCinema.cinema_name
                                : t("home_page.select-cinema")}
                            </span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            align="right"
                            className="dropdownItems align-right"
                          >
                            {cinemas.map((cinema, index) => (
                              <Dropdown.Item
                                key={index}
                                onClick={() => {
                                  dispatch(
                                    setSelectedCinema(cinema, history)
                                  ).then((_) => {
                                    window.location.href = "/";
                                  });
                                }}
                                className="selected"
                              >
                                {cinema?.cinema_name}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </li>
                  )}
                {!loggedInUser && guestUser && (
                  <>
                    <li className="list-inline-item">
                      <div className="logged-in-greet">
                        <p>{t("Hello")},&nbsp;</p>
                        <p>{t("Guest")}&nbsp;!</p>
                      </div>
                    </li>

                    <li className="list-inline-item">
                      <div className="jod d-flex align-items-center">
                        <div className="dropdown-bg">
                          <Dropdown className="">
                            <Dropdown.Toggle
                              className="dropdownClassic login-icon"
                              id="dropdown-basic"
                            >
                              <span className="jod">
                                {!loginIconBool ? (
                                  <LoginIcon
                                    onMouseOver={() => setLoginIconBool(true)}
                                    onMouseOut={() => setLoginIconBool(false)}
                                  />
                                ) : (
                                  <ActiveLoginIcon
                                    onMouseOver={() => setLoginIconBool(true)}
                                    onMouseOut={() => setLoginIconBool(false)}
                                  />
                                )}
                              </span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="dropdownItems align-right">
                              <Dropdown.Item
                                className=""
                                onClick={() =>
                                  history.push(`/guest-user-profile`)
                                }
                              >
                                {t("My Transactions")}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </li>
                  </>
                )}
                {loggedInUser ? (
                  <>
                    <li className="list-inline-item">
                      <div className="logged-in-greet">
                        <p>{t("Hello")},&nbsp;</p>
                        <p>{loggedInUser?.first_name}&nbsp;!</p>
                      </div>
                    </li>

                    <li className="list-inline-item">
                      <div className="jod d-flex align-items-center">
                        <div className="dropdown-bg">
                          <Dropdown className="">
                            <Dropdown.Toggle
                              className="dropdownClassic login-icon"
                              id="dropdown-basic"
                            >
                              <span className="jod">
                                {/* <img
                                  src={
                                    !loginIconBool ? loginIcon : activeLoginIcon
                                  }
                                  // onMouseDown={() => setLoginIconBool(true)}
                                  // onMouseUp={() => setLoginIconBool(false)}
                                  onMouseOver={() => setLoginIconBool(true)}
                                  onMouseOut={() => setLoginIconBool(false)}
                                  alt=""
                                  className=""
                                /> */}
                                {!loginIconBool ? (
                                  <LoginIcon
                                    onMouseOver={() => setLoginIconBool(true)}
                                    onMouseOut={() => setLoginIconBool(false)}
                                  />
                                ) : (
                                  <ActiveLoginIcon
                                    onMouseOver={() => setLoginIconBool(true)}
                                    onMouseOut={() => setLoginIconBool(false)}
                                  />
                                )}
                              </span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="dropdownItems align-right">
                              <Dropdown.Item
                                className=""
                                onClick={() => history.push(`/user-profile`)}
                              >
                                {/* {loggedInUser?.first_name}{" "}
                              {loggedInUser?.last_name} */}
                                {t("header-footer-menu.My Profile")}
                              </Dropdown.Item>
                              <Dropdown.Item
                                className=""
                                onClick={() => dispatch(logout({ history }))}
                              >
                                {t("header-footer-menu.Logout")}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </li>
                  </>
                ) : (
                  <>
                    {/* <li className="list-inline-item">
                      <Link className="jod" onClick={() => setLogin(true)}>
                        {t("header-footer-menu.Sign in")}
                      </Link>
                    </li> */}
                    <li className="list-inline-item">
                      <div
                        className="jod d-flex align-items-center signup"
                        onClick={() => handleLoginPopup(true)}
                      >
                        {t(`header-footer-menu.Sign in`)}
                        {/* /{" "} */}
                        {/* {t("header-footer-menu.Sign up")} */}
                      </div>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
        <section
          className={`search-comp ${searchComp ? "active" : ""}`}
          onClick={() => setSearchComp(false)}
        >
          <article className="container-fluid">
            <div className="row">
              <section className="col-lg-10 mx-auto">
                <form
                  action=""
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  onSubmit={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <div className="search-input">
                    <img
                      src={close}
                      alt=""
                      onClick={() => {
                        if (searchTerm) {
                          onChangeSearch("");
                          setSearchComp(false);
                        }
                      }}
                      title={`Close`}
                    />
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder={t("Search for movies")}
                      value={searchTerm}
                      onChange={(e) => {
                        onChangeSearch(e.target.value);
                      }}
                      autoFocus
                      ref={searchInputRef}
                    />
                  </div>
                  <aside className="row search-results">
                    {searchTerm.length > 1 && searchResult?.length > 0 ? (
                      searchResult.map((search, index) => (
                        <div className="col-lg-6 mb-3" key={index}>
                          <HorizontalCard
                            type="yellow"
                            movie={search}
                            key={index}
                            setSearchBar={() => {
                              console.log(12345);
                              setSearchComp(false);
                              showSearch.current = false;
                              setIsSearchClicked(false);
                              setSearchBar(false);
                            }}
                            onChangeSearch={onChangeSearch}
                            t={t}
                          />
                        </div>
                      ))
                    ) : searchTerm &&
                      searchResult &&
                      searchResult.length === 0 ? (
                      <h6 className="mx-auto mb-0">
                        {t("common.No movies found")}
                      </h6>
                    ) : searchTerm.length > 1 && searchMoviesLoading ? (
                      <Spinner
                        animation="border"
                        role="status"
                        className="mx-auto"
                      />
                    ) : null}
                  </aside>
                </form>
              </section>
            </div>
          </article>
        </section>
      </motion.div>
      {/* End Desktop Header */}

      {/* Start Mobile Menu Header */}
      <div className="main-menu d-block d-md-none">
        <div className="container-fluid h-100">
          {/* header begins */}
          <div className="row align-items-center justify-content-between px-3 h-100 flex-nowrap">
            <div className="d-flex align-items-center">
              {!nav && (
                <div className="pr-3">
                  <img
                    src={leftArrow}
                    alt={t("alt.Back")}
                    onClick={() => history.goBack()}
                  />
                </div>
              )}
              {/* {nav && ( */}
              <>
                <div>
                  <Link to="/">
                    <img
                      src={org_mycinema_logo || org_logo_url}
                      alt={org_name}
                      className="brand-logo"
                    />
                  </Link>
                </div>
              </>
              {/* )} */}
              {isNavItemActive("Show Selected Cinema") && cinemas?.length > 0 && (
                <aside className="cinema-select">
                  <p onClick={() => setCinemaSelected(true)}>
                    {/* <img src={activeMap} alt="" width="20px" className="pr-2" /> */}
                    <span className="cinema-map-img">
                      <svg
                        width="26"
                        height="30"
                        viewBox="0 0 26 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M24.3754 7.59902C23.7503 6.15761 22.849 4.85253 21.7225 3.75742C20.5922 2.65242 19.2597 1.77539 17.7979 1.17422C16.2771 0.543359 14.6635 0.224609 13 0.224609C11.3365 0.224609 9.72285 0.543359 8.20215 1.1709C6.73125 1.77852 5.41309 2.64844 4.27754 3.7541C3.1517 4.84982 2.25054 6.15475 1.62461 7.5957C0.977148 9.08984 0.648438 10.677 0.648438 12.3105C0.648438 14.6547 1.20957 16.9889 2.31191 19.24C3.19844 21.0496 4.43691 22.8127 5.99746 24.4895C8.66367 27.3516 11.4594 29.1014 12.2529 29.5695C12.4782 29.7026 12.7351 29.7725 12.9967 29.7721C13.2557 29.7721 13.5113 29.7057 13.7404 29.5695C14.534 29.1014 17.3297 27.3516 19.9959 24.4895C21.5564 22.816 22.7949 21.0496 23.6814 19.24C24.7904 16.9922 25.3516 14.6613 25.3516 12.3139C25.3516 10.6803 25.0229 9.09316 24.3754 7.59902ZM13 27.2254C10.8119 25.8342 3.03906 20.3258 3.03906 12.3139C3.03906 9.72734 4.07168 7.29688 5.94766 5.46406C7.83027 3.62793 10.3338 2.61523 13 2.61523C15.6662 2.61523 18.1697 3.62793 20.0523 5.46738C21.9283 7.29687 22.9609 9.72734 22.9609 12.3139C22.9609 20.3258 15.1881 25.8342 13 27.2254ZM13 6.73242C9.77266 6.73242 7.15625 9.34883 7.15625 12.5762C7.15625 15.8035 9.77266 18.4199 13 18.4199C16.2273 18.4199 18.8438 15.8035 18.8438 12.5762C18.8438 9.34883 16.2273 6.73242 13 6.73242ZM15.6297 15.2059C15.2848 15.5518 14.8749 15.826 14.4236 16.013C13.9723 16.1999 13.4885 16.2957 13 16.2949C12.0072 16.2949 11.0742 15.9064 10.3703 15.2059C10.0244 14.861 9.75012 14.4511 9.56322 13.9998C9.37632 13.5484 9.28049 13.0646 9.28125 12.5762C9.28125 11.5834 9.66973 10.6504 10.3703 9.94648C11.0742 9.24258 12.0072 8.85742 13 8.85742C13.9928 8.85742 14.9258 9.24258 15.6297 9.94648C16.3336 10.6504 16.7188 11.5834 16.7188 12.5762C16.7188 13.5689 16.3336 14.502 15.6297 15.2059Z"
                          fill="#e8308a"
                        />
                      </svg>
                    </span>

                    {selectedCinema
                      ? selectedCinema.cinema_name
                      : t("home_page.select-cinema")}
                  </p>
                  <article className={cinemaSelected ? "visible" : ""}>
                    <img
                      src={close}
                      onClick={(e) => {
                        setCinemaSelected(false);
                      }}
                      alt=""
                    />
                    <ul className="list-unstyled">
                      {cinemas?.map((cinema, index) => (
                        <li
                          key={index}
                          className=""
                          onClick={() => {
                            setCinemaSelected(false);
                            dispatch(setSelectedCinema(cinema, history)).then(
                              (_) => {
                                window.location.href = "/";
                              }
                            );
                          }}
                        >
                          {cinema?.cinema_name}
                        </li>
                      ))}
                    </ul>
                  </article>
                </aside>
              )}
            </div>
            <div className="d-flex align-items-center">
              {(nav || movieDetails) && (
                <div>
                  <img
                    src={search}
                    alt={t("alt.search")}
                    className="search-btn"
                    // onClick={ShowMobileSearchBar}
                    onClick={() => {
                      setSearchBar(true);
                      if (isMobile) {
                        showSearch.current = true;
                      }
                    }}
                  />
                </div>
              )}
              {nav && (
                <div>
                  <img
                    src={mobileMenu}
                    alt="Logo"
                    className="hamburger-btn pl-3"
                    onClick={() => setSidebar(true)}
                  />
                </div>
              )}
            </div>
          </div>
          {/* header ends */}

          {/* Sidebar Starts */}
          <div className={`mobile-sidebar ${sidebar ? "is-active" : ""}`}>
            <div className="w-100 d-flex justify-content-end align-items-center mb-3">
              <img
                src={close}
                alt=""
                className="sidebar-close pr-3"
                onClick={() => setSidebar(false)}
              />
            </div>
            <MobileSideMenu sideBarClickHandler={sideBarClickHandler} />
            {/* <div className="container-fluid">
              <div className="row">
                <div className="col-6">
                  <div className="">
                    <img src={gogoLogo} alt="Logo" className="brand-logo" />
                  </div>
                </div>
                <div className="col-6">
                  <div className="w-100 h-100 d-flex justify-content-end align-items-center">
                    <img
                      src={close}
                      alt=""
                      className="sidebar-close"
                      onClick={() => setSidebar(false)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="welcome-text">
                    <p className="">Welcome to</p>
                    <p className="">
                      My Cinema, {loggedInUser ? loggedInUser?.first_name : ""}
                    </p>
                    {loggedInUser ? (
                      <button
                        className="btn-main btn-main-sm mx-auto mt-3"
                        onClick={() => {
                          dispatch(logout({ history }));
                        }}
                      >
                        Logout
                      </button>
                    ) : (
                      <button
                        className="btn-main btn-main-sm mx-auto mt-3"
                        onClick={() => {
                          setSidebar(false);
                          setLogin(true);
                        }}
                      >
                        Sign in
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="sidebar-links">
                    <p className="" onClick={() => history.push("/")}>
                      Now Showing
                    </p>
                    <p className="" onClick={() => history.push("/")}>
                      Coming Soon
                    </p>
                    <p className="" onClick={() => history.push("/")}>
                      Specials
                    </p>
                    <p className="" onClick={() => history.push("/")}>
                      Link
                    </p>
                    <p className="" onClick={() => history.push("/")}>
                      Other Link
                    </p>
                    <p className="" onClick={() => history.push("/")}>
                      Some other Link
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          {/* Sidebar Ends */}
        </div>
      </div>
      {/* Start Search Menu */}
      {showSearch.current && (
        <div
          className={`mobile-search-bar p-3 d-block d-lg-none ${
            showSearch.current ? "is-active" : ""
          }`}
        >
          <div className="mb-4">
            <img
              src={leftArrow}
              alt={t("alt.Back")}
              className="search-back"
              // onClick={HideMobileSearchBar}
              onClick={() => {
                setSearchBar(false);
                onChangeSearch("");
                showSearch.current = false;
              }}
            />
          </div>
          <div>
            <input
              type="text"
              className={`search-bar ${
                searchResult?.length > 0 ? "movie-list" : ""
              }`}
              value={searchTerm}
              onChange={(e) => {
                onChangeSearch(e.target.value);
              }}
              placeholder={t("Search for movies")}
              ref={mobileSearchInputEle}
              autoFocus
            />
          </div>
          {/* {searchResult?.length > 0 ? (
          <aside className={``}>
            {searchResult.map((search, index) => (
              <SearchedMovie
                type="yellow"
                movie={search}
                key={index}
                onChangeSearch={onChangeSearch}
                t={t}
              />
            ))}
          </aside>
        ) : */}
          {searchResult?.length > 0 ? (
            <article className="row">
              {searchResult.map((search, index) => (
                <MovieCard
                  {...search}
                  key={index}
                  index={index}
                  showMovieType={true}
                  clickFunc={() => {
                    onChangeSearch("");
                    // HideMobileSearchBar();
                    setSearchBar(false);
                    showSearch.current = false;
                    reRender({});
                    history.replace(
                      `/movie-details/${search.movie_id}/${search.content_lang_id}`
                    );
                  }}
                />
              ))}
            </article>
          ) : searchTerm && searchResult && searchResult.length === 0 ? (
            <h6 className="text-center mt-3">{t("common.No movies found")}</h6>
          ) : searchTerm.length > 1 && searchMoviesLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Spinner
                animation="border"
                role="status"
                className="mx-auto mt-3"
              />
            </div>
          ) : null}
          {/* } searchTerm && searchResult && searchResult.length === 0 ? (
        <h6 className="text-center mt-3">{t("common.No movies found")}</h6>) :
        null} */}
          {/* {searchTerm && searchResult === null && (

                    )} */}
        </div>
      )}
      {/* End Search Menu */}
      {/* End Mobile Menu Header */}
    </header>
  );
};
// <aside className="">
// </aside>
//   <SearchedMovie type="yellow" />
//   <SearchedMovie type="green" />
//   <SearchedMovie type="red" />
export default withNamespaces()(Header);
