import React, {useState, useRef, useEffect} from "react";
import {withNamespaces} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import ModalVideo from "react-modal-video";
import useIsMobile from "../../../customHooks/useIsMobile";
import {currencyFormatter} from "../../../helper/currencyFormatter";

// Components
import CreatePrivateScreen from "./CreatePrivateScreen";
import Button from "@components/partials/Button"

// Images
import {ReactComponent as TrailerIcon} from "@assets/pictures/svgs/play_new.svg";
import {ReactComponent as ArrowLeft} from "@assets/pictures/svgs/slider-arrow-left.svg";
import dummyPosterStatic from "@assets/pictures/dummyPoster.png";
import poster from "@assets/pictures/sample-poster-new.png";
import {ReactComponent as TrailerIconMobile} from "@assets/pictures/svgs/play_new_mobile.svg";


import {generateMovieVersionsText,} from "@helper/formatting";
import {hasDirector, hasStaring} from "../../../helper";
import {getLangSpecificAttribute} from "../../../helper/languages";
import Spinner from "../../partials/Spinner";
import {useHistory, useParams} from "react-router-dom";
import {getMovieVersionsText} from "../../../helper/formatting";
import {setOndemandDate, setOndemandLang} from "../../../store/movies/movieActions";
import _ from "lodash";

const MovieInfo = (props) => {
  const { t, isPrivate=false, onClickNext, fromSchedulePage = false } = props;

  const dispatch = useDispatch()
  const history = useHistory()
  const { movie_id, content_lang_id } = useParams()

  const selected_language = useSelector((state) => state.movies.selected_language)
  const { lang_id, lang_name, iso_2 } = selected_language;
  const isMobile = useIsMobile();
  const movieSynopsisRef = useRef();

  const organization = useSelector((state) => state.movies.organizationDetails)
  const {dummy_poster: dummyPosterDynamic} = organization;

  const dummyPoster = window.localStorage.getItem("placeholder_image")
    ? window.localStorage.getItem("placeholder_image")
    : dummyPosterStatic;

  const [moreDetails, setMoreDetails] = useState(false)
  const [isTrailerOpen, setTrailerOpen] = useState(false)
  const [currentVideoId, setCurrentVideoId] = useState(null)
  const [videoChannel, setVideoChannel] = useState("youtube")
  const [selectedCinema, setSelectedCinema] = useState(null)

  const movieDetails = useSelector((state) => state.movies.movieDetails)
  const movieDetailsLoading = useSelector((state) => state.movies.movieDetailsLoading)
  const selectedCinemaFromLocal = useSelector((state) => state.movies.selectedCinema)

  let activeCinema = selectedCinema ? selectedCinema : selectedCinemaFromLocal
  const [movieInfoObj, setMovieInfoObj] = useState({})

  const movieSchedules = useSelector((state) => state.movies.movieSchedules)
  const isCreateShowEnabled = useSelector((state) => state.movies.createYourShowEnabled);
  const createYourShowPrice = useSelector((state) => state.movies.createYourShowPrice);
    const currency = useSelector((state) => state.movies.currency);
  const {curr_code, curr_id} = currency;

  const getLabelToshow = (labels) => {
    let tempArray = [];
    if (labels?.length > 0) {
      let sortedArray = _.sortBy(labels, (e) => e.order)
      let first_label_id = sortedArray[0]?.label_id;
      tempArray = labels?.filter((x) => x.label_id == first_label_id)
    } else {
      tempArray = [];
    }
    return tempArray
  }

  useEffect(() => {
    setMovieInfoObj({
      image:
        getLangSpecificAttribute(
          movieDetails?.languageSpecificImages,
          lang_id,
          "artwork"
        ) || dummyPoster,
      movie_title:
        getLangSpecificAttribute(
          movieDetails?.altMovieContent,
          lang_id,
          "mc_title",
          undefined,
          undefined,
          undefined,
          true,
        ) || movieDetails?.original_title_of_movie || movieDetails?.movie_title,
      lang: undefined, // movieDetails?.version_name || t(selectedLanguage?.lang_name),
      format: undefined, //selectedFormat?.mf_name,
      genre:
        movieDetails?.genres_with_translations
        ?.map((g) => g[iso_2?.toLowerCase()])
        .filter((x) => x)
        .splice(0, 13)
        .join(", ") || movieDetails?.genre,
      rating: movieDetails?.rating,
      trailers: movieDetails?.trailer_array ?? movieInfoObj?.trailers ?? [],
      duration: movieDetails?.mrrdr_runtime ?? '',
      year: (movieDetails?.mrrdr_release_date || movieDetails?.original_mrrdr_release_date || '')?.split('-')?.[0] ?? '',
      plot: getLangSpecificAttribute(
        movieDetails?.altMovieContent,
        lang_id,
        "mc_plot"
      ) ?? movieDetails?.mc_plot,
      castCrewDetails: movieDetails?.castCrewDetails ?? movieInfoObj?.castCrewDetails ?? [],
      languages:
        movieDetails?.languages_with_translations?.map(l => l[iso_2?.toLowerCase()])
        .filter(x => x)
        .filter((item, pos, arr) => arr.indexOf(item) == pos)
          ?? movieInfoObj?.languages ?? [],
      availLanguagesForOndemand:
        movieDetails?.movie_versions?.filter(x => x)
        .filter((item, pos, arr) => {
          if (!item?.cinema_details) return false
          if (!item?.md_licence_end_date) return false
          if (
            item?.is_confirmed_show === 'Y' ||
            item?.is_private_show === 'Y' ||
            item?.is_crowd_show === 'Y'
          )
            return true
        })
        ?? movieInfoObj?.availLanguagesForOndemand ?? [],
      versions:
        getMovieVersionsText(
          movieDetails?.movie_versions,
          activeCinema?.region_name
        ).map((item, i) => item)
        ?? movieInfoObj?.versions ?? [],
      original_lang: movieDetails?.lang_name ??  movieInfoObj?.original_lang ?? '',
      label_to_show:
        getLabelToshow(movieDetails?.movie_labels_with_translations?.[0] || [])
        ?? movieInfoObj?.label_to_show ?? [],
    })
  }, [iso_2, lang_id, movieDetails])

    useEffect(() => {
    if (movieSynopsisRef.current) {
      const movieSynopsis = movieSynopsisRef.current;
    }
  }, [movieSynopsisRef.current])

  useEffect(() => {
    if (isTrailerOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = ""
    }
    return () => {
      document.body.style.overflow = ""
    }
  }, [isTrailerOpen])

  if (movieDetailsLoading) return (<div className="row">
    <div className="col-12 text-center">
      <Spinner/>
    </div>
  </div>)

  return (
    <>
      <div className={`movie_info_wrapper ${(!isMobile && !isTrailerOpen) ?"sticky_movie_info" : ""}`}>
        {isMobile ?
          // MOBILE DESIGN
          <>
            {/* MOVIE INFO */}
            <div className="movie_info_mobile">
              <figure>
                <img src={movieInfoObj.image} alt="" />
                {movieInfoObj?.label_to_show?.length > 0 && (
                  <article className="movie_info_label_wrapper">
                    <span className="movie_info_label_background" />
                    <span className="movie_info_label">
                      {getLangSpecificAttribute(
                        movieInfoObj?.label_to_show,
                        lang_id,
                        "label_name",
                        "lang_id"
                      )  || movieInfoObj?.label_to_show?.[0].label}
                    </span>
                  </article>
                )}
              </figure>
              <article>
                <section>
                  <h6>{
                    // t('On-demand').toUpperCase()
                  }</h6>
                 <h1 className="movie_info_title">
                    {movieInfoObj.movie_title}
                    {movieInfoObj.year && <span>&nbsp;({movieInfoObj.year})</span>}
                  </h1>
                  <div className="movie_info_rating_n_format mb-0">


                    <article className="movie_info_language">
                      {movieInfoObj.duration} {t("common.mins")}
                    </article>
                 <span className="movie_info_rating">
              {movieInfoObj.rating}
                  </span>
                    </div>
                </section>
          {/* GENRE, YEAR & DURATION */}
                {/* <section>
                  <article>
                  <h6>{t('Genre')}</h6>
                  <h5>{movieInfoObj.genre}</h5>
                  </article>
                   <article>
                  <h6>{t('Year')}</h6>
                  <h5>{movieInfoObj.year}</h5>
                  </article>
                  <article>
                  <h6>{t('Duration')}</h6>
                  <h5>{movieInfoObj.duration} {t("common.mins")}</h5>
                </article>
                </section> */}
                <section className={`${ isPrivate? "movie_info_trailers_mobile_gold" : "movie_info_trailers_mobile"}`}>
            <ModalVideo
              autoplay
              channel={videoChannel}
              isOpen={isTrailerOpen}
              videoId={currentVideoId}
              onClose={() => setTrailerOpen(false)}
            />
              {movieInfoObj.trailers?.map(t => (
                  <div className={`${ isPrivate? "movie_info_trailer_gold" : "movie_info_trailer "}`}
                    key={t?.mt_link}
                           onClick={() => {
                             if (t?.mt_link?.includes("youtube")) {
                               setVideoChannel("youtube");
                               setCurrentVideoId(
                                 t?.mt_link?.split("=")[1]
                               );
                             }
                             if (t?.mt_link?.includes("vimeo")) {
                               setVideoChannel("vimeo");
                               setCurrentVideoId(
                                 t?.mt_link
                                 ?.split("/")
                                 .pop()
                                 .split(".")?.[0]
                               );
                             }
                             setTrailerOpen(true);
                           }}
                  >
                    <TrailerIconMobile/>
                    <p>
                      {/*{generateMovieVersionsText(*/}
                      {/*  activeCinema?.region_name,*/}
                      {/*  t.iso_3,*/}
                      {/*  t.sub_1_iso_3,*/}
                      {/*  t.sub_2_iso_3,*/}
                      {/*  t.iso_2,*/}
                      {/*)}*/}
                      {t.iso_3}
                    </p>
                  </div>
              ))}
            </section>
              </article>
            </div>

            <div className={`movie_info_tabs`}>
              <p className={`${!moreDetails ? "active" : ""} ${!moreDetails && isPrivate ? " golden_line" : ""}`}
                 onClick={() => setMoreDetails(false)}>{t("Story")}</p>
              <p className={`${moreDetails ? "active" : ""} ${moreDetails && isPrivate ? " golden_line" : ""}`}
                 onClick={() => setMoreDetails(true)}>{t("More details")}</p>
            </div>
             <section className="movie_info_more_details">
            {/* MOVIE DESCRIPTION */}
            {movieInfoObj.plot && (
              <p className={`movie_info_synopsis ${(false && !moreDetails) ? "movie_info_synopsis_partial" : ""}`} ref={movieSynopsisRef}>
                    {movieInfoObj.plot}
              </p>
            )}

            {moreDetails &&
                <>
                {/* MOVIE LANGUAGE */}
                   {movieInfoObj?.original_lang && (
                    <div>
                      <h6>
                        {t("Language")}:{' '}
                      </h6>
                      <p>
                        {t(movieInfoObj?.original_lang)}
                </p>
              </div>
                  )}

                  {/* MOVIE GENRES */}
                  {movieInfoObj.genre && (
                <div>
                      <h6>{t('Genre')}:{" "}</h6>
                  <p>{movieInfoObj.genre}</p>
                </div>
            )}
              {/* MOVIE CAST */}
              {hasStaring(movieInfoObj.castCrewDetails) && (
                <div>
                  <h6>
                    {t("Cast")}:{' '}
                  </h6>
                  <p className="comma-separated">
                    {movieInfoObj.castCrewDetails?.map(
                      (cast) =>
                        cast?.crew_title !== `Director` && (
                          <>
                            {cast?.Crew?.filter(
                              (c) => c.mcc_cast_name
                            ).map((crew, i, crr) => (
                              <span key={i}>
                                  {crew.mcc_cast_name}
                                </span>
                            ))}
                          </>
                        )
                    )}
                  </p>
                </div>
              )}

              {/* MOVIE DIRECTOR */}
              {hasDirector(movieInfoObj.castCrewDetails) && (
                <div>
                  <h6>
                    {t("Director")}:{' '}
                  </h6>
                  <p className="comma-separated">
                    {movieInfoObj.castCrewDetails?.map(
                      (cast, index) =>
                        cast?.crew_title === `Director` && (
                          <>
                            {cast?.Crew?.filter(
                              (c) => c.mcc_cast_name
                            ).map((crew, i, crr) => (
                              <span key={i}>
                                  {crew.mcc_cast_name}
                                </span>
                            ))}
                          </>
                        )
                    )}
                  </p>
                </div>
              )}
            </>
            }
            </section>
            <Button text={t("Book Now")} classes="mx-auto w-100" onClick={onClickNext} isPrivate={isPrivate}/>

            {
              !isPrivate &&
              isCreateShowEnabled &&
              (!!movieSchedules?.isShowScreening || !!fromSchedulePage) &&
              (<>
            <p className="text-center my-3">
              <small>{t('OR')}</small>
            </p>
            <article className="movie_info_private_screening col-12">
            {/* <CreatePrivateScreen onClick={() => {
                console.log('REDIRECTING TO PRIVATE SCREENING')
                history.push(`/private-booking-flow/${movie_id}/${content_lang_id}/`)
            }}/> */}
            <section className="pvt_sec_mob py-2 px-3 col-xl-12">
            <div className="pvt_screen" >
            <span style={{color:"#C09E3F",fontFamily:"BwModelicaBold"}}>
              {t("Private Screening")}
            </span><span className="px-1 line" style={{color:"#333333",fontWeight:"500"}}>|</span>
            <span style={{color:"#45bb4d",fontFamily:"BwModelicaBold"}}>
              {t("Confirmed Screening")}
            </span>
            </div>

              <p className="pvt_p">
                <span>
                  {t("Available languages in")} {t("Private Screening")}/{t("Confirmed Screening")}
                </span>
              </p>
                <div className="movie_info_language_wrapper">
              {!isPrivate && (
                movieInfoObj?.availLanguagesForOndemand?.map(l => (
                  <>
                    <article
                      style={{ cursor: 'pointer' }}
                      className="movie_info_language_screening"
                      onClick={() => {
                        dispatch(setOndemandDate())
                        dispatch(setOndemandLang({
                          lang_id: l?.movie_language_id,
                          lang_name: l?.movie_lang,
                        }))

                        console.log('REDIRECTING TO PRIVATE SCREENING')
                        history.push(`/private-booking-flow/${movie_id}/${content_lang_id}/${
                          // l?.movie_language_id
                          encodeURIComponent(`${ t(l?.movie_lang) != null ? t(l?.movie_lang) : l?.movie_lang }${
                            l?.sub_lang_1_iso_2 ? `/${l?.sub_lang_1_iso_2}` : ''
                          }${
                            l?.sub_lang_1_iso_2 && l?.sub_lang_2_iso_2 ? `-${l?.sub_lang_2_iso_2}` : ''
                          }`)
                        }`)
                      }}
                    >
                      {
                        `${ t(l?.movie_lang) != null ? t(l?.movie_lang) : l?.movie_lang }${
                          l?.sub_lang_1_iso_2 ? `/${l?.sub_lang_1_iso_2}` : ''
                        }${
                          l?.sub_lang_1_iso_2 && l?.sub_lang_2_iso_2 ? `-${l?.sub_lang_2_iso_2}` : ''
                        }`
                      }
                    </article>
                  </>
                )))
              }
            </div>
            { /* {createYourShowPrice && <p className="pvt_p py-1">*/}
                  {/* <span> */}
                 { /*{t('Starting at')} */}
                {/* </span><span> */}
                 { /* &nbsp;{curr_code} {currencyFormatter(createYourShowPrice)} */}
                   {/* </span> */}
                  {/* </p>
          }*/}
            {/* <CreatePrivateScreen onClick={() => {
              console.log('REDIRECTING TO PRIVATE SCREENING')
              history.push(`/private-booking-flow/${movie_id}/${content_lang_id}/`)
                }} /> */}
                <Button text={t('Create private screening')} onClick={()=>history.push(`/private-booking-flow/${movie_id}/${content_lang_id}/`)} classes="private_screen_btn"/>
                </section>
            </article>
            </>)
            }
            </>
          :

          // DESKTOP/TABLET DESIGN
          <>
        {/* MOVIE POSTER */}
        <figure>
          <img src={movieInfoObj.image} alt=""/>

          {/* MOVIE TRAILERS */}
          <figcaption className="movie_info_trailers">
            <ModalVideo
              autoplay
              channel={videoChannel}
              isOpen={isTrailerOpen}
              videoId={currentVideoId}
              onClose={() => setTrailerOpen(false)}
            />
            <ul className="list-inline">
              {movieInfoObj.trailers?.map((t, index) => (
                <li className="list-inline-item" key={t?.mt_link + index}>
                  <article className={`${ isPrivate? "movie_info_trailer_gold" : "movie_info_trailer"}`}
                           onClick={() => {
                             if (t?.mt_link?.includes("youtube")) {
                               setVideoChannel("youtube");
                               setCurrentVideoId(
                                 t?.mt_link?.split("=")[1]
                               );
                             }
                             if (t?.mt_link?.includes("vimeo")) {
                               setVideoChannel("vimeo");
                               setCurrentVideoId(
                                 t?.mt_link
                                 ?.split("/")
                                 .pop()
                                 .split(".")?.[0]
                               );
                             }
                             setTrailerOpen(true);
                           }}
                  >
                    <TrailerIcon/>
                    <p>
                      {/*{generateMovieVersionsText(*/}
                      {/*  activeCinema?.region_name,*/}
                      {/*  t.iso_3,*/}
                      {/*  t.sub_1_iso_3,*/}
                      {/*  t.sub_2_iso_3,*/}
                      {/*  t.iso_2,*/}
                      {/*)}*/}
                      {t.iso_3}
                    </p>
                  </article>
                </li>
              ))}
            </ul>
            {/* <h6>
              {movieInfoObj.trailers?.length > 0 ? t('Trailers') : ''}
            </h6> */}
          </figcaption>

          {movieInfoObj?.label_to_show?.length > 0 && (
            <article className="movie_info_label_wrapper">
              <span className="movie_info_label_background" />
              <span className="movie_info_label">
              {getLangSpecificAttribute(
                movieInfoObj?.label_to_show,
                lang_id,
                "label_name",
                "lang_id"
              ) || movieInfoObj?.label_to_show?.[0]?.label}
             </span>
            </article>
          )}
        </figure>

        {/* MOVIE INFO */}
            <article className="movie_info">
              {!isPrivate && (<h6 className="movie_booking_type">{t('Now Showing').toUpperCase()}</h6>)}
              {!!isPrivate && (<h6 className="movie_booking_type_golden">{t('On-demand').toUpperCase()}</h6>)}
          {/* MOVIE TITLE */}
          <h1 className="movie_info_title">
                {movieInfoObj.movie_title}
                {movieInfoObj.year && <span>&nbsp;({movieInfoObj.year})</span>}
          </h1>
          <div className="movie_info_rating_n_format">
          {/*{!isPrivate && isCreateShowEnabled && movieSchedules?.isShowScreening && (
          <button className= "btn_book_now " style={{backgroundColor:"#e8308a"}}>Polish/DE-FR</button>
          )}*/}
            {movieInfoObj.duration && <article className="movie_info_language">
              {movieInfoObj.duration} {t("common.mins")}
            </article>}
            <span className="movie_info_rating">
              {movieInfoObj.rating}
            </span>
            {/*{!isPrivate && (*/}
            {/*  movieInfoObj?.languages?.map(l => (*/}
            {/*    <>*/}
            {/*      <article className="movie_info_language">*/}
            {/*        {l}*/}
            {/*      </article>*/}
            {/*    </>*/}
            {/*  )))*/}
            {/*}*/}
            {/* {movieInfoObj?.original_lang && (
              <article className="movie_info_language">
                { !!t(movieInfoObj?.original_lang) ? t(movieInfoObj?.original_lang) : movieInfoObj?.original_lang}
              </article>
            )} */}
          </div>

          {/* GENRE, YEAR & DURATION */}
          {/* <ul className="list-inline movie_info_props">
            {movieInfoObj.genre && (
              <li className="list-inline-item" id="genre">
                <article>
                  <h6>{t('Genre')}</h6>
                  <h5>{movieInfoObj.genre}</h5>
                </article>
              </li>
            )}
            {movieInfoObj.year && (
              <li className="list-inline-item" id="year">
                <article>
                  <h6>{t('Year')}</h6>
                  <h5>{movieInfoObj.year}</h5>
                </article>
              </li>
            )}
            {movieInfoObj.duration && (
              <li className="list-inline-item" id="duration">
                <article>
                  <h6>{t('Duration')}</h6>
                  <h5>{movieInfoObj.duration} {t("common.mins")}</h5>
                </article>
              </li>
            )}
          </ul> */}

          <section className="movie_info_more_details">
            {/* MOVIE DESCRIPTION */}
            {movieInfoObj.plot && (
              <p className={`movie_info_synopsis ${!moreDetails ? "movie_info_synopsis_partial" : ""}`}>
                {movieInfoObj.plot}
              </p>
            )}

            {moreDetails &&
                  <>
                  {/* MOVIE YEAR */}
                  {/* {movieInfoObj.year && (
                <div>
                      <h6>{t('Year')}:{" "}</h6>
                  <p>{movieInfoObj.year}</p>
                </div>
            )} */}
                  {/* MOVIE LANGUAGE */}
                   {movieInfoObj?.original_lang && (
                    <div>
                      <h6>
                        {t("Language")}:{' '}
                      </h6>
                      <p>
                        {t(movieInfoObj?.original_lang)}
                </p>
              </div>
                  )}

                  {/* MOVIE GENRES */}
                  {movieInfoObj.genre && (
                <div>
                      <h6>{t('Genre')}:{" "}</h6>
                  <p>{movieInfoObj.genre}</p>
                </div>
            )}
                  
              {/* MOVIE CAST */}
              {hasStaring(movieInfoObj.castCrewDetails) && (
                <div>
                  <h6>
                    {t("Cast")}:{' '}
                  </h6>
                  <p className="comma-separated">
                    {movieInfoObj.castCrewDetails?.map(
                      (cast, index) =>
                        cast?.crew_title !== `Director` && (
                          <React.Fragment key={index}>
                            {cast?.Crew?.filter(
                              (c) => c.mcc_cast_name
                            ).map((crew, i, crr) => (
                              <span key={`${index}-${i}`}>
                                  {crew.mcc_cast_name}
                                </span>
                            ))}
                          </React.Fragment>
                        )
                    )}
                  </p>
                </div>
              )}

                  

              {/* MOVIE DIRECTOR */}
              {hasDirector(movieInfoObj.castCrewDetails) && (
                <div>
                  <h6>
                    {t("Director")}:{' '}
                  </h6>
                  <p className="comma-separated">
                    {movieInfoObj.castCrewDetails?.map(
                      (cast, index) =>
                        cast?.crew_title === `Director` && (
                          <React.Fragment key={index}>
                            {cast?.Crew?.filter(
                              (c) => c.mcc_cast_name
                            ).map((crew, i, crr) => (
                              <span key={`${index}-${i}`}>
                                  {crew.mcc_cast_name}
                                </span>
                            ))}
                          </React.Fragment>
                        )
                    )}
                  </p>
                </div>
              )}
            </>
            }

            <h6 className={`movie_info_view_more_details py-2 ${ isPrivate? " movie_info_view_more_details_gold " : ""} ${moreDetails ? "collapse" : ""}${ isPrivate? " goldarrow" : " "}`} onClick={() => setMoreDetails(!moreDetails)}>
                  <span>
                    {moreDetails ? t('View Less details') : t('View More details')}
                  </span>
              <ArrowLeft/>
            </h6>
          </section>
        </article>

        {/* PRIVATE SCREENING OPTIONS FOR REGULAR SCREENING */}
        {
          !isPrivate &&
          isCreateShowEnabled &&
          (!!movieSchedules?.isShowScreening || !!fromSchedulePage) &&
          (
          <article className="movie_info_private_screening ">
          
            <section className="pvt_sec py-2 px-3 col-xl-12">
            <div className="pvt_screen" >
            <span style={{color:"#C09E3F"}}>
              {t("Private Screening")}
            </span>
            <span className="px-1" style={{color:"#333333",fontWeight:"500"}}>|</span>
            <span style={{color:"#45bb4d"}}>
              {t("Confirmed Screening")}
            </span>
            </div>
            <p className="pvt_p">
              <span>
                {t("Available languages in")} {t("Private Screening")}/{t("Confirmed Screening")}
              </span>
                </p>
                <div className="movie_info_language_wrapper">
              {!isPrivate && (
                movieInfoObj?.availLanguagesForOndemand?.map(l => (
                  <>
                    <article
                      style={{ cursor: 'pointer' }}
                      className="movie_info_language_screening"
                      onClick={() => {
                        dispatch(setOndemandDate())
                        dispatch(setOndemandLang({
                          lang_id: l?.movie_language_id,
                          lang_name: l?.movie_lang,
                        }))

                        console.log('REDIRECTING TO PRIVATE SCREENING')
                        history.push(`/private-booking-flow/${movie_id}/${content_lang_id}/${
                          // l?.movie_language_id
                          encodeURIComponent(`${ t(l?.movie_lang) != null ? t(l?.movie_lang) : l?.movie_lang }${
                            l?.sub_lang_1_iso_2 ? `/${l?.sub_lang_1_iso_2}` : ''
                          }${
                            l?.sub_lang_1_iso_2 && l?.sub_lang_2_iso_2 ? `-${l?.sub_lang_2_iso_2}` : ''
                          }`)
                        }`)
                      }}
                    >
                      {
                        `${ t(l?.movie_lang) != null ? t(l?.movie_lang) : l?.movie_lang }${
                          l?.sub_lang_1_iso_2 ? `/${l?.sub_lang_1_iso_2}` : ''
                        }${
                          l?.sub_lang_1_iso_2 && l?.sub_lang_2_iso_2 ? `-${l?.sub_lang_2_iso_2}` : ''
                        }`
                      }
                    </article>
                  </>
                )))
              }
            </div>
              { /* {createYourShowPrice && <p className="pvt_p py-1">*/}
                  {/* <span> */}
                 { /*{t('Starting at')} */}
                {/* </span><span> */}
                 { /* &nbsp;{curr_code} {currencyFormatter(createYourShowPrice)} */}
                   {/* </span> */}
                  {/* </p>
          }*/}
            {/* <CreatePrivateScreen onClick={() => {
              console.log('REDIRECTING TO PRIVATE SCREENING')
              history.push(`/private-booking-flow/${movie_id}/${content_lang_id}/`)
                }} /> */}
                <Button text={t('Create private screening')} onClick={()=>history.push(`/private-booking-flow/${movie_id}/${content_lang_id}/`)} classes="private_screen_btn"/>
                </section>
          </article>
            )}
            </>
        }
      </div>
    </>
  );
}

export default withNamespaces()(MovieInfo);
