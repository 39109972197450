export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";

export const REGISTER = "REGISTER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";

export const FETCH_USER_HISTORY = "FETCH_USER_HISTORY";
export const FETCH_USER_HISTORY_SUCCESS = "FETCH_USER_HISTORY_SUCCESS";
export const FETCH_UNPAID_USER_HISTORY_SUCCESS = "FETCH_UNPAID_USER_HISTORY_SUCCESS";
export const FETCH_USER_HISTORY_ERROR = "FETCH_USER_HISTORY_ERROR";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";

export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

export const OTP_SUCCESS = "OTP_SUCCESS";
export const OTP_CLEAR = "OTP_CLEAR";
export const OTP_ERROR = "OTP_ERROR";

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_SUCCESS_ERROR = "RESET_PASSWORD_SUCCESS_ERROR";

export const LOGIN_VIA_OTP_SUCCESS = "LOGIN_VIA_OTP_SUCCESS";
export const LOGIN_VIA_OTP_ERROR = "LOGIN_VIA_OTP_ERROR";

export const START_OTP_TIMER = "START_OTP_TIMER";
export const STOP_OTP_TIMER = "STOP_OTP_TIMER";
export const RESET_OTP_TIMER = "RESET_OTP_TIMER";

export const CONTACTUSMAIL = "CONTACTUSMAIL";
export const SHOW_LOGIN_POPUP = "SHOW_LOGIN_POPUP";
export const LOGIN_VIA_OTP_SUCCESS_LOGIN_FIELD = "LOGIN_VIA_OTP_SUCCESS_LOGIN_FIELD";

export const GUEST_REGISTER = "GUEST_REGISTER";
export const GUEST_REGISTER_SUCCESS = "GUEST_REGISTER_SUCCESS";
