import React, {useState, useEffect} from "react";
import { withNamespaces } from "react-i18next";

// Components
import { Accordion, Card } from "react-bootstrap";
import CenteredTitle from "@components/partials/CenteredTitle";
import { useSelector } from "react-redux";
import BreadCrumb from "@components/partials/BreadCrumbs";
import searchIcon from "@assets/pictures/svgs/search.svg";
import close from "@assets/pictures/svgs/close.svg";
import InlineScroll from "@components/partials/InlineScroll";
import useIsMobile from "../customHooks/useIsMobile";
const FAQNewContainer = ({ t }) => {
  const selected_language = useSelector(
    (state) => state.movies.selected_language
  )
  const isMobile = useIsMobile();
    const lengthOfFaqsArray = 10;
      const [activeAccordionTab, setActiveAccordionTab] = useState([...new Array(lengthOfFaqsArray)].map((item, index) => index ? false : true));
    const [activeAccordionTabIndex, setActiveAccordionTabIndex] = useState("0");
      const handleAccordionTab = (index) => {
    let newActiveAccordionTab = Array(lengthOfFaqsArray).fill(false);
    if (activeAccordionTab[index]) {
      setActiveAccordionTabIndex(null)
      newActiveAccordionTab[index] = false
    } else {
      setActiveAccordionTabIndex(String(index));
      newActiveAccordionTab[index] = true
    }
    setActiveAccordionTab(newActiveAccordionTab)
    }

    let [faqs, setFaqs] = useState([])
    let [filteredFaq, setFilteredFaq] = useState([])
    let [categories, setCategories] = useState([])

  useEffect(() => {
    let faqs = [];
    let categories = new Set();

    for (let i = 1; i < 22; i++) {
      let faq = {
        q: t(`FAQ.Q${i}`),
        a: t(`FAQ.A${i}`),
        category: "General",
        categoryDisplay: t("General")
      };

      if (i >= 12 && i <= 16) {
        faq.category = "On Demand";
        faq.categoryDisplay = t("On Demand");
      } else if (i >= 16 && i <= 21) {
        faq.category = "About Cinema";
        faq.categoryDisplay = t("About Cinema");
      }

      faqs.push(faq);
      categories.add(JSON.stringify({
        category: faq.category,
        categoryDisplay: faq.categoryDisplay
      }));
    }

    setFaqs(faqs);
    setFilteredFaq(faqs)
    setCategories(Array.from(categories.map(c => JSON.parse(c))))
  }, [selected_language]);


  let [search, setSearched] = useState('')
  let [selectedCategory, setSelectedCategory] = useState('')

  useEffect(() => {
    let filteredBySearch = [...faqs]

    if (!!selectedCategory){
      filteredBySearch = filteredBySearch.filter(f => {
        return f.category == selectedCategory
      })
    }

    if (!!search) {
      filteredBySearch = filteredBySearch.filter(f => {
        if (f.q.toLowerCase().includes(search.toLowerCase()))
          return true
        else if (f.a.toLowerCase().includes(search.toLowerCase()))
          return true

        return false
      })
    }

    setFilteredFaq(filteredBySearch)
  }, [faqs, search, selectedCategory])
 
    
    return ( 
        <>
            <div className="container-fluid faq_new align_new">
                <section className="row">
                  <div className={isMobile ? "left_right_align" : ""}>
                <BreadCrumb firstHalf={`${t('More')} >`} secondHalf=" FAQ’s"/>
                <p className="faq_title">Frequently Asked Questions</p>
                  { /*<CenteredTitle firstHalf={t("Frequently Asked Questions")} secondHalf=""/>*/}
                        </div>
                    <article className="col-lg-10 col-xl-8 mx-auto">
                    <label className="fnb-search mb-4" style={{width:"80%"}}>
                  <img
                    src={searchIcon}
                    alt="search"
                    onClick={(e) => {}}
                  />
                  <input
                    type="text"
                    placeholder={t("Search for concessions")}
                    value={search}
                    onChange={(e) => {
                      setSearched(e?.target?.value || '')
                    }}
                    style={{borderRadius:"38px"}}
                  />
                  <img
                    src={close}
                    alt="close"
                    className={`${!!search ? "show" : ''}`}
                    onClick={() => {
                      setSearched('')
                      setSelectedCategory('')
                    }}
                  />
                </label>
                      <div>
                    <InlineScroll>
                      {
                        categories.map((c,i) =>
                          <button
                            key={i}
                            className={`faq_btn${c.category === selectedCategory ? '' : '_inactive' }`}
                            onClick={() => {
                              if (c.category === selectedCategory)
                                setSelectedCategory('')
                              else
                                setSelectedCategory(c.category)
                            }}
                          >
                            {c.categoryDisplay}
                          </button>
                        )
                      }
            </InlineScroll>
            </div>
                <Accordion
                            activeKey={activeAccordionTabIndex}
                            defaultActiveKey={activeAccordionTabIndex}
              className="faq_new_accordion"
            >
                    {filteredFaq.map((faq, index) => (
                <Card key={index}>
                <Accordion.Toggle as={Card.Header} eventKey={`${index}`}
                                className={`${activeAccordionTab[index] ? "active" : ""}`}
                                onClick={()=>handleAccordionTab(index)}
                            >
                                <p>
                                  {faq.q} {/*({faq.categoryDisplay})*/}
                                </p>
                </Accordion.Toggle>
                            <Accordion.Collapse eventKey={`${index}`}>
                                <p>
                                  {faq.a}
                                </p>
                </Accordion.Collapse>
                    </Card>  
              ))}
                    </Accordion>
                    </article>
                </section>
        </div>
        </>
     );
}
 
export default withNamespaces()(FAQNewContainer);
