import AuthService from "@apiService/AuthService";
import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  FETCH_USER_HISTORY,
  FETCH_USER_HISTORY_SUCCESS,
  FETCH_USER_HISTORY_ERROR,
  LOGOUT,
  UPDATE_USER_SUCCESS,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  OTP_SUCCESS,
  OTP_CLEAR,
  OTP_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_SUCCESS_ERROR,
  LOGIN_VIA_OTP_SUCCESS,
  LOGIN_VIA_OTP_ERROR,
  START_OTP_TIMER,
  STOP_OTP_TIMER,
  RESET_OTP_TIMER,
  CONTACTUSMAIL,
  SHOW_LOGIN_POPUP,
  LOGIN_VIA_OTP_SUCCESS_LOGIN_FIELD,
  FETCH_UNPAID_USER_HISTORY_SUCCESS,
  GUEST_REGISTER,
  GUEST_REGISTER_SUCCESS,
} from "./authTypes";
import { toast } from "react-toastify";
import {getLangISOCode} from "../../helper/formatting";
import i18n from "../../plugins/i18n";
import moment from "moment";
import {setSelectedLanguage} from "../movies/movieActions";
const _ = require("lodash");

export const login = ({ loginField, password, callback, countryId }) => async (
  dispatch,
  getStore,
) => {
  let state = getStore()

  try {
    dispatch({ type: LOGIN });
    const payload = { password, countryId };

    if (emailRegex(loginField)) {
      payload.email = loginField;
    } else {
      payload.mobileNo = loginField;
    }

    const { data } = await AuthService.Login(payload);

    if (data.status && data.Records) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: data.Records[0],
      });
      localStorage.setItem("user", JSON.stringify(data.Records[0]));
      // toast.dismiss();
      // toast.success(`Login successful.`);

      // set lang to user specific
      let globalOptions = state?.movies?.globalOptions ?? []
      const kioskLang = globalOptions.find((go) => go.go_key === "KIOSK_LANGUAGE");
      const availLangs = JSON.parse(kioskLang?.go_value || []);
      let [selectedLang] = availLangs.filter((l) => data.Records[0]?.preferred_lang_id == l?.lang_id);

      console.log({
        'preferred_lang_id': data.Records[0]?.preferred_lang_id,
        kioskLang,
        availLangs,
        selectedLang
      });

      if (data.Records[0]?.preferred_lang_id &&
          availLangs.length > 0 &&
          selectedLang
      ) {
          dispatch(setSelectedLanguage(selectedLang));

          //setting lang for moment
          let langMoment = selectedLang.iso_2
              ? selectedLang.iso_2.toLowerCase()
              : getLangISOCode(selectedLang.lang_name);
          i18n.changeLanguage(langMoment);
          moment.locale(langMoment.toLowerCase());
      }
      //
    } else {
      dispatch({ type: LOGIN_ERROR, payload: data.message });
      // toast.dismiss();
      // toast.error(data.message);
    }
    callback(false);
    return data?.status
  } catch (error) {
    dispatch({ type: LOGIN_ERROR, payload: error });
  }
};

export const register = ({
  email,
  first_name,
  last_name,
  password,
  phone_no,
  country_id,
  callback,
  dob = undefined,
}) => async (dispatch) => {
  console.log("country_id", country_id);
  try {
    dispatch({ type: REGISTER });
    const { data } = await AuthService.Register({
      email,
      first_name,
      last_name,
      password,
      phone_no,
      country_id,
      dob,
    });
    const payload = {
      cust_email: email,
      first_name,
      last_name,
      password,
      cust_mobile: phone_no,
      country_id,
      dob,
    };
    if (data.status) {
      dispatch({
        type: REGISTER_SUCCESS,
        payload,
      });
      payload.cust_id = data.cust_id;
      localStorage.setItem("user", JSON.stringify(payload));
      // toast.dismiss();
      // toast.success(`Registration successful.`);
    } else {
      dispatch({ type: REGISTER_ERROR, payload: data.message });
      // toast.dismiss();
      // toast.error(data.message);
    }
    callback(false);
    return data?.status
  } catch (error) {
    dispatch({ type: REGISTER_ERROR, payload: error });
  }
};

export const guestRegister = ({
  guest_email,
  guest_first_name,
  guest_last_name,
  guest_mobile,
  guest_country_code,
  callback,
  dob = undefined,
  preferred_lang_id,
  isUpdateLang,
}) => async (dispatch, getStore) => {
  try {
    let state = getStore()
    dispatch({ type: GUEST_REGISTER });

    let payload = {
      guest_email,
      guest_first_name: guest_first_name ?? '',
      guest_last_name,
      guest_mobile,
      guest_country_code,
      guest_user_id: localStorage.getItem(`guest_user_id`),
      isUpdateLang: !!isUpdateLang,
    }

    // if update, use updated preferred_lang_id
    if (!!preferred_lang_id)
      payload.preferred_lang_id = preferred_lang_id

    // if not, use mycinemas selected_lang_id
    if (state?.movies?.selected_language?.lang_id)
      payload.selected_lang_id = state?.movies?.selected_language?.lang_id

    const { data } = await AuthService.GuestRegister(payload);

    if (data.status) {
      // if (!window.location.pathname.includes('/payment'))
      dispatch({
        type: GUEST_REGISTER_SUCCESS,
        payload: data?.data?.length ? data.data[0] : [],
      });
      if (data?.data?.length) {
        localStorage.setItem("guest_user", JSON.stringify(data.data[0]));

        // set lang to user specific
        let globalOptions = state?.movies?.globalOptions ?? []
        const kioskLang = globalOptions.find((go) => go.go_key === "KIOSK_LANGUAGE");
        const availLangs = JSON.parse(kioskLang?.go_value || []);
        let [selectedLang] = availLangs.filter((l) => data.data[0]?.preferred_lang_id == l?.lang_id);

        console.log({
          'preferred_lang_id': data.data[0]?.preferred_lang_id,
          kioskLang,
          availLangs,
          selectedLang
        });

        if (data.data[0]?.preferred_lang_id &&
          availLangs.length > 0 &&
          selectedLang
        ) {
          dispatch(setSelectedLanguage(selectedLang));

          //setting lang for moment
          let langMoment = selectedLang.iso_2
            ? selectedLang.iso_2.toLowerCase()
            : getLangISOCode(selectedLang.lang_name);
          i18n.changeLanguage(langMoment);
          moment.locale(langMoment.toLowerCase());
        }
      }
      // toast.dismiss();
      // toast.success(`Registration successful.`);
    } else {
      localStorage.removeItem("guest_user");
      dispatch({ type: REGISTER_ERROR, payload: data.message });
      // toast.dismiss();
      // toast.error(data.message);
    }
    callback(false);
    return data?.status
  } catch (error) {
    dispatch({ type: REGISTER_ERROR, payload: error?.message ? error.message : error });
  }
};

export const fetchUserHistory = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_USER_HISTORY });
    const { data } = await AuthService.GetUserHistory(payload);
    if (data.status && data.Records.length > 0) {
      let result = _.chain(data?.Records)
        .groupBy("show_booking_id")
        .toPairs()
        .map((currentItem) =>
          _.fromPairs(_.zip(["show_booking_id", "Seats"], currentItem))
        )
        .value();
      result = result.sort((a, b) => b.show_booking_id - a.show_booking_id);

      result.forEach((history) => {
        history.seatsString = [];
        let seat_names_set = new Set(
          history?.Seats?.[0].seat_names?.split(",")
        );
        history.seatsString = [...seat_names_set];
        // history.Seats.map((seat) => history.seatsString.push(seat.seat_name));
        history.seatsString.sort(sortSeatsString);
      });


      let unpaidResult = _.chain(data?.reservedUnpaidBookings ?? [])
        .groupBy("reservation_id")
        .toPairs()
        .map((currentItem) =>
          _.fromPairs(_.zip(["reservation_id", "Seats"], currentItem))
        )
        .value();

      unpaidResult.forEach((history) => {
        history.reservation_date_time_fallback =
            history?.Seats?.[0]?.reservation_date_time_fallback ??
            history?.Seats?.[0]?.reservation_date_time ??
            history?.Seats?.[0]?.ss_start_date

        history.seatsString = [];
        let seat_names_set = new Set(history?.Seats?.[0].seat_names);
        history.seatsString = [...seat_names_set];
        // history.Seats.map((seat) => history.seatsString.push(seat.seat_name));
        history.seatsString.sort(sortSeatsString);
      });

      unpaidResult = unpaidResult.sort(
          (a, b) => {
            return new Date(b.reservation_date_time_fallback).getTime() - new Date(a.reservation_date_time_fallback).getTime()
          }
      );

      dispatch({
        type: FETCH_USER_HISTORY_SUCCESS,
        payload: result,
      });
      dispatch({
        type: FETCH_UNPAID_USER_HISTORY_SUCCESS,
        payload: unpaidResult,
      });
    } else {
      dispatch({ type: FETCH_USER_HISTORY_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: FETCH_USER_HISTORY_ERROR, payload: error.message });
  }
};
export const fetchGuestHistory = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_USER_HISTORY });
    const { data } = await AuthService.GetGuestHistory(payload);
    if (data.status && data.Records.length > 0) {
      let result = _.chain(data?.Records)
        .groupBy("show_booking_id")
        .toPairs()
        .map((currentItem) =>
          _.fromPairs(_.zip(["show_booking_id", "Seats"], currentItem))
        )
        .value();
      result = result.sort((a, b) => b.show_booking_id - a.show_booking_id);

      result.forEach((history) => {
        history.seatsString = [];
        let seat_names_set = new Set(
          history?.Seats?.[0].seat_names?.split(",")
        );
        history.seatsString = [...seat_names_set];
        // history.Seats.map((seat) => history.seatsString.push(seat.seat_name));
        history.seatsString.sort(sortSeatsString);
      });


      let unpaidResult = _.chain(data?.reservedUnpaidBookings ?? [])
        .groupBy("reservation_id")
        .toPairs()
        .map((currentItem) =>
          _.fromPairs(_.zip(["reservation_id", "Seats"], currentItem))
        )
        .value();

      unpaidResult.forEach((history) => {
        history.seatsString = [];
        let seat_names_set = new Set(history?.Seats?.[0].seat_names);
        history.seatsString = [...seat_names_set];
        // history.Seats.map((seat) => history.seatsString.push(seat.seat_name));
        history.seatsString.sort(sortSeatsString);
      });

      dispatch({
        type: FETCH_USER_HISTORY_SUCCESS,
        payload: result,
      });
      dispatch({
        type: FETCH_UNPAID_USER_HISTORY_SUCCESS,
        payload: unpaidResult,
      });
    } else {
      dispatch({ type: FETCH_USER_HISTORY_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: FETCH_USER_HISTORY_ERROR, payload: error.message });
  }
};

export const sortSeatsString = (a, b) => {
  let reA = /[^a-zA-Z]/g;
  let reN = /[^0-9]/g;
  let aA = a.replace(reA, "");
  let bA = b.replace(reA, "");
  if (aA === bA) {
    let aN = parseInt(a.replace(reN, ""), 10);
    let bN = parseInt(b.replace(reN, ""), 10);
    return aN === bN ? 0 : aN > bN ? 1 : -1;
  } else {
    return aA > bA ? 1 : -1;
  }
};

export const logout = ({ history }) => async (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
  localStorage.removeItem("user");
  // toast.dismiss();
  // toast.success(`Logout successful`);
  history.push(`/`);
};
export const guestLogout = ({ history }) => async (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
  localStorage.removeItem("guest_user");
  // toast.dismiss();
  // toast.success(`Logout successful`);
  history.push(`/`);
};

export const updateUser = ({
  first_name,
  last_name,
  email,
  phone_no,
  callback,
}) => async (dispatch, getStore) => {
  try {
    let store = getStore()

    const { data } = await AuthService.UpdateUser({
      first_name,
      last_name,
      email,
      phone_no,
    });
    if (data && data.status) {
      dispatch({ type: UPDATE_USER_SUCCESS, payload: {
        ...store?.auth?.loggedInUser,
        ...data.Records[0]
      }});
      localStorage.removeItem("user");
      localStorage.setItem("user", JSON.stringify({
        ...store?.auth?.loggedInUser,
        ...data.Records[0]
      }));
      callback(false);
      // toast.dismiss();
      toast.success(`Your Profile has been updated`);
    }
  } catch (error) {
    console.error(error);
  }
};

export const updateUserLang = ({ lang_id }, isUpdateLang = true) => async (dispatch, getStore) => {
  try {
    let store = getStore()
    let email =  store?.auth?.loggedInUser?.cust_email
    let guest_id =  store?.auth?.guestUser?.guest_id
    let guestUser =  store?.auth?.guestUser

    if (email){
      const {data} = await AuthService.UpdateUserLang({
        preferred_lang_id: lang_id,
        email,
      });
      if (data && data.status) {
        // if (!window.location.pathname.includes('/payment'))
        dispatch({type: UPDATE_USER_SUCCESS, payload: {
          ...store?.auth?.loggedInUser,
          ...data.Records[0]
        }});
        localStorage.removeItem("user");
        localStorage.removeItem("guest_user");
        localStorage.setItem("user", JSON.stringify({
          ...store?.auth?.loggedInUser,
          ...data.Records[0]
        }));
        // callback(false);
        // toast.dismiss();
        // toast.success(`Your Profile has been updated`);
      }
    }
    if (guest_id && guestUser) {
      console.log({lang_id, guest_id, guestUser})
      let payload = {
        ...guestUser,
        preferred_lang_id: lang_id,
        isUpdateLang,
        callback: () => {},
      }

      dispatch(guestRegister(payload)).catch(console.error);
    }
  } catch (error) {
    console.error(error);
  }
};

export const forgotPassword = ({ field, callback, successCallback }) => async (
  dispatch
) => {
  try {
    const payload = {};
    console.log(emailRegex(field));
    if (emailRegex(field)) {
      payload.email = field;
    } else {
      payload.mobile = field;
    }
    const { data } = await AuthService.ForgotPassword(payload);
    if (data && data.status) {
      dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: data.Records[0] });
      successCallback();
    } else {
      dispatch({ type: FORGOT_PASSWORD_ERROR, payload: data.message });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: FORGOT_PASSWORD_ERROR });
  } finally {
    callback(false);
  }
};

export const verifyOTP = ({
  otp,
  callback,
  successCallback,
  forLogin,
}) => async (dispatch, getState) => {
  try {
    const {
      auth: {
        forgotPasswordUserDetails: { cust_id },
        loginOtpSuccess,
      },
    } = getState();

    const { data } = await AuthService.VerifyOTP({ cust_id, otp });
    if (data && data.status) {
      dispatch({ type: OTP_SUCCESS, payload: { otp } });
      successCallback();
    } else {
      dispatch({ type: OTP_ERROR, payload: data.message });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: OTP_ERROR });
  } finally {
    callback(false);
  }
};

export const verifyLoginOTP = ({ otp, callback, successCallback }) => async (
  dispatch,
  getState
) => {
  try {
    const {
      auth: {
        loginOtpSuccess: { cust_id },
      },
    } = getState();

    const { data } = await AuthService.VerifyOTP({ cust_id, otp });
    if (data && data.status) {
      dispatch({ type: OTP_SUCCESS, payload: { otp } });

      dispatch({
        type: LOGIN_SUCCESS,
        payload: data.user,
      });
      localStorage.setItem("user", JSON.stringify(data.user));
      successCallback();
    } else {
      dispatch({ type: OTP_ERROR, payload: data.message });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: OTP_ERROR });
  } finally {
    callback(false);
  }
};

export const resetPassword = ({
  newPassword,
  callback,
  successCallback,
}) => async (dispatch, getState) => {
  try {
    const {
      auth: {
        forgotPasswordUserDetails: { cust_id, cust_email, first_name },
        otpSuccess: { otp },
      },
    } = getState();
    const { data } = await AuthService.ResetPassword({
      password: newPassword,
      otp,
      cust_id,
      cust_email,
      first_name,
    });
    if (data && data.status) {
      dispatch({ type: RESET_PASSWORD_SUCCESS, payload: {} });
      successCallback();
    } else {
      dispatch({ type: RESET_PASSWORD_SUCCESS_ERROR, payload: data.message });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: RESET_PASSWORD_SUCCESS_ERROR });
  } finally {
    callback(false);
  }
};

export const emailRegex = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const numberRegex = (number) => {
  const re = /^\d+$/;
  return re.test(String(number).toLowerCase());
};

export const loginViaOtp = ({
  loginField,
  callback,
  successCallback,
}) => async (dispatch, getState) => {
  try {
    if (!!loginField)
      dispatch({ type: LOGIN_VIA_OTP_SUCCESS_LOGIN_FIELD, payload: loginField })

    const payload = {};
    console.log(emailRegex(loginField));
    if (emailRegex(loginField)) {
      payload.email = loginField;
    } else {
      payload.mobile = loginField;
    }
    const { data } = await AuthService.LoginViaOtp(payload);
    if (data && data.status) {
      dispatch({ type: LOGIN_VIA_OTP_SUCCESS, payload: data.Records[0] });
      successCallback();
      return data;
    } else {
      dispatch({ type: LOGIN_VIA_OTP_ERROR, payload: data.message });
      return data;
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: LOGIN_VIA_OTP_ERROR });
  } finally {
    callback(false);
  }
};

let timerInterval = null;
export const startTimer = () => (dispatch) => {
  timerInterval = setInterval(
    () =>
      dispatch({
        type: START_OTP_TIMER,
        payload: {
          timerInterval,
        },
      }),
    1 * 1000
  );
};
export const clearOTP = () => (dispatch) => {
  dispatch({
    type: OTP_CLEAR,
  })
};

export const stopTimer = () => (dispatch) => {
  if (timerInterval) {
    clearInterval(timerInterval);
  }
  dispatch({
    type: STOP_OTP_TIMER,
  });
};

export const resetTimer = () => (dispatch) => {
  if (timerInterval) {
    clearInterval(timerInterval);
  }
  dispatch({ type: RESET_OTP_TIMER });
};

//Contact Us Form --> Send Email
export const sendemail = ({ name, email, contact_no, message, customer_id, cinema_id, stc_id }) => async (
  dispatch
) => {
  try {
    const { data } = await AuthService.ContactusMail({
      name,
      email,
      contact_no,
      message,
      customer_id,
      cinema_id,
      stc_id,
    });
    if ((data, data.status)) {
      console.log(data);
      dispatch({ type: CONTACTUSMAIL, payload: data });
    }
  } catch (err) {
    console.log(err);
  }
};

export const setShowLoginPopup = (toShow) => ({
  type: SHOW_LOGIN_POPUP,
  payload: toShow,
});
