import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";

// Components
import CenteredTitle from "@components/partials/CenteredTitle";
import Slider from "react-slick";
import AboutUsCTA from "./AboutUsCTA";
import Gallery from "./AboutUsGallery";
import BreadCrumb from "@components/partials/BreadCrumbs";
// Images
import seatsImg from "@assets/pictures/about-us.png";
import { ReactComponent as MapIcon } from "@assets/pictures/svgs/map-new.svg";
import { fetchAudiSeatLayout, fetchAuditoriums } from "../../../store/ancilliary/ancilliaryActions";

import { useSelector, useDispatch } from "react-redux";
import Spinner from "../../partials/Spinner";
import { useHistory, Link } from "react-router-dom";
import SeatLayoutPopup from "@components/partials/popups/SeatLayoutPopup";

const AuditoriumPlan = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, classes } = props;
  const [swiping, setSwiping] = useState(false);
  const [showGallery, setShowGallery] = useState(false);
  const [selectedScreen, setSelectedScreen] = useState(null);

  const handleSwipe = () => {
    setSwiping(true);
  };

  const handleClick = () => {
    if (swiping) {
      setSwiping(false);
    } else {
      // Click actions here
    }
  };

  // Slider Settings
  const heroSlider = {
    pauseOnFocus: true,
    onSwipe: handleSwipe,
    arrows: true,
    dots: false,
    infinite: true,
    useTransform: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const auditoriums = useSelector((state) => state.ancilliary.auditoriums);
  const selectedCinema = useSelector((state) => state.movies.selectedCinema);
  const [auditoriumsLoading, setAuditoriumsLoading] = useState(true);

  useEffect(() => {
    if (selectedCinema?.cinema_id)
      dispatch(fetchAuditoriums({ cinema_id: selectedCinema.cinema_id }))
        .finally(() => {
          setAuditoriumsLoading(false);
        });
  }, [selectedCinema]);

  const openAudiGallery = (audi) => {
    setSelectedScreen(audi);
    setShowGallery(true);
  };

  const [loader, setLoader] = useState(false);
  const [popUpSeatLayout, setPopupSetSeatLayout] = useState(false);
  const onClickAudiCard = (screen_id) => {
    if (screen_id) {
      setLoader(true);
      dispatch(
        fetchAudiSeatLayout({screen_id: screen_id, callback: setLoader})
      ).then(() => {
        // setPopupSetSeatLayout(true)
      })
      .finally(() => {
        // setLoader(false)
      });
    }
  };

  if (auditoriumsLoading)
    return (<div className="row">
      <div className="col-12 text-center">
        <Spinner />
      </div>
    </div>);

  return (<>
    <SeatLayoutPopup
      show={popUpSeatLayout}
      closeFunc={setPopupSetSeatLayout}
      loader={loader}
    />
    <Gallery show={showGallery} onClose={() => setShowGallery(false)} selectedScreen={selectedScreen} />
    <section className="container-fluid about_us_auditorium_plan_wrapper align_new">
    <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t("Auditorium Plan")}/>
      <CenteredTitle firstHalf={t("Auditorium Plan")} secondHalf="" />
      <div className="row" style={{justifyContent:"center"}}>
        {auditoriums.length > 0 && auditoriums.map((audi, index) => (
          <section className={`col-md-4 col-xl-3 mb-4`} key={index}>
            <article className="about_us_auditorium_plan">
              <figure>
                <img src={audi?.screen_image_url || auditoriums[0].screen_image_url} alt="" />
              </figure>

              <div className="">
                <h6><span>{audi.screen_name}</span><span>&nbsp;|&nbsp;{audi.screen_no_of_seats} {t("Seats")}</span></h6>
                <p>
                 {/*6 wheelchair spaces, */}
                </p>
                <p>
                {/*5 boxes of 2 seats each*/}
                </p>
                <AboutUsCTA text={t("View Plan")} onClick={() => {
                  // openAudiGallery(audi);
                  if (audi?.screen_id){
                    setPopupSetSeatLayout(true)
                    onClickAudiCard(audi?.screen_id)
                  }
                }} />
              </div>
            </article>

            {/*<p className="">
              <Link
                to={{ pathname: "https://www.google.ch/maps/place/Kino+St%C3%BCssihof/@47.372348,8.543828,17z/data=!3m1!4b1!4m2!3m1!1s0x479aa0a9eb55d7a3:0xa9667931be870f79" }}
                target="_blank"
              >
                <MapIcon />
                <span className="px-1">{t("Get Directions")}</span>
              </Link>
              </p>*/}

          </section>
        ))}
      </div>
    </section>
  </>);
};

export default withNamespaces()(AuditoriumPlan);
