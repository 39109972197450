export const API_VERSION = "v1.0";
const HOST_URL = `mycinema-dev-cinemaakil.brij.tech`;
const LIVEBASEURL = `https://api-dev-cinemaakil.brij.tech`;
const DEVBASEURL = `http://localhost:3800`;
// const DEVBASEURL = `https://cinematic-server-gdc-3.brij.tech`;
// const DEVBASEURL = `http://shringar.brij.tech:3950`;
export const BASEURL =
  window.location.host === HOST_URL ? LIVEBASEURL : DEVBASEURL;
export const ARENA_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjYwN2VkY2MwLTNjODQtNGRhZC1hY2VmLTBjN2FhYjNmMzU1MyIsImlhdCI6MTYwNjg5MzA0MCwiZXhwIjoxNjA5NDg1MDQwfQ.3dkWIqaDYXjJ0Eayq_nL8Q0rbmqfYcyI3NLZbcbKh8Y`;
export const BING_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjIwNTE5ZGM2LTNkODktNDViMi04OTM5LWM2Yjg4YmM2NmExNSIsImlhdCI6MTYxMjQzNzQzOSwiZXhwIjoxNjE1MDI5NDM5fQ.jMjwQUqQopck_eOQga-zTig3DDhuhnRLMpgSRo2_5s4`;
//export const CINEMAAKIL_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6ImY2MDkxZWJhLTA0ZDAtNGM2Yi1iNTkyLTdmMzU0MzI5NjZmYyIsImlhdCI6MTYzOTA1NTAwNSwiZXhwIjoxNjQxNjQ3MDA1fQ.-ZdScJR_F0tJka_PUckt5Rejad3hPHwBxqdh10V18HM`;
export const CINEMAAKIL_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjIyMDM1ODY3LTQ4MWMtNGQ1NC1hODdhLTQ4NDk3MGVjZjFjOSIsImlhdCI6MTYzOTA1NTg5NywiZXhwIjoxNjQxNjQ3ODk3fQ.u2gdOzJ8Xli-AYczgLX_7Xwg74Gdxl3wI6g9KNievlo`;
export const TOKEN = CINEMAAKIL_TOKEN;
