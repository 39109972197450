import { createSelector } from "reselect";
import cloneDeep from "lodash/cloneDeep";

const selectedFnbItemsSelector = (state) => state.fnb.selected_fnb_items;
const fnbItemsInStore = (state) => state.fnb.fnb_items;

const fnbItemsWithSearch = (state, search, selectedCategory) => [
  state.fnb.fnb_items,
  search,
  selectedCategory,
];

export const fnbItemsWithSearchSelector = createSelector(
  fnbItemsWithSearch,
  ([fnbItems, search, selectedCategory]) => {
    if (!fnbItems) return fnbItems
    if (!search && !selectedCategory) return fnbItems

    return fnbItems.filter((item) => {
      let nameMatches = true;
      let categoryMatches = true;

      if (search)
        nameMatches = item?.item_name?.toLowerCase().includes(search?.toLowerCase());

      if (selectedCategory)
        categoryMatches = item?.fc_category === selectedCategory?.fc_category;

      return !!nameMatches && !!categoryMatches;
    });
  }
);

export const fnbItemCategoriesSelector = createSelector(
  fnbItemsInStore,
  (fnbItems) => {
    let categories = new Set();

    if (fnbItems && fnbItems.length > 0) {
      for (let fnbItem of fnbItems) {
        if (fnbItem.fc_category) {
          categories.add(fnbItem.fc_category);
        }
      }
    }

    return Array.from(categories.keys());
  }
);
