import React, { useEffect, useState, useRef, useContext } from "react";
import QRCode from "qrcode.react";
import { withNamespaces } from "react-i18next";
import { Link } from "react-router-dom";
import moment from "moment";
import poster2 from "@assets/pictures/poster-2.png";
import gogoLogo from "@assets/pictures/svgs/gogo-logo.svg";
import eyeGreen from "@assets/pictures/svgs/eye-green.svg";
import barCode from "@assets/pictures/svgs/bar-code.svg";
import greyBorder from "@assets/pictures/svgs/grey-border.svg";
import poster from "@assets/pictures/poster.png";
import logo from "@assets/pictures/svgs/private-final-ticket/logo.svg";
import logoBlack from "@assets/pictures/svgs/private-final-ticket/logo-black.svg";
import mail from "@assets/pictures/svgs/private-final-ticket/mail.svg";
import mobile from "@assets/pictures/svgs/private-final-ticket/mobile.svg";
import qr from "@assets/pictures/svgs/private-final-ticket/qr.svg";
import share from "@assets/pictures/svgs/private-final-ticket/share.svg";
import star from "@assets/pictures/svgs/private-final-ticket/star.svg";
import ticket from "@assets/pictures/svgs/private-final-ticket/ticket.svg";
import close from "@assets/pictures/svgs/close.svg";
import { ReactComponent as ArrowBack } from "@assets/pictures/svgs/arrow-back.svg";
import { ReactComponent as ArrowBackActive } from "@assets/pictures/svgs/arrow-back-active.svg";
import useIsMobile from "../customHooks/useIsMobile";

import {
  clearState,
  fetchBookingDetails,
  fetchBookingDetailsBrij,
  sendEmail,
  sendSms,
} from "../store/movies/movieActions";
import {
  seatTypesSelector,
  compactSeatTypes,
} from "../store/movies/movieSelector";
import ShareTicketPopup from "./partials/popups/ShareTicketPopup.jsx";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { HOST_URL, BASEURL } from "../config/index";
import { CLEAR_VOUCHERS_DATA } from "../store/promotions/promotionsTypes";
import Spinner from "./partials/Spinner";
import {
  Row,
  Container,
  Col,
  Dropdown,
  Modal,
  Alert,
  // Spinner,
} from "react-bootstrap";
import { currencyFormatter } from "../helper/currencyFormatter";
import dummyPosterStatic from "@assets/pictures/dummyPoster.png";
import { getLangSpecificAttribute } from "@helper/languages";

const FinalTicket = ({ t }) => {
  const isMobile = useIsMobile();
  // useEffect(() => dispatch(clearState()), []);
  const [modalShow, setModalShow] = useState(false);
  const [actionsModal, setActionsModal] = useState(false);
  const [back, setBack] = useState(false);
  let getLanguageSpecificMovieDetails = useSelector(
    (state) => state.movies.getLanguageSpecificMovieDetails
  );
  const movieDetails = useSelector((state) => state.movies.movieDetails);
  const dispatch = useDispatch();
  const history = useHistory();
  const showBookingID = useSelector((state) => state.movies.showBookingID);
  const { show_booking_id, category_id } = useParams();
  const bookingDetails = useSelector((state) => state.movies.bookingDetails);
  let private_rate_card_detail = bookingDetails?.private_rate_card_detail ?? [];

  const bookingDetailsLoading = useSelector(
    (state) => state.movies.bookingDetailsLoading
  );
  const organization = useSelector((state) => state.movies.organizationDetails);
  const {
    org_logo_url,
    org_mycinema_logo,
    org_name,
    dummy_poster: dummyPosterDynamic,
  } = organization;

  const dummyPoster = window.localStorage.getItem("placeholder_image")
    ? window.localStorage.getItem("placeholder_image")
    : dummyPosterStatic;

  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const [sendSmsLoading, setSendSmsLoading] = useState(false);

  const currency = useSelector((state) => state.movies.currency);
  const [loading, setLoading] = useState({
    email: false,
    sms: false,
  });
  const { curr_code, curr_id } = currency;
  useEffect(() => {
    if (show_booking_id) {
      dispatch({ type: CLEAR_VOUCHERS_DATA });
      category_id == 1
        ? dispatch(fetchBookingDetails({ sb_id: show_booking_id }))
        : dispatch(fetchBookingDetailsBrij({ sb_id: show_booking_id }));
    } else {
      history.push(`/`);
    }
  }, [dispatch, history]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onClickEmailTicket = () => {
    setSendEmailLoading(true);
    dispatch(sendEmail({ sb_booking_code: show_booking_id })).finally(() => {
      setSendEmailLoading(false);
    });
  };

  const onClickSmsTicket = () => {
    setSendSmsLoading(true);
    dispatch(sendSms({ sb_booking_code: show_booking_id })).finally(() => {
      setSendSmsLoading(false);
    });
  };

  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );

  const { lang_name, iso_2, lang_id } = selected_language;

  const seatTypes = useSelector((state) =>
    compactSeatTypes(state, "final_ticket", iso_2)
  );

  const booking_type = useSelector((state) => state.movies.booking_type);

  const loginDetails = useSelector((state) => state.auth);
  const { loginError, loggedInUser, guestUser, userHistory } = loginDetails;
  return (
    <section className="">
      <div className="container-fluid header-space ticket-page">
        {false && !isMobile && (
          <section className="row for-prev-step mx-0">
            <button
              className="btn-main my-3 my-xl-4"
              onClick={() => history.go(-1)}
              onMouseOver={() => setBack(true)}
              onMouseOut={() => setBack(false)}
            >
              {/* <img
                src={back ? arrowBackActive : arrowBack}
                className="mr-3"
                alt=""
              /> */}
              {back ? <ArrowBackActive /> : <ArrowBack />}
              Back
            </button>
          </section>
        )}{" "}
        {bookingDetails?.booking_type_id !== 3 && (
          <>
            <div className="position-relative overflow-hidden">
              <div className="final-ticket">
                <div className="ticket-header">
                  <div>
                    <Link to>
                      <img
                        src={org_mycinema_logo || org_logo_url}
                        alt={org_name}
                        height="28px"
                        // className="img-fluid"
                      />
                    </Link>
                  </div>
                  <div>
                    <h6 className="mb-0">{t("Your Ticket")}</h6>
                  </div>
                </div>
                {bookingDetailsLoading ? (
                  <div className="row my-5">
                    <div className="col-12 text-center">
                      <Spinner />
                    </div>
                  </div>
                ) : (
                  <div className="ticket-content movie_info_wrapper payment_details_new">
                    <div className="row top">
                      <div className="col-md-9">
                        <section className="row align-items-center">
                          {/* col-4 col-md-3 */}
                          <div className="col-12 d-flex align-items-center">
                            <img
                              src={
                                getLangSpecificAttribute(
                                  bookingDetails?.languageSpecificImages,
                                  lang_id,
                                  "artwork"
                                ) ||
                                movieDetails?.md_thumbnail_url ||
                                bookingDetails?.md_thumbnail_url ||
                                dummyPoster
                              }
                              alt="Movie Img"
                              className="movie-img img-fluid mr-3"
                            />
                            {/* </div>
                          <div className="col-8 col-md-9"> */}
                            <article className="">
                              <h1 className="movie_info_title mb-3">
                                {getLangSpecificAttribute(
                                  bookingDetails?.languageSpecificImages,
                                  lang_id,
                                  "mc_title"
                                ) || bookingDetails?.movie_title}
                                </h1>
                                <div className="movie_info_rating_n_format justify-content-start mb-3">
                                  <span className="movie_info_rating">
                                    {bookingDetails?.rating}
                                  </span>
                                  {(<>
                                      <span className="movie_info_language">
                                        {(!!t(bookingDetails?.lang_name) ? t(bookingDetails?.lang_name) : bookingDetails?.lang_name)
                                        || bookingDetails?.version_name}{`${
                                          bookingDetails?.sub_1_iso_2 ? `/${bookingDetails?.sub_1_iso_2}` : ''
                                      }${
                                          bookingDetails?.sub_1_iso_2 && bookingDetails?.sub_2_iso_2 ? `-${bookingDetails?.sub_2_iso_2}` : ''
                                      }`}
                                        </span>
                                      <span className="movie_info_language">
                                          {bookingDetails?.mf_name}
                                        </span>
                                    </>)
                                  }
                                </div>
                                <div className=" text-left mb-3">
                                  <h6 className="">{t("Genre")}</h6>
                                  <p/>
         {!!(bookingDetails?.genres_with_translations
                                        ?.filter(
                                            (genre) => genre[iso_2?.toLowerCase()]
                                        )
                                        ?.map(
                                            (genre) => genre[iso_2?.toLowerCase()]
                                        )
                                        .splice(0, 13)
                                        .join(", "))
                                    &&
                                    <p>
            { (<>
                                          {bookingDetails?.genres_with_translations
                                              ?.filter(
                                                  (genre) => genre[iso_2?.toLowerCase()]
                                              )
                                              ?.map(
                                                  (genre) => genre[iso_2?.toLowerCase()]
                                              )
                                              .splice(0, 13)
                                              .join(", ") || bookingDetails?.g_name}
                                        </>)}
          </p>}
                                </div>
                                 <div className="text-left mb-0">
                                  <h6 className="">
                                  {t("Cinema")}
                                  </h6>
                                  <p/>
                                <p className="">
                                  {bookingDetails?.cine_name},{" "}
                                  {bookingDetails?.cine_address}
                                  {/* {bookingDetails?.cine_location} */}
                                </p>
                                </div>
                              {/* <div className="features">
                                <p>
                                  {
                                    bookingDetails?.is_version_apply
                                        ? <>{bookingDetails.version_name}{" "}
                                          {bookingDetails?.mf_name}</>
                                        : <>{bookingDetails?.lang_name}{" "}
                                          {bookingDetails?.mf_name}</>
                                  }
                                </p>
                                <p>
                                  {
                                    !!(bookingDetails?.genres_with_translations
                                        ?.filter(
                                            (genre) => genre[iso_2?.toLowerCase()]
                                        )
                                        ?.map(
                                            (genre) => genre[iso_2?.toLowerCase()]
                                        )
                                        .splice(0, 13)
                                        .join(", "))
                                      ? (<>
                                          {bookingDetails?.genres_with_translations
                                              ?.filter(
                                                  (genre) => genre[iso_2?.toLowerCase()]
                                              )
                                              ?.map(
                                                  (genre) => genre[iso_2?.toLowerCase()]
                                              )
                                              .splice(0, 13)
                                              .join(", ") || bookingDetails?.g_name}{" "}
                                          | {bookingDetails?.rating}
                                        </>)
                                      : (<>
                                          {bookingDetails?.rating}
                                        </>)
                                  }
                                </p>
                              </div>
                              <div className="cinema-name">
                                <p className="fs-16 d-none d-md-block">
                                  {t("Cinema")}:
                                </p>
                                <p className="font-weight-bold">
                                  {bookingDetails?.cine_name},{" "}
                                  {bookingDetails?.cine_address}
                                   {bookingDetails?.cine_location}
                                </p>
                              </div> */}
                            </article>
                          </div>
                        </section>
                      </div>
                      <div className="col-md-3 right mt-3 mt-md-0">
                        {/* <img
                      src={barCode}
                      alt="bar-code"
                      height="39px"
                      className="img-fluid"
                    /> */}
                        {bookingDetails?.sb_booking_code && (
                          <QRCode
                            value={`${BASEURL}/your-ticket/${bookingDetails?.sb_booking_code}`}
                            className="mx-auto ml-md-auto mr-md-0 qr-border"
                          />
                        )}
                        <p className="text-center text-md-right">
                          {/* {t("Scan to get a ticket on your mobile")} */}
                          <span>{t("Use QR code for all tickets")}</span>
                        </p>
                      </div>
                    </div>
                    <div className="grey-dashed-border py-4">
                      <img
                        src={greyBorder}
                        alt="border"
                        className="img-fluid"
                      />
                    </div>
                    <div className="row bottom align-items-center">
                      <div className="col-md-12 col-lg-9 col-xl-9 left">
                        <div className="grid-col-1 mb-4 mb-md-0">
                          {bookingDetails?.booking_type_id != 3 && (
                            <div>
                              <div className="heading">
                                {t("No. of Tickets")}
                              </div>
                              <div className="content">
                                {bookingDetails?.seats.length}
                              </div>
                            </div>
                          )}
                          <div>
                            <div className="heading">
                              {t("Seats Selected")}:
                            </div>
                            <div className="content comma-separated">
                              {bookingDetails?.booking_type_id != 3 &&
                                bookingDetails?.seats?.map((seat, index) => (
                                  <>
                                    <span>{seat}</span>
                                  </>
                                ))}
                              {bookingDetails?.booking_type_id == 3 && (
                                <p>{t("entire-hall-booked")}</p>
                              )}
                            </div>
                          </div>
                          <div>
                            <div className="heading">{t("Auditorium")}</div>
                            <div className="content">
                              {bookingDetails?.screen_name}
                            </div>
                          </div>
                          {/* <div>
                            <p>{t("Seat Type")}</p>
                            <p>Premium, Multiple</p>
                          </div> */}
                        </div>
                        <div>
                          <div className="grid-col-5">
                            <div className="ticket-type-col">
                              <div className="text heading">
                                {t("Ticket Type")}
                              </div>
                              {bookingDetails?.booking_type_id !== 3 && (
                                <div>
                                  {seatTypes?.length > 0 &&
                                    seatTypes.map((seatType, seatTypeIndex) => (
                                      <div
                                        className="content"
                                        key={seatTypeIndex}
                                      >
                                        <div>
                                          <span className="">
                                            {seatType.ticket_type}&nbsp;
                                            {!isMobile && "-"}&nbsp;
                                          </span>
                                          {seatType.seat_types.map(
                                            (item, index) => (
                                              <span
                                                className={`${
                                                  isMobile ? "d-block" : ""
                                                }`}
                                              >
                                                {item}
                                                {seatType.seat_types.length -
                                                  1 !==
                                                  index && ", "}
                                              </span>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              )}
                            </div>
                            {bookingDetails?.fnb_items?.length > 0 && (
                              <div className="ticket-type-col">
                                <div className="heading">
                                  {t("Concessions")}
                                </div>

                                <div className="comma-separated content">
                                  {bookingDetails.fnb_items.map((item) => (
                                    <span>
                                      {item?.fst_quantity}&nbsp;
                                      {getLangSpecificAttribute(
                                        item?.FnbContent,
                                        lang_id,
                                        "item_lang_name"
                                      ) || item?.item_name}
                                    </span>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="grid-col-2 mt-n2">
                          {bookingDetails?.screening_name && (
                            <div>
                              <div className="heading">
                                {t("Screening Type")}:
                              </div>
                              <div className="content">
                                {" "}
                                {/* {booking_type === 1
                          ? t("Confirmed Screening")
                          : booking_type === 2
                          ? t("Crowdsourced Screening")
                          : booking_type === 3
                          ? t("Private Screening")
                          : t("Regular")} */}
                                {t(bookingDetails?.screening_name)}
                              </div>
                            </div>
                          )}

                          <div>
                            <div className="heading">
                              {t("Show Date & Time")}:
                            </div>
                            <div className="content">
                              {" "}
                              {moment(bookingDetails?.show_date).format(
                                "dddd"
                              )}, {moment(bookingDetails?.show_date).format(
                                "DD MMM YYYY"
                              )} |{" "}
                              {moment(
                                bookingDetails?.show_time,
                                "HH:mm:ss"
                              ).format("HH:mm")}
                            </div>
                          </div>
                          <div>
                            <div className="heading">
                              {t("Total Amount Paid")}
                            </div>
                            <div className="content">
                              {curr_code}{" "}
                              {currencyFormatter(
                                parseFloat(
                                  bookingDetails?.sb_total_amount +
                                    (bookingDetails?.internet_handling_fee ||
                                      0) +
                                    (bookingDetails?.ft_total_amount || 0) -
                                    bookingDetails?.offer_price
                                )
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="grid-col-2">
                          {/* <div>
                            <div className="heading">
                              {t("Booking")} {t("Date")} & {t("Time")}:
                            </div>
                            <div className="content">
                              {" "}
                              {moment(bookingDetails?.show_date).format("ll")}
                              {" "}
                              |
                              {" "}
                              {moment(bookingDetails?.show_time,"HH:mm:ss").format("HH:mm")}
                            </div>
                          </div> */}
                          <div>
                            <div className="heading">
                              {t("Payment Method")}:
                            </div>
                            <div className="content">
                              {bookingDetails?.stripeDetails?.card
                                ? "Stripe"
                                : bookingDetails?.pm_payment_mode
                                ? bookingDetails?.pm_payment_mode
                                : "Debit Card"}
                            </div>
                          </div>
                          <div>
                            <div className="heading">{t("Ticket ID")}:</div>
                            <div className="content">
                              {bookingDetails?.sb_booking_code}
                            </div>
                          </div>
                          {(bookingDetails?.voucher_code ||
                              bookingDetails?.voucher_code_fallback) && (
                              <div>
                                <div className="heading">
                                  {t("Voucher Code")}:
                                </div>
                                <div className="content">
                                  {bookingDetails?.voucher_code ||
                                  bookingDetails?.voucher_code_fallback}
                                </div>
                              </div>
                          )}
                          {/* <div>
                      <p>Availability:</p>
                      <p>Minimum seats booked</p>
                    </div> */}
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-3 col-xl-3 right">
                        {/* <div> */}
                        <button
                          className={`ticket-btn email-btn ${
                            sendEmailLoading ? "loader-text" : ""
                          }`}
                          onClick={onClickEmailTicket}
                        >
                          {t("email-ticket")}
                        </button>
                        {/* </div>
                    <div> */}
                        {!guestUser && (
                        <button
                          className={`ticket-btn mobile-btn ${
                            sendSmsLoading ? "loader-text" : ""
                          }`}
                          onClick={onClickSmsTicket}
                        >
                          {t("Send to mobile")}
                        </button>
                        )}
                        {/* </div>
                    <div> */}
                        <button
                          className="ticket-btn share-btn"
                          onClick={() => setModalShow(true)}
                        >
                          {t("Share Ticket")}
                        </button>
                        {/* </div> */}
                        {/* <div>
                    <button className="ticket-btn download-btn">
                      Download pdf
                    </button>
                  </div> */}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* Desktop Final Ticket */}
              {false && (
                <div className="final-ticket">
                  <div className="ticket-header">
                    <div>
                      <Link to>
                        <img
                          src={org_mycinema_logo || org_logo_url}
                          alt={org_name}
                          height="28px"
                          // className="img-fluid"
                        />
                      </Link>
                    </div>
                  </div>
                  {bookingDetailsLoading ? (
                    <div className="row my-5">
                      <div className="col-12 text-center">
                        <Spinner />
                      </div>
                    </div>
                  ) : (
                    <div className="ticket-content">
                      <div className="row top">
                        <div className="col-4 pr-0">
                          <img
                            src={
                              getLangSpecificAttribute(
                                bookingDetails?.languageSpecificImages,
                                lang_id,
                                "artwork"
                              ) ||
                              movieDetails?.md_thumbnail_url ||
                              bookingDetails?.md_thumbnail_url ||
                              dummyPoster
                            }
                            alt="Movie Img"
                            className="movie-img img-fluid"
                          />
                        </div>
                        <div className="col-5">
                          <div>
                            <h5 className="heading font-weight-bold">
                              {getLangSpecificAttribute(
                                bookingDetails?.languageSpecificImages,
                                lang_id,
                                "mc_title"
                              ) || bookingDetails?.movie_title}
                            </h5>
                            <div className="features">
                              <p>
                                {
                                  bookingDetails?.is_version_apply
                                      ? <>{bookingDetails.version_name}{" "}
                                        {bookingDetails?.mf_name}</>
                                      : <>{bookingDetails?.lang_name}{" "}
                                        {bookingDetails?.mf_name}</>
                                }
                              </p>
                              <p>
                                {bookingDetails?.g_name} |{" "}
                                {bookingDetails?.rating}
                              </p>
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-md-2 right">
           
                    {bookingDetails?.sb_booking_code && (
                      <QRCode
                        value={`${BASEURL}/your-ticket/${bookingDetails?.sb_booking_code}`}
                        className="mx-auto qr-border"
                      />
                    )}
                    <p>{t("Scan to get a ticket on your mobile")}</p>
                  </div> */}
                      </div>
                      <div className="row align-items-center py-3 my-2">
                        <div className="col-6 border-right d-flex align-items-center">
                          <h4 className="font-weight-bold mb-0">
                            {" "}
                            {moment(bookingDetails?.show_date).format("DD")}th
                          </h4>{" "}
                          <div className="pl-2">
                            <p>
                              {moment(bookingDetails?.show_date).format("MMMM")}
                            </p>
                            <p>
                              {moment(bookingDetails?.show_date).format("dddd")}
                            </p>
                          </div>
                        </div>
                        <div className="col-6">
                          <h4 className="font-weight-bold text-right mb-0">
                            {moment(
                              bookingDetails?.show_time,
                              "HH:mm:ss"
                            ).format("HH:mm")}
                          </h4>
                        </div>
                      </div>
                      <div className="row py-3 px-3 border-top my-2">
                        <div className="cinema-name">
                          <p className="fs-16">{t("Cinema")}:</p>
                          <p className="font-weight-bold pt-2">
                            {bookingDetails?.cine_name},{" "}
                            {bookingDetails?.cine_address}
                            {/* {bookingDetails?.cine_location} */}
                          </p>
                        </div>
                      </div>
                      {/* <div className="grey-dashed-border py-4">
                  <img src={greyBorder} alt="border" className="img-fluid" />
                </div> */}

                      <div className="row align-items-center py-3 my-2 border-top">
                        <div className="col-6 align-items-center">
                          <p>{t("Total Amount Paid")}</p>
                          <p className="pt-2">
                            {curr_code}{" "}
                            {currencyFormatter(
                              parseFloat(
                                bookingDetails?.sb_total_amount +
                                  (bookingDetails?.ft_total_amount || 0) -
                                  bookingDetails?.offer_price
                              )
                            )}
                          </p>
                        </div>
                        <div className="col-6">
                          <p>{t("Auditorium")}</p>
                          <p className="pt-2">{bookingDetails?.screen_name}</p>
                        </div>
                      </div>
                      {/* <div className="row bg-ticket rounded pb-2 pt-3 mx-3">
                  <div className="col-12 text-center">
                    <div class="pb-3">
                      <h4 className="font-weight-bold">PRIVATE SCREENING</h4>
                      <p className="font-weight-bold">Enter Hall Booked</p>
                    </div>
                    <div className="py-3 border-top">
                      <p className="pb-3">Please show this ticket with the QR Code to enter the cinema</p>
                      {bookingDetails?.sb_booking_code && (
                        <QRCode
                          value={`${BASEURL}/your-ticket/${bookingDetails?.sb_booking_code}`}
                          className="mx-auto qr-border"
                        />
                      )}
                    </div>
                    <div className="pb-3">
                      <p className="pb-2">Booking ID</p>
                      <h4 className="font-weight-bold">BB8IT0980</h4>
                    </div>
                  </div>
                </div> */}
                    </div>
                  )}
                </div>
              )}
            </div>
            {/* <div className="back-home mx-auto w-25 mt-5">
          <button
            className="payment-submit-btn"
            onClick={() => {
              dispatch(clearState());
              history.replace(`/`);
            }}
          >
            {t("Back to home")}
          </button>
        </div> */}
            <button
              className="mx-auto btn-main my-5"
              onClick={() => {
                dispatch(clearState());
                history.replace(`/`);
              }}
            >
              {t("Back to home")}
            </button>
          </>
        )}
        {/* --------------------GOLDEN TICKET---------------------------- */}
        {bookingDetails?.booking_type_id === 3 && (
          <>
            <div className="section row mt-5">
              <article className="col-12">
                <div className="private-final-ticket">
                  <section className="row mx-0">
                    <article className="col-lg-8 px-0">
                      <>
                        <div className="left-section px-2">
                          <figure className="py-3 mb-0 text-center text-md-left mx-n2 mx-md-0">
                            <img
                              src={org_mycinema_logo || org_logo_url}
                              alt={org_name}
                              height="23px"
                            />
                          </figure>
                          <section className="">
                            <figure className="img-wrapper">
                              <img
                                src={
                                  getLangSpecificAttribute(
                                    bookingDetails?.languageSpecificImages,
                                    lang_id,
                                    "artwork"
                                  ) ||
                                  movieDetails?.md_thumbnail_url ||
                                  bookingDetails?.md_thumbnail_url ||
                                  dummyPoster
                                }
                                alt=""
                              />
                            </figure>
                            <article className="movie-details">
                              <div className="">
                                <p className="">
                                  {getLangSpecificAttribute(
                                    bookingDetails?.languageSpecificImages,
                                    lang_id,
                                    "mc_title"
                                  ) || bookingDetails?.movie_title}
                                </p>
                                {<p className="">
                                  {
                                    (!!t(bookingDetails?.lang_name) ? t(bookingDetails?.lang_name) : bookingDetails?.lang_name)
                                    || bookingDetails?.version_name}{`${
                                  bookingDetails?.sub_1_iso_2 ? `/${bookingDetails?.sub_1_iso_2}` : ''
                                }${
                                  bookingDetails?.sub_1_iso_2 && bookingDetails?.sub_2_iso_2 ? `-${bookingDetails?.sub_2_iso_2}` : ''
                                }`}
                                        {" | "}
                                        {bookingDetails?.mf_name}
                                      </p>
                                }
                                <p className="">
                                  {bookingDetails?.genres_with_translations
                                        ?.filter(
                                            (genre) => genre[iso_2?.toLowerCase()]
                                        )
                                        ?.map(
                                            (genre, index) =>
                                                genre[iso_2?.toLowerCase()] &&
                                                index < 13 && (
                                                    <span key={index}>
                                            {genre[iso_2?.toLowerCase()]}{" "}
                                                      {index < 2 && ", "}
                                          </span>
                                                )
                                        ).length > 0
                                      ? (<>
                                        {bookingDetails?.genres_with_translations
                                            ?.filter(
                                                (genre) => genre[iso_2?.toLowerCase()]
                                            )
                                            ?.map(
                                                (genre, index, arr) =>
                                                    genre[iso_2?.toLowerCase()] &&
                                                    index < 13 && (
                                                        <span key={index}>
                                              {genre[iso_2?.toLowerCase()]}{" "}
                                                          {index < arr.length-1 && ", "}
                                            </span>
                                                    )
                                            ) || bookingDetails?.g_name}
                                        {" | "}
                                        {bookingDetails?.rating}
                                        </>)
                                      : (<> {bookingDetails?.rating}</>)
                                  }
                                </p>
                              </div>
                              {!isMobile && (
                                <div className="ticket-field mt-2">
                                  <p className=""> {t("Cinema")}:</p>
                                  <p className="">
                                    {bookingDetails?.cine_name},{" "}
                                    {bookingDetails?.cine_address}
                                  </p>
                                </div>
                              )}
                            </article>
                          </section>
                          {isMobile && (
                            <div className="ticket-field mt-2">
                              <p className=""> {t("Cinema")}:</p>
                              <p className="">
                                {bookingDetails?.cine_name},{" "}
                                {bookingDetails?.cine_address}
                              </p>
                            </div>
                          )}
                          <section className="final-ticket-details">
                            <div className="ticket-field">
                              <p>{t("Payment Method")}</p>
                              <p>
                                {bookingDetails?.stripeDetails?.card
                                  ? "Stripe"
                                  : bookingDetails?.pm_payment_mode
                                  ? bookingDetails?.pm_payment_mode
                                  : "Debit Card"}
                              </p>
                            </div>
                            {(bookingDetails?.voucher_code ||
                              bookingDetails?.voucher_code_fallback) && (
                              <div className="ticket-field">
                                <p>{t("Voucher Code")}</p>
                                <p>
                                  {bookingDetails?.voucher_code ||
                                    bookingDetails?.voucher_code_fallback}
                                </p>
                              </div>
                            )}
                            <div className="ticket-field">
                              <p>
                                {t("Booking")} {t("Date")} & {t("Time")}
                              </p>
                              <p>
                                {" "}
                                {moment(bookingDetails?.show_date).format(
                                  "ll"
                                )}{" "}
                                |{" "}
                                {moment(
                                  bookingDetails?.show_time,
                                  "HH:mm:ss"
                                ).format("HH:mm")}
                              </p>
                            </div>
                            {isMobile && bookingDetails.fnb_items.length > 0 && (
                              <div
                                className={`ticket-field ${
                                  isMobile ? "inline brdr pb-2" : ""
                                }`}
                              >
                                <p>{t("Concessions")}:</p>
                                <p className="comma-separated">
                                  {bookingDetails.fnb_items.map((item) => (
                                    <span>
                                      {item?.fst_quantity}&nbsp;
                                      {getLangSpecificAttribute(
                                        item?.FnbContent,
                                        lang_id,
                                        "item_lang_name"
                                      ) || item?.item_name}
                                    </span>
                                  ))}
                                </p>
                              </div>
                            )}
                            {isMobile && bookingDetails?.offer_price > 0 && (
                              <div
                                className={`ticket-field text-success ${
                                  isMobile ? "inline brdr pb-2" : ""
                                }`}
                              >
                                <p>{t("Discount")}:</p>
                                <p className="">
                                  - {curr_code} {bookingDetails?.offer_price}
                                </p>
                              </div>
                            )}

                            {!isMobile && bookingDetails.fnb_items.length > 0 && (
                              <div
                                className={`ticket-field ${
                                  isMobile ? "inline" : ""
                                }`}
                              >
                                <p>Concessions</p>
                                <p className="comma-separated">
                                  {bookingDetails.fnb_items.map((item) => (
                                    <span>
                                      {item?.fst_quantity}&nbsp;
                                      {getLangSpecificAttribute(
                                        item?.FnbContent,
                                        lang_id,
                                        "item_lang_name"
                                      ) || item?.item_name}
                                    </span>
                                  ))}
                                </p>
                              </div>
                            )}
                            {!isMobile && bookingDetails?.offer_price > 0 && (
                              <div
                                className={`ticket-field text-success ${
                                  isMobile ? "inline brdr pb-2" : ""
                                }`}
                              >
                                <p>{t("Discount")}:</p>
                                <p className="">
                                  {curr_code} {bookingDetails?.offer_price}
                                </p>
                              </div>
                            )}

                            {!!private_rate_card_detail?.length &&
                              !!private_rate_card_detail[0]?.slab.length &&
                              private_rate_card_detail[0].slab.map((item) => (
                                <div
                                  className={`ticket-field ${
                                    isMobile ? "inline brdr" : ""
                                  }`}
                                >
                                  <p>{t("Private Tickets")}</p>
                                  <p>
                                    {curr_code}{" "}
                                    {parseFloat(
                                      private_rate_card_detail[0]
                                        .customSeatCount * item.price
                                    ).toFixed(2)}
                                  </p>
                                </div>
                              ))}
                            {!!private_rate_card_detail?.length &&
                              !!private_rate_card_detail[0]?.fnb.length &&
                              private_rate_card_detail[0].fnb.map((item) => (
                                <div
                                  className={`ticket-field ${
                                    isMobile ? "inline brdr" : ""
                                  }`}
                                >
                                  <p>{t("Concessions")}</p>
                                  <p>
                                    {curr_code}{" "}
                                    {parseFloat(
                                      private_rate_card_detail[0]
                                        .customSeatCount * item.price
                                    ).toFixed(2)}
                                  </p>
                                </div>
                              ))}
                            {!!private_rate_card_detail?.length &&
                              !!private_rate_card_detail[0]?.addon.length &&
                              private_rate_card_detail[0].addon.map((item) => (
                                <div
                                  className={`ticket-field ${
                                    isMobile ? "inline brdr" : ""
                                  }`}
                                >
                                  <p>{t("Add Ons")}</p>
                                  <p>
                                    {curr_code}{" "}
                                    {parseFloat(
                                      item.quantity * item.price
                                    ).toFixed(2)}
                                  </p>
                                </div>
                              ))}
                            {private_rate_card_detail?.length === 0 && (
                                <div
                                    className={`ticket-field ${
                                        isMobile ? "inline brdr" : ""
                                    }`}
                                >
                                  <p>{t("Total Amount Paid")}</p>
                                  <p>
                                    {curr_code}{" "}
                                    {parseFloat(
                                        bookingDetails?.sb_total_amount +
                                        (bookingDetails?.ft_total_amount || 0) -
                                        bookingDetails?.offer_price
                                    ).toFixed(2)}
                                  </p>
                                </div>
                            )}
                            {private_rate_card_detail?.length > 0 && (
                                <div
                                    className={`ticket-field ${
                                        isMobile ? "inline brdr" : ""
                                    }`}
                                >
                                  <p>{t("Total Amount Paid")}</p>
                                  <p>
                                    {curr_code}{" "}
                                    {parseFloat(
                                        private_rate_card_detail[0].total -
                                        (bookingDetails?.offer_price || 0)
                                    ).toFixed(2)}
                                  </p>
                                </div>
                            )}
                            {/* <div
                            className={`ticket-field ${
                              isMobile ? "inline" : ""
                            }`}
                          >
                            <p>Tax Label 2</p>
                            <p>CHF 1050</p>
                          </div> */}
                          </section>
                          {!isMobile && (
                            <div className="golden-ticket-btn-group">
                              <button
                                className={`btn-main ${
                                  sendEmailLoading ? "loader-text" : ""
                                }`}
                                onClick={onClickEmailTicket}
                              >
                                {/* <img src={mail} alt="" /> */}
                                <svg
                                  width="32"
                                  height="32"
                                  viewBox="0 0 32 32"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M29 5H3C2.44687 5 2 5.44687 2 6V26C2 26.5531 2.44687 27 3 27H29C29.5531 27 30 26.5531 30 26V6C30 5.44687 29.5531 5 29 5ZM27.75 8.4625V24.75H4.25V8.4625L3.3875 7.79063L4.61562 6.2125L5.95312 7.25313H26.05L27.3875 6.2125L28.6156 7.79063L27.75 8.4625ZM26.05 7.25L16 15.0625L5.95 7.25L4.6125 6.20937L3.38438 7.7875L4.24687 8.45938L14.9219 16.7594C15.2289 16.9979 15.6066 17.1273 15.9953 17.1273C16.3841 17.1273 16.7618 16.9979 17.0688 16.7594L27.75 8.4625L28.6125 7.79063L27.3844 6.2125L26.05 7.25Z"
                                    fill="black"
                                  />
                                </svg>

                                {t("email-ticket")}
                              </button>
                              {!guestUser && (<button
                                  className={`btn-main ${
                                      sendSmsLoading ? "loader-text" : ""
                                  }`}
                                  onClick={onClickSmsTicket}
                              >
                                {/* <img src={mobile} alt="" /> */}
                                <svg
                                  width="14"
                                  height="22"
                                  viewBox="0 0 14 22"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.4375 0.453125H1.5625C0.735156 0.453125 0.0625 1.12578 0.0625 1.95312V19.9531C0.0625 20.7805 0.735156 21.4531 1.5625 21.4531H12.4375C13.2648 21.4531 13.9375 20.7805 13.9375 19.9531V1.95312C13.9375 1.12578 13.2648 0.453125 12.4375 0.453125ZM12.25 19.7656H1.75V2.14062H12.25V19.7656ZM6.0625 17.375C6.0625 17.6236 6.16127 17.8621 6.33709 18.0379C6.5129 18.2137 6.75136 18.3125 7 18.3125C7.24864 18.3125 7.4871 18.2137 7.66291 18.0379C7.83873 17.8621 7.9375 17.6236 7.9375 17.375C7.9375 17.1264 7.83873 16.8879 7.66291 16.7121C7.4871 16.5363 7.24864 16.4375 7 16.4375C6.75136 16.4375 6.5129 16.5363 6.33709 16.7121C6.16127 16.8879 6.0625 17.1264 6.0625 17.375Z"
                                    fill="black"
                                  />
                                </svg>

                                {t("Send to mobile")}
                              </button>)}

                              <button
                                className="btn-main"
                                onClick={() => setModalShow(true)}
                              >
                                {/* <img src={share} alt="" /> */}
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M18.625 0.625H1.375C0.960156 0.625 0.625 0.960156 0.625 1.375V18.625C0.625 19.0398 0.960156 19.375 1.375 19.375H9.8125C9.91562 19.375 10 19.2906 10 19.1875V17.875C10 17.7719 9.91562 17.6875 9.8125 17.6875H2.3125V2.3125H17.6875V9.8125C17.6875 9.91562 17.7719 10 17.875 10H19.1875C19.2906 10 19.375 9.91562 19.375 9.8125V1.375C19.375 0.960156 19.0398 0.625 18.625 0.625ZM13.3117 12.0484L14.5352 10.825C14.5599 10.8001 14.5772 10.7687 14.5851 10.7345C14.5929 10.7002 14.591 10.6645 14.5795 10.6313C14.568 10.5981 14.5475 10.5687 14.5202 10.5466C14.4928 10.5245 14.4599 10.5105 14.425 10.5062L10.2203 10.0141C10.1008 10 9.99766 10.1008 10.0117 10.2227L10.5039 14.4273C10.5227 14.582 10.7125 14.6477 10.8227 14.5375L12.0508 13.3094L18.0555 19.3141C18.1281 19.3867 18.2477 19.3867 18.3203 19.3141L19.3141 18.3203C19.3867 18.2477 19.3867 18.1281 19.3141 18.0555L13.3117 12.0484Z"
                                    fill="black"
                                  />
                                </svg>

                                {t("Share Ticket")}
                              </button>
                            </div>
                          )}
                        </div>
                      </>
                    </article>
                    <article className="col-lg-4 px-0">
                      <div className="right-section">
                        <section className="qr-section">
                          <figure className="mx-auto text-center">
                            <img src={ticket} alt="" />
                          </figure>
                          <p className="screening-name">
                            <figure>
                              <img src={star} alt="" />
                            </figure>
                            <span>{t(`Private Screening`)}</span>
                            <figure>
                              <img src={star} alt="" />
                            </figure>
                          </p>
                          <figure className="qr">
                            <p className="mb-2">
                              {/* {t(
                                "Scan the code to get the ticket on your mobile"
                              )} */}
                              {t("Scan the code to enter cinema")}
                            </p>
                            <QRCode
                              value={`${BASEURL}/your-ticket/${bookingDetails?.sb_booking_code}`}
                              className="mx-auto"
                              bgColor="transparent"
                            />
                          </figure>
                          <p className="text-center" id="ticket_number_label">
                            {t("Ticket ID")}:
                          </p>
                          <p className="text-center">
                            {bookingDetails?.sb_booking_code}
                          </p>
                        </section>
                        {bookingDetails?.special_request && (
                          <section className="px-2">
                            <p className="spl-ins">
                              {t("Special Instructions")}
                            </p>
                            <p>{bookingDetails?.special_request}</p>
                          </section>
                        )}
                        <section>
                          <p
                            className="text-center"
                            style={{ fontWeight: "unset" }}
                          >
                            {t("Show Date & Time")}:
                          </p>
                          <p
                            className="fs-16"
                            style={{ fontSize: "16px", fontWeight: 600 }}
                          >
                            {" "}
                            {moment(bookingDetails?.show_date).format(
                              "dddd"
                            )}, {moment(bookingDetails?.show_date).format(
                            "DD MMM YYYY"
                          )} |{" "}
                            {moment(
                              bookingDetails?.show_time,
                              "HH:mm:ss"
                            ).format("HH:mm")}
                          </p>
                        </section>
                        <section>
                          <p>{t("Auditorium")}</p>
                          <p className="d-flex align-items-center">
                            {bookingDetails?.screen_name}
                          </p>
                        </section>
                        <section>
                          <p>{t("Seats Selected")}:</p>
                          <p>{t("entire-hall-booked")}</p>
                        </section>
                        {(bookingDetails?.label_1 ||
                          bookingDetails?.label_2) && (
                          <div
                            className={`legal_text ${
                              isMobile ? "legal_text_mobile" : ""
                            }`}
                          >
                            {bookingDetails?.label_1 && (
                              <div className="text-center mb-2">
                                {bookingDetails?.label_1}
                              </div>
                            )}
                            {bookingDetails?.label_2 && (
                              <div className="text-center">
                                {bookingDetails?.label_2}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </article>
                  </section>
                </div>
              </article>
            </div>

            {isMobile && (
              <div className="golden-ticket-btn-group mt-4">
                {/* <button
                  className={`btn-main ${
                    sendEmailLoading ? "loader-text" : ""
                  }`}
                  onClick={onClickEmailTicket}
                >
                  <img src={mail} alt="" />
                  {t("email-ticket")}
                </button>
                <button
                  className={`btn-main ${sendSmsLoading ? "loader-text" : ""}`}
                  onClick={onClickSmsTicket}
                >
                  <img src={mobile} alt="" />
                  {t("Send to mobile")}
                </button>
                <button className="btn-main" onClick={() => setModalShow(true)}>
                  <img src={share} alt="" />

                  {t("share Ticket")}
                </button> */}
                <button
                  className="btn-main"
                  onClick={() => setActionsModal(true)}
                >
                  <img src={share} alt="" />

                  {t("Get Ticket")}
                </button>
              </div>
            )}

            <button
              className="mx-auto btn-main my-4"
              onClick={() => {
                dispatch(clearState());
                history.replace(`/`);
              }}
            >
              {t("Back to home")}
            </button>
          </>
        )}
      </div>

      <ShareTicketPopup
        link={`${window.location.protocol}//${window.location.host}/final-ticket/${show_booking_id}/${category_id}`}
        show={modalShow}
        onHide={() => setModalShow(false)}
        sharedObject="Ticket"
        message={getShareMessage(bookingDetails, lang_id)}
      />

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        className="share-ticket-popup"
        size="lg"
        centered
        onHide={() => setActionsModal(false)}
        show={actionsModal}
      >
        <section className="wrapper container-fluid">
          <img
            src={close}
            className="close-btn-main"
            alt=""
            onClick={() => setActionsModal()}
          />
          <div className="text-center title-row">
            <p className="title">{t("Get Ticket")}</p>
            <div className="golden-ticket-btn-group mt-4">
              <button
                className={`btn-main ${sendEmailLoading ? "loader-text" : ""}`}
                onClick={onClickEmailTicket}
              >
                {/* <img src={mail} alt="" /> */}
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M29 5H3C2.44687 5 2 5.44687 2 6V26C2 26.5531 2.44687 27 3 27H29C29.5531 27 30 26.5531 30 26V6C30 5.44687 29.5531 5 29 5ZM27.75 8.4625V24.75H4.25V8.4625L3.3875 7.79063L4.61562 6.2125L5.95312 7.25313H26.05L27.3875 6.2125L28.6156 7.79063L27.75 8.4625ZM26.05 7.25L16 15.0625L5.95 7.25L4.6125 6.20937L3.38438 7.7875L4.24687 8.45938L14.9219 16.7594C15.2289 16.9979 15.6066 17.1273 15.9953 17.1273C16.3841 17.1273 16.7618 16.9979 17.0688 16.7594L27.75 8.4625L28.6125 7.79063L27.3844 6.2125L26.05 7.25Z"
                    fill="black"
                  />
                </svg>

                {t("email-ticket")}
              </button>
              {!guestUser && (<button
                  className={`btn-main ${sendSmsLoading ? "loader-text" : ""}`}
                  onClick={onClickSmsTicket}
              >
                {/* <img src={mobile} alt="" /> */}
                <svg
                  width="14"
                  height="22"
                  viewBox="0 0 14 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.4375 0.453125H1.5625C0.735156 0.453125 0.0625 1.12578 0.0625 1.95312V19.9531C0.0625 20.7805 0.735156 21.4531 1.5625 21.4531H12.4375C13.2648 21.4531 13.9375 20.7805 13.9375 19.9531V1.95312C13.9375 1.12578 13.2648 0.453125 12.4375 0.453125ZM12.25 19.7656H1.75V2.14062H12.25V19.7656ZM6.0625 17.375C6.0625 17.6236 6.16127 17.8621 6.33709 18.0379C6.5129 18.2137 6.75136 18.3125 7 18.3125C7.24864 18.3125 7.4871 18.2137 7.66291 18.0379C7.83873 17.8621 7.9375 17.6236 7.9375 17.375C7.9375 17.1264 7.83873 16.8879 7.66291 16.7121C7.4871 16.5363 7.24864 16.4375 7 16.4375C6.75136 16.4375 6.5129 16.5363 6.33709 16.7121C6.16127 16.8879 6.0625 17.1264 6.0625 17.375Z"
                    fill="black"
                  />
                </svg>

                {t("Send to mobile")}
              </button>)}
              <button
                className="btn-main"
                onClick={() => {
                  setActionsModal(false);
                  setModalShow(true);
                }}
              >
                {/* <img src={share} alt="" /> */}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.625 0.625H1.375C0.960156 0.625 0.625 0.960156 0.625 1.375V18.625C0.625 19.0398 0.960156 19.375 1.375 19.375H9.8125C9.91562 19.375 10 19.2906 10 19.1875V17.875C10 17.7719 9.91562 17.6875 9.8125 17.6875H2.3125V2.3125H17.6875V9.8125C17.6875 9.91562 17.7719 10 17.875 10H19.1875C19.2906 10 19.375 9.91562 19.375 9.8125V1.375C19.375 0.960156 19.0398 0.625 18.625 0.625ZM13.3117 12.0484L14.5352 10.825C14.5599 10.8001 14.5772 10.7687 14.5851 10.7345C14.5929 10.7002 14.591 10.6645 14.5795 10.6313C14.568 10.5981 14.5475 10.5687 14.5202 10.5466C14.4928 10.5245 14.4599 10.5105 14.425 10.5062L10.2203 10.0141C10.1008 10 9.99766 10.1008 10.0117 10.2227L10.5039 14.4273C10.5227 14.582 10.7125 14.6477 10.8227 14.5375L12.0508 13.3094L18.0555 19.3141C18.1281 19.3867 18.2477 19.3867 18.3203 19.3141L19.3141 18.3203C19.3867 18.2477 19.3867 18.1281 19.3141 18.0555L13.3117 12.0484Z"
                    fill="black"
                  />
                </svg>

                {t("Share Ticket")}
              </button>
            </div>
          </div>
        </section>
      </Modal>
    </section>
  );
};

function getShareMessage(bookingDetails, lang_id) {
  return `Here is a ticket for ${
    getLangSpecificAttribute(
      bookingDetails?.languageSpecificImages,
      lang_id,
      "mc_title"
    ) || bookingDetails?.movie_title
  } - ${bookingDetails?.lang_name} ${bookingDetails?.mf_name} at ${
    bookingDetails?.cine_name
  }, on ${moment(bookingDetails?.show_date).format("ll")} at ${moment(
    bookingDetails?.show_time,
    "HH:mm:ss"
  ).format("HH:mm")}`;
}

export default withNamespaces()(FinalTicket);
