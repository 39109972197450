import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import offer from "@assets/pictures/offer.png";
import { withNamespaces } from "react-i18next";
import Slider from "react-slick";
import useIsMobile from "../../customHooks/useIsMobile";
import { fetchOffers } from "../../store/ancilliary/ancilliaryActions";
import { useSelector, useDispatch } from "react-redux";

const PnoSlider = (props) => {
  const dispatch = useDispatch();
  const offers = useSelector((state) => state.ancilliary.offers);
  useEffect(() => {
    if (offers.length === 0) {
      dispatch(fetchOffers());
    }
  }, [offers.length]);

  const pnoSlider = {
    arrows: true,
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const history = useHistory();
  const t = props.t;
  const isMobile = useIsMobile();

  return (
    offers && (
      /* Promotions and offers slider starts */
      /* Mobile Specific Starts */
      /* {isMobile && ( */
      <article className="row d-none d-md-flex">
        <div className="col">
          <div className="">
            {offers && (
              <Slider {...pnoSlider} className="pno-slider">
                {offers?.map((item, index) => (
                  <div className="" key={index}>
                    <section className="hero-section no-shade">
                      <div className="hero-img-section">
                        <img src={offer} alt="Offers" className="hero-img-1" />
                      </div>
                      <div className="hero-text justify-content-center align-items-end pb-3 pb-md-4 pb-lg-5">
                        <div className="container-fluid container-xl">
                          <div className="">
                            <button
                              className="btn-main mx-auto"
                              onClick={() =>
                                history.push("/promotions-and-offers")
                              }
                            >
                              {item?.offer_title}
                            </button>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                ))}
              </Slider>
            )}
          </div>
        </div>
      </article>
      /* )} */
      /* Mobile Specific Ends */
      /* Promotions and offers slider starts */
    )
  );
};

export default withNamespaces()(PnoSlider);
