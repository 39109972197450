import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";

// Components
import CustomInput from "@components/partials/CustomInput";
import Button from "@components/partials/Button";
import CashCardService from "../../../ApiServices/CashCardService";
import { useDispatch } from "react-redux";
import {
  fetchCashCardDetailsFromCardNumberPin,
  fetchCashCardDetailsFromCardNumber,
} from "../../../store/cashCard/cashCardActions";

const TopupCard = (props) => {
  const { t, classes } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  let [submitted, setSubmitted] = useState(null);

  let [card, setCard] = useState("");
  let [cardError, setCardError] = useState(false);

  let [pin, setPin] = useState("");
  let [pinError, setPinError] = useState(false);
  let [errorMsg, setErrorMsg] = useState("");

  let submitCardDetails = () => {
    setErrorMsg("");
    setCardError(false);
    setPinError(false);
    setSubmitted(true);

    let count = 0;
    if (!card) {
      ++count;
      setCardError(true);
    }
    if (!pin) {
      ++count;
      setPinError(true);
    }

    if (count > 0) {
      console.log({ pin, card });
      return;
    }

    dispatch(fetchCashCardDetailsFromCardNumberPin({ cash_card_number: card, cash_pin: pin }))
    .then(({ status, message }) => {
      if (!!status)
        history.push(`/cash-card-topup/${card}`, { cash_card_number: card, cash_pin: pin });
      else {
        setCardError(true);
        setErrorMsg(message);
      }
    })
    .catch(console.log);
  };

  return (
    <>
      <div className="check_balance_wrapper">
        <section className="row">
          <article className="col-sm-6 col-lg-4 col-xl-3 mx-auto">
            <>
              <CustomInput
                type="number"
                label={t("Card Number")}
                placeholder={t("Enter your Card number")}
                errorStatus={submitted && cardError}
                errorMsg={
                  submitted && cardError && !errorMsg
                    ? t("Please enter Card number")
                    : !!errorMsg
                      ? t(errorMsg)
                      : ""
                }
                value={card}
                onChange={(e) => {
                  setCard(e?.target?.value);
                }}
              />
              <CustomInput
                type="number"
                label={t("PIN number")}
                placeholder={t("Enter your PIN number")}
                errorStatus={submitted && pinError}
                errorMsg={submitted && pinError ? t("Please enter PIN number") : ""}
                value={pin}
                onChange={(e) => {
                  setPin(e?.target?.value);
                }}
              />
              <Button
                text={t("Proceed")}
                classes="w-100"
                onClick={() => submitCardDetails()}
                loading={submitted && !pinError && !cardError && !errorMsg}
                disable={submitted && !pinError && !cardError && !errorMsg}
              />
            </>
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(TopupCard);
