import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import RangeSlider from 'react-bootstrap-range-slider';

// Components
import Button from "@components/partials/Button";

// Images
import { ReactComponent as IncreaseIcon } from "@assets/pictures/svgs/gift-card-increase.svg";
import { ReactComponent as DecreaseIcon } from "@assets/pictures/svgs/gift-card-decrease.svg";
import Spinner from "../../partials/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { currencyFormatter } from "../../../helper/currencyFormatter";
import { SET_CASHCARD_BUY_AMOUNT, SET_CASHCARD_TOPUP_AMOUNT } from "../../../store/cashCard/cashCardTypes";

const TopupStepOne = (props) => {
  const dispatch = useDispatch();
  const { t, classes = "", onClickNext } = props;
  const [loading, setLoading] = useState(true);

  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;

  let rangeStart = 1;
  let rangeEnd = 250;
  let rangeStep = 1;

  const cashCardDetails = useSelector((state) => state.cashCard.cashCardDetails);
  const cashCardDetailsLoading = useSelector((state) => state.cashCard.cashCardDetailsLoading);
  const cashCardTopUpSelection = useSelector((state) => state.cashCard.cashCardTopUpSelection);

  let topUpValues = [20, 60, 100, 200, rangeEnd].map(v => ({ amount: v }));

  useEffect(() => {
    setLoading(cashCardDetailsLoading);
  }, [cashCardDetailsLoading]);

  if (loading || cashCardDetailsLoading)
    return <div className="row">
      <div className="col-12 text-center">
        <Spinner />
      </div>
    </div>;

  return (
    <>
      <div className={`gift_card_step_one ${classes}`}>
        <section className="row align-items-center">
          <article className="col-xl-6 mb-4">
            <section className="gift_card_value_btn_group">
              {topUpValues.map((step, index) => (
                <div
                  key={index}
                  className={
                    `gift_card_value_btn ${step.amount == cashCardTopUpSelection?.amount ? "active" : ""}`
                  }
                  onClick={(e) => {
                    dispatch({ type: SET_CASHCARD_TOPUP_AMOUNT, payload: step.amount });
                  }}
                >
                  {step.amount}{".00"}
                </div>
              ))}
            </section>
          </article>

          <article className="col-xl-6 mb-4">
            <label className="custom_input_range">
              <span>{curr_code} {rangeStart}.00</span>
              {/*<input
                type="range"
                value={cashCardTopUpSelection?.amount ?? 20}
                min={rangeStart}
                max={rangeEnd}
                step={rangeStep}
                onChange={(e) => {
                  console.log(e?.target?.value);
                  dispatch({ type: SET_CASHCARD_TOPUP_AMOUNT, payload: e?.target?.value ?? 20 });
                }}
              />*/}
              <div style={{ width: '100%', }}>
                <RangeSlider
                  tooltipPlacement="top"
                  tooltip="auto"
                  value={cashCardTopUpSelection?.amount}
                  min={rangeStart}
                  max={rangeEnd}
                  step={rangeStep}
                  onChange={e => {
                    dispatch({ type: SET_CASHCARD_TOPUP_AMOUNT, payload: e?.target?.value ?? 20 });
                  }}
                />
              </div>
              <span>{curr_code} {rangeEnd}.00</span>
            </label>
          </article>
          <article className="col-xl-6 mb-4 mb-xl-0">
            <div className="gift_card_input_group">
              <label>
                <p>{t("Amount")}</p>
                <input
                  readOnly
                  type="text"
                  value={`${curr_code} ${(cashCardTopUpSelection?.amount)}.00`}
                />
              </label>

              <Button
                text={t("common.NEXT")}
                size="lg"
                classes={`mx-auto mx-xl-0`}
                onClick={onClickNext}
              />
            </div>
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(TopupStepOne);
