import React, { useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";

// Components
import AboutUsHeroText from "./AboutUs/helper/AboutUsHeroText";
import AboutUsHeroSlider from "./AboutUs/helper/AboutUsHeroSlider";
import AboutUsHowItWorks from "./AboutUs/helper/AboutUsHowItWorks";
import AboutUsBookingType from "./AboutUs/helper/AboutUsBookingType";


const HowItWorks = ({t}) => {
  const history = useHistory();
  useEffect(() => { window.scrollTo(0, 0) }, []);

  return (
    <>
      <div className="about_us_new">
        {/*<AboutUsHeroText />

        <AboutUsHeroSlider />*/}

        <AboutUsHowItWorks />

        <AboutUsBookingType
          type="private" titleFirstHalf={t('Private Cinema')} titleSecondHalf="" description={t('PrivateCinemaMessage')}
          onGetStarted={() => history.push("/new-on-demand")}
        />

        <AboutUsBookingType
          type="watch_party" titleFirstHalf={t('Watch party')} titleSecondHalf="" description={t('WatchPartyMessage')}
          onGetStarted={() => history.push("/new-on-demand")} reverse
        />

        <AboutUsBookingType
          type="crowdsourced" titleFirstHalf={t("Crowdsourced Cinema")} titleSecondHalf=""
          description={t('CrowdsourcedCinemaMessage')} onGetStarted={() => history.push("/new-on-demand")}
        />

        {/* <BarOffers />

        <AuditoriumPlan />

        <EventsAndAppetisers />

        <TariffsAndVouchers />

        <AgeRating /> */}
      </div>
    </>
  );
};

export default withNamespaces()(HowItWorks);
