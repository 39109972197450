import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import useIsMobile from "../../customHooks/useIsMobile";
import { motion } from "framer-motion";

// Components
import MovieCardNew from "./MovieCardNew";
import FestivalCard from "./FestivalCard";
import Slider from "react-slick";

// Images
import poster from "@assets/pictures/sample-poster-new.png";
import festivalPoster from "@assets/pictures/sample-fest.png";
import { ReactComponent as ArrowRight } from "@assets/pictures/svgs/slider-arrow-left.svg";
import HorizontalCard from "./HorizontalCard";

const MovieNFests = (props) => {
  const { t, category, subCategory, movieCount, slider, type, section, search, showMovieList = undefined } = props;
  const history = useHistory();
  const isMobile = useIsMobile();
  const { pathname } = useLocation();

  // STATE
  const [swiping, setSwiping] = useState(false);
  const [isDedicatedPage, setIsDedicatedPage] = useState(false);

  // Slider Settings
  const heroSlider = {
    pauseOnFocus: true,
    arrows: true,
    dots: false,
    infinite: false,
    useTransform: false,
    onSwipe: () => setSwiping(true),
    slidesToShow: type === "mini_festival" ? 6 : 8,
    slidesToScroll: type === "mini_festival" ? 6 : 8,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          arrows: true,
          slidesToShow: type === "mini_festival" ? 5 : 7,
          slidesToScroll: type === "mini_festival" ? 5 : 7,
        },
      },
      {
        breakpoint: 1350,
        settings: {
          arrows: true,
          slidesToShow: type === "mini_festival" ? 4 : 6,
          slidesToScroll: type === "mini_festival" ? 4 : 6,
        },
      },
      {
        breakpoint: 992,
        settings: {
          arrows: true,
          slidesToShow: type === "mini_festival" ? 3 : 5,
          slidesToScroll: type === "mini_festival" ? 3 : 5,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: type === "mini_festival" ? 2 : 3,
          slidesToScroll: type === "mini_festival" ? 2 : 3,
        },
      },
    ],
  };

  const handleSwiping = (onClick) => {
    if (swiping) {
      setSwiping(false);
    } else {
      // Actual Click Actions
      onClick && onClick();
    }
  }

  const goToPage = () => {
    if (!isDedicatedPage) {
      if (type === "on_demand") {
        history.push("/new-on-demand");
      } else if (type === "now_showing") {
        history.push("/now-showing");
      } else if (type === "mini_festival") {
        history.push("/new-mini-festivals");
      }
    }
  }

  const getUrl = (item) => {
    let url = `/private-booking-flow/${item?.movie_id}/${item?.content_lang_id}/`
    if (section == 2) {
      url = `/private-booking-flow/${item?.movie_id}/${item?.content_lang_id}/`
    } else if (section == 1) {
      // url = `/movie-details/${item?.movie_id}/${item?.content_lang_id}/`
      url = `/now-showing-booking/${item?.movie_id}/${item?.content_lang_id}/`
    } else if (section == 4) {
      // url = `/now-showing-booking/${item?.movie_id}/${item?.content_lang_id}/`
      url = `/movie-details/${item?.movie_id}/${item?.content_lang_id}/3`
    }
    return history.push(url)
  }

  useEffect(() => {
    if (pathname === "/now-showing" || pathname === "/new-on-demand" || pathname === "new-mini-festivals") {
      setIsDedicatedPage(true);
    }
  }, [])

  return (<>
    <section className={`movie_n_fests_new ${isDedicatedPage ? "is_dedicated_page" : ""} ${section == 3 ? "__festivals" : ""}`}>
      <article className="movie_n_fests_new_headings"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.1 }}>
        {category && !search &&
          <span className={`movie_n_fests_new_category ${type === "on_demand" ? "text-warning" : ""}`}>
            {category}
            {/* This dot below is part of design */}
            &nbsp;
            {/* .&nbsp; */}
          </span>
        }
        {/* Sub Category */}
        {
          (!search) && (
            <span className="movie_n_fests_new_category_sub_category">
              {t(`${subCategory}`)}
            </span>)
        }
        {/* GATEWAY TO DEDICATED PAGE */}
        {!search && (<>
        {isMobile ?
          <>
            {movieCount &&
              <span
                className="movie_n_fests_new_category_cards"
                onClick={() => {
                  if (showMovieList){
                    history.push(`/show-list-movies/${
                      showMovieList.category_id
                    }${
                      !!showMovieList?.section_id ? `/${showMovieList?.section_id}` : ''
                    }`)
                  }
                }}
              >({movieCount})
              </span>
            }
            <figure className="movie_n_fests_new_arrow_right">
              {/*<ArrowRight />*/}
            </figure>
          </>
          :
          <span
            className={`movie_n_fests_new_category_cards ${ showMovieList ? "pointer" : ""}`}
            onClick={() => {
              if (showMovieList){
                history.push(`/show-list-movies/${
                  showMovieList.category_id
                }${
                  !!showMovieList?.section_id ? `/${showMovieList?.section_id}` : ''
                }`)
              }
            }}
          >
            {movieCount} {movieCount && parseInt(movieCount) > 1 ? section == 3 ? t("Festivals") : t("Movies") : section == 3 ? t("Festival") : t("Movie")}
          </span>
        }
        </>)}
      </article>
      {slider ?
        // IF NEED TO SHOW AS SLIDER
        <article className="row">
          <section className="col-12 slider_col_spacing">
            <Slider {...heroSlider}>
              {/* Loop through Cards */}
              {props?.moviesArray?.map((item, index) => (
                section == 3 ?
                  item?.total_movies > 0 && <FestivalCard slider festival={item} key={index} onClick={() => {
                    handleSwiping(() => {
                      history.push(`/mini-festivals/${item?.unique_id}?mini_fest_id=${item?.unique_id}`)
                    })
                  }} />
                  :
                  <MovieCardNew slider {...item} key={index} onClick={() => {
                    handleSwiping(() => getUrl(item))
                  }} />
              ))
              }
            </Slider>
          </section>
        </article>

        : !!search
        ? (
            <aside className="row search-results book_search">
              {
                props?.moviesArray?.map?.((search, index) => (
                  <div className="" key={index}>
                    <HorizontalCard
                      showType={type == 'now_showing' ? 'now_showing' : 'on_demand'}
                      type="yellow"
                      movie={search}
                      key={index}
                      setSearchBar={() => {
                      }}
                      onChangeSearch={()=>{}}
                      t={t}
                      onClick={() => {
                        // handleSwiping(() => getUrl(search))
                      }}
                    />
                  </div>
                ))
              }
            </aside>
          )
        :
        // IF NEED TO SHOW AS NORMAL STACKING CARDS
        /* Loop through Cards */
        <article className="movie_n_fests_grid">
          {props?.moviesArray?.map((item, index) => (
            category === "Mini Festivals" ?
              <FestivalCard festival={item} key={index} onClick={() => {
                handleSwiping(() => {
                  history.push(`/mini-festivals${item?.unique_id}?mini_fest_id=${item?.unique_id}`)
                })
              }} />
              :
              <MovieCardNew {...item} key={index} onClick={() => {
                handleSwiping(() => getUrl(item))
              }} />
          ))}
        </article>
      }
    </section>
  </>);
}

// Default Props
MovieNFests.defaultProps = {
  category: "",
  subCategory: "",
  type: "now_showing",
  cards: "8",
};

export default withNamespaces()(MovieNFests);
