import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { withNamespaces } from "react-i18next";
import dummyPosterStatic from "@assets/pictures/dummyPoster.png";
import useIsMobile from "../../customHooks/useIsMobile";
import { getLangSpecificAttribute } from "@helper/languages";
import moment from "moment";
import { SET_MOVIE_LANGUAGES } from "@store/movies/movieTypes";
import { clearMovieDetails, clearMovieSchedules, clearNewOndemandState } from "@store/movies/movieActions";
import { clearSeatSelection } from "@store/seatLayout/seatLayoutActions";
import { clearFnbState } from "@store/foodAndBeverages/foodAndBeveragesActions";

const HorizontalCard = ({
  // type = "yellow",
  onChangeSearch,
  setSearchBar,
  t,
  onClick: onClickSearchResult,
  movie,
  showType = 'now_showing',
}) => {
  const dispatch = useDispatch();
  let NowShowing = movie['NowShowing']?.[0] || undefined
  let OnDemand = movie['OnDemand']?.[0] || undefined
  let Upcoming = movie['Upcoming']?.[0] || undefined
  let OnDemandLabeledMovies = movie['OnDemandLabeledMovies']?.[0] || undefined

  let {
    md_thumbnail_url,
    movie_title,
    genre_name,
    mf_name,
    rating,
    movie_id,
    content_lang_id,
    genre_id,
    category_id,
    now_demand_count,
    now_showing_count,
    genres_with_translations,
    languages_with_translations = [],
    altMovieContent,
    is_upcomimg,
    original_release_date,
    original_title_of_movie,
    lang_name,
    format_name
  } = NowShowing || OnDemand || Upcoming || OnDemandLabeledMovies || {};

  now_showing_count = showType == "now_showing" ? 1 : 0;
  now_demand_count = showType == "on_demand" ? 1 : 0;

  const selected_language = useSelector((state) => state.movies.selected_language);

  const organization = useSelector((state) => state.movies.organizationDetails);
  const { dummy_poster: dummyPosterDynamic } = organization;

  const dummyPoster = window.localStorage.getItem("placeholder_image") ? window.localStorage.getItem("placeholder_image") : dummyPosterStatic;

  const { iso_2, lang_id } = selected_language;
  const history = useHistory();
  const isMobile = useIsMobile();
  // let type1 = "yellow"; // now showing
  // let type2 = "yellow"; // on demand
  // let type3 = "yellow"; // coming soon
  //   if (showType === "Book Now") {
  //   type = "green";
  // }
  // if (now_showing_count > 0) {
  //   type1 = "red";
  // }
  // if (now_demand_count > 0) {
  //   type2 = "yellow";
  // }

  useEffect((OnDemand) => {}, [movie]);
  const getFormat = (
    NowShowing,
    OnDemand,
    Upcoming,
    OnDemandLabeledMovies
  ) => {
    return OnDemand?.format_name || NowShowing?.format_name || Upcoming?.format_name || OnDemandLabeledMovies?.format_name ||
      OnDemand?.format_name || NowShowing?.format_name || Upcoming?.format_name || OnDemandLabeledMovies?.format_name || ''
  }

  // if (!movie_title) return <>Loading...</>
  return (<div className="searched-movie-link">
    <div
      className={`searched-movie ${false ? "searched-movie-on-demand" : ""}`}
      // onClick={() => {
      //   onClickSearchResult && onClickSearchResult();
      // }}
    >
      <div className="img-wrapper">
        <img
          src={getLangSpecificAttribute(altMovieContent, lang_id, "artwork") || dummyPoster}
          alt=""
        />
      </div>

      <div className="movie-content">
        <div style={{ paddingBottom: "15px" }}>

          <p className="movie-name">
            {getLangSpecificAttribute(altMovieContent, lang_id, "mc_title") || movie_title || original_title_of_movie}
          </p>

          <div style={{ display: "flex" }}>
            <span style={{ fontSize: "12px", fontWeight: "300",wordBreak:"break-all",paddingBottom:"10px" }}>
              {!!(getFormat(NowShowing,OnDemand,Upcoming,OnDemandLabeledMovies)) && `${getFormat(NowShowing,OnDemand,Upcoming,OnDemandLabeledMovies)}`}
              {genres_with_translations?.map((genre, index) => (
                <>
                  {genre[iso_2?.toLowerCase()]}
                  {genres_with_translations?.length - 1 !== index && ","}
                  &nbsp;
                </>
              )).length > 0
                ? <> {!!(getFormat(NowShowing,OnDemand,Upcoming,OnDemandLabeledMovies)) && ` | `} {
                  genres_with_translations?.map((genre, index) => (
                    <>
                      {genre[iso_2?.toLowerCase()]}
                      {genres_with_translations?.length - 1 !== index && ","}
                      &nbsp;
                    </>
                  ))
                }</>
                : <></>
              }
              {!!rating && ` | ${rating}`}
              {original_release_date && (` | ${moment(original_release_date).format("YYYY")}`)}
            </span>
          </div>
        </div>

        <div className="">
          <div className="tens">
          {!!OnDemand && <button
            
            className={`btn-main tag yellow`}
            onClick={() => {
              dispatch(clearMovieSchedules())
              .then(() => {
                dispatch(clearMovieDetails())  // not async
                dispatch(clearNewOndemandState()) // not async
                return dispatch(clearSeatSelection())
              })
              .then(() => {
                return dispatch(clearFnbState())
              })
              .then(() => {
                history.push(`/private-booking-flow/${OnDemand?.movie_id}/${OnDemand?.content_lang_id}`)
              })
            }}
          >
            {t(`Private Cinema`)}
          </button>}
          {
            !!OnDemand && (
              <p className="genre text-limit-2">
                {OnDemand?.languages_with_translations?.map((l, index) => (
                  <button
                    key={index}
                    className="btn_change mx-1 mb-1"
                  >
                    {l?.iso_2?.slice(0, 2) || l?.iso_3?.slice(0, 2) || l?.lang_name.slice(0, 2)}
                  </button>))}
              </p>
            )
          }
            {
              !!NowShowing && (<>
                <div>
                  <button
                    className="btn-main tag red"
                    onClick={() => {
                      dispatch(clearMovieSchedules())
                      .then(() => {
                        dispatch(clearMovieDetails())  // not async
                        dispatch(clearNewOndemandState()) // not async
                        return dispatch(clearSeatSelection())
                      })
                      .then(() => {
                        return dispatch(clearFnbState())
                      })
                      .then(() => {
                        history.push(`/now-showing-booking/${NowShowing?.movie_id}/${NowShowing?.content_lang_id}`)
                      })
                    }}
                  >
                    {t(`Now Showing`)}
                  </button>
                </div>

                <p className=""> {/*class name should be genre text-limit-2 */}
                  {NowShowing?.languages_with_translations?.filter(l => l?.ss_id != null)?.map((l, index) => (
                    <button
                      key={index}
                      className="btn_change mx-1 mb-1"
                    >
                      {l?.iso_2?.slice(0, 2) || l?.iso_3?.slice(0, 2) || l?.lang_name?.slice(0, 2)}
                    </button>))}
                </p>
              </>)
            }
          </div>


          

          {/*{is_upcomimg && <button
            className={`btn-main tag ${type3}`}
          >
            {is_upcomimg === true ? t("Upcoming") : ``}
          </button>}*/}

         
        </div>
      </div>
    </div>
  </div>);
};

export default withNamespaces()(HorizontalCard);
