import "core-js/stable";
import "core-js/features/promise/all-settled";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import GlobalConfigContext from "@context/GlobalConfigContext";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { LanguageService, PwaService } from "@apiService/tokenService";
import { isInStandaloneMode } from "@helper/utils";
import { getTrackingIdInfo } from "@helper/googleAnalytics";
import ReactGA from "react-ga";
import qs from "qs";
import io from "socket.io-client";
import { BASEURL } from "@config/index";
const language = LanguageService.getLanguage();

const qparams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
const lang_from_param = qparams.lang;

function stopMainSpinner() {
  let mainSpinner = document.getElementById("main-spinner");
  if (mainSpinner) {
    mainSpinner.style.display = "none";
  }
}

const imports = [
  import("bootstrap/dist/css/bootstrap.min.css"),
  import("@assets/css/App.css"),
  import("@assets/css/style.css"),
];

if (language == "ar" || language == "ab" || lang_from_param == "ar") {
  import("@assets/css/arabic.css").then((condition) => {
    stopMainSpinner();
  });
} else {
  Promise.all(imports).then((condition) => {
    stopMainSpinner();
  });
}

const isPwa = isInStandaloneMode();
if (isPwa) {
  PwaService.setIsPwa(true);
}

ReactDOM.render(
  <GlobalConfigContext>
    <Router>
      <App />
    </Router>
  </GlobalConfigContext>,
  document.getElementById("root")
);
// document.addEventListener("DOMContentLoaded", function(event) {
//       ReactDOM.render(
//         <GlobalConfigContext>
//           <Router>
//             <App />
//           </Router>
//         </GlobalConfigContext>,
//         document.getElementById('root')
//       );
//     });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
