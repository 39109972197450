import React, { useState, useEffect, useRef } from "react";
import { withNamespaces } from "react-i18next";
import { getLangSpecificAttribute } from "@helper/languages";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchTodaysScreenings,
  fetchCreateOwnShow,
  fetchMiniFestivals,
  fetchComingSoon
} from "../store/homepage/homepageActions";
// Components
import MoviesNFestsNew from "@components/partials/MoviesNFestsNew";
import InlineScroll from "@components/partials/InlineScroll";
import _, { groupBy } from "lodash";
import { setSearchedQuery } from "@store/ancilliary/ancilliaryActions";

let genres = []

const SearchContainer = ({ t, setSearchComp, searchComp }) => {
  const searchedQuery = useSelector(state => state.ancilliary.searchedQuery)
  const searchResult = useSelector((state) => state.homepage.searchMovies);
  const cms_settings = useSelector((state) => state.homepage.cms_settings);
  const CMSLOADER = useSelector((state) => state.homepage.cmsLoader);

  const [selectedLabelFilter, setLabelFilter] = useState('')
  const [selectedLabelFilterGenre, setLabelFilterGenre] = useState('')

  const todaysScreeningsLabeledMovies = useSelector(
    (state) => state.homepage.todaysScreeningsLabeledMovies
  );
  const onDemandLabeledMovies = useSelector(
    (state) => state.homepage.onDemandLabeledMovies
  );

  const todaysScreeningsMovies = useSelector(
    (state) => state.homepage.todaysScreenings
  );
  const createOwnShowMovies = useSelector(
    (state) => state.homepage.createOwnShow
  );

  const comingSoonMovies = useSelector((state) => state.homepage.comingSoon);

  const uniqueTodaysScreeningMovies = {
    ...todaysScreeningsMovies,
    data: [
      ...new Map(
        todaysScreeningsMovies?.data?.map((item) => [item["movie_id"], item])
      ).values(),
    ],
  };
  const uniqueCreateOwnShowMovies = {
    ...createOwnShowMovies,
    data: [
      ...new Map(
        createOwnShowMovies?.data?.map((item) => [item["movie_id"], item])
      ).values(),
    ],
  };

  const uniqueComingSoonMovies = {
    ...comingSoonMovies,
    data: [
      ...new Map(
        comingSoonMovies?.data?.map((item) => [item["movie_id"], item])
      ).values(),
    ],
  };

  const miniFestivalsData = useSelector(
    (state) => state.homepage.miniFestivals
  );

  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );
  const { iso_2, lang_id } = selected_language;

  const dispatch = useDispatch();

  const isItemActive = (item) => {
    if (!CMSLOADER) {
      if (cms_settings && cms_settings.length > 0) {
        if (cms_settings[0][item] == "Y") {
          return true;
        } else if (cms_settings[0][item] == "N") {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    setLabelFilter('All');
  }, [searchComp])

  useEffect(() => {
    dispatch({ type: "FETCH_TODAYS_SCREENINGS" });
    dispatch({ type: "FETCH_CREATE_OWN_SHOW" });
    dispatch({ type: "FETCH_MINI_FESTIVALS" });
    dispatch({ type: "FETCH_COMING_SOON" });
    dispatch(
      fetchCreateOwnShow({
        limit: 100,
        currentPage: 1,
      })
    );
    dispatch(
      fetchMiniFestivals({
        currentPage: 1,
        index: null,
        category_link_name: null,
        category_id: 4
      })
    );
    dispatch(
      fetchComingSoon({
        limit: 1000,
        currentPage: 1,
      })
    );
    dispatch(
      fetchTodaysScreenings({
        limit: 1000,
        currentPage: 1,
      })
    );
    setLabelFilter('All')
  }, [])

  const createLabelsList = () => {
    let LabelList = [{
      label: 'All',
      language_content: []
    }];
    let tempArr = searchedOnDemandLabeledMovies?.map((category, index) => {
      if (!LabelList?.some(label => label?.label?.toLowerCase() == category?.category_link_name?.toLowerCase())) {
        let obj = {
          label: category?.category_link_name,
          language_content: category?.language_content
        }
        LabelList.push(obj);
      }
    })
    return LabelList;
  }

  const checkFilter = (label) => {
    if (selectedLabelFilter?.toLowerCase() == label?.toLowerCase()) {
      return true;
    } else if (selectedLabelFilter == 'All') {
      return true;
    } else {
      return false;
    }
  }

  const SearchMovieData = (movieList = [], index, searchType) => {
    let searchedResult = [];
    searchedResult = movieList?.filter((x) => {
      if (
        x?.movie_title &&
        x?.movie_title
          .toLowerCase()
          .includes(searchedQuery.toLowerCase())
      ) {
        return x;
      } else if (
        x?.d_company_name &&
        x?.d_company_name
          .toLowerCase()
          .includes(searchedQuery.toLowerCase())
      ) {
        return x;
      } else if (x?.alternate_movie_name_language && x?.alternate_movie_name_language?.toLowerCase().includes(searchedQuery.toLowerCase())) {
        return x;
      } else if (
        x?.languageSpecificImages?.length > 0 &&
        x?.languageSpecificImages.some(mc => mc?.mc_title.toLowerCase().includes(searchedQuery.toLowerCase()))
      ) {
        return x;
      } else if (
        x?.genres_with_translations?.length > 0 &&
        x?.genres_with_translations.some(g => g?.de?.toLowerCase().includes(searchedQuery.toLowerCase()) || g?.en?.toLowerCase().includes(searchedQuery.toLowerCase()) || g?.fr?.toLowerCase().includes(searchedQuery.toLowerCase()) || g?.it?.toLowerCase().includes(searchedQuery.toLowerCase()) || g?.g_name?.toLowerCase().includes(searchedQuery.toLowerCase()))
      ) {
        return x;
      } else if (
        x?.languages_with_translations?.length > 0 &&
        x?.languages_with_translations.some(l => l?.de?.toLowerCase().includes(searchedQuery.toLowerCase()) || l?.en?.toLowerCase().includes(searchedQuery.toLowerCase()) || l?.fr?.toLowerCase().includes(searchedQuery.toLowerCase()) || l?.it?.toLowerCase().includes(searchedQuery.toLowerCase()) || l?.lang_name?.toLowerCase().includes(searchedQuery.toLowerCase()))
      ) {
        return x;
      } else if (
        x?.label_to_show?.length > 0 &&
        x?.label_to_show.some(label => label?.label_name.toLowerCase().includes(searchedQuery.toLowerCase()))
      ) {
        return x;
      } else if (
        x?.label_to_show?.length > 0 &&
        x?.label_to_show.some(label => label?.label.toLowerCase().includes(searchedQuery.toLowerCase()))
      ) {
        return x;
      } else if (
        x?.category_link_name &&
        x?.category_link_name
          .toLowerCase()
          .includes(searchedQuery.toLowerCase())
      ) {
        return x
      } else if (
        x?.language_content?.length > 0 &&
        x?.language_content?.some(label => label?.event_name?.toLowerCase().includes(searchedQuery.toLowerCase()))
      ) {
        return x
      } else if (
        x?.cast_crew?.length > 0 &&
        x?.cast_crew?.some(cast => cast?.toLowerCase().includes(searchedQuery.toLowerCase()))
      ) {
        return x
      } else if (
        x?.keywords_list?.length > 0 &&
        x?.keywords_list?.some(key_w => key_w?.toLowerCase().includes(searchedQuery.toLowerCase()))
      ) {
        return x
      }
    })
    searchedResult.forEach(r => r.searchType = searchType)
    return searchedResult;
  }

  const searchCategoryMovies = (categories = [], index, searchType) => {
    let temp_arr = [];
    if (categories?.length > 0) {
      let temp = categories?.map((x) => {
        let obj = {
          ...x,
          movies: SearchMovieData(x?.movie_data?.data, undefined, searchType)
        }
        temp_arr.push(obj);
      })
    }
    // console.log("temp_arr", temp_arr);
    temp_arr.forEach(r => r.searchType = searchType)
    return temp_arr;
  }

  // const searchedNowShowing = SearchMovieData(uniqueTodaysScreeningMovies?.data, undefined, 'NowShowing');
  const searchedNowShowing = React.useMemo(
    () => SearchMovieData(uniqueTodaysScreeningMovies?.data, undefined, "NowShowing"),
    [uniqueTodaysScreeningMovies, searchedQuery]
  )

  // const searchedOnDemand = SearchMovieData(uniqueCreateOwnShowMovies?.data, undefined, 'OnDemand');
  const searchedOnDemand = React.useMemo(
    () => SearchMovieData(uniqueCreateOwnShowMovies?.data, undefined, "OnDemand"),
    [uniqueCreateOwnShowMovies, searchedQuery]
  )

  // const searchedUpcoming = SearchMovieData(uniqueComingSoonMovies?.data, undefined, 'Upcoming');
  const searchedUpcoming = React.useMemo(
    () => SearchMovieData(uniqueComingSoonMovies?.data, undefined, "Upcoming"),
    [uniqueComingSoonMovies, searchedQuery]
  )

  // const searchedOnDemandLabeledMovies = searchCategoryMovies(miniFestivalsData?.Records, undefined, 'OnDemandLabeledMovies');
  const searchedOnDemandLabeledMovies = React.useMemo(
    () => searchCategoryMovies(miniFestivalsData?.Records, undefined, "OnDemandLabeledMovies"),
    [miniFestivalsData, searchedQuery]
  )

  let movies = [
    ...searchedNowShowing,
    ...searchedOnDemand,
    ...searchedUpcoming,
    ...searchedOnDemandLabeledMovies.flatMap(x => x.movies)
  ]
  let movieIds = [...new Set(movies.map(x => x.movie_id))]
  let groupByMovies = groupBy(movies, 'movie_id')
  let merged = []
  movieIds.forEach(i => {
    merged.push(
      groupBy(groupByMovies[i], "searchType")
    )
  })

  if (genres.length === 0)
  genres = [
    ...new Set(
      movies.flatMap(x => x.genres_with_translations.flatMap(g => JSON.stringify(g)))
    )
  ]

  useEffect(() => {
    // console.log('searchedOnDemandLabeledMovies', searchedOnDemandLabeledMovies)
  }, [searchedOnDemandLabeledMovies])

  const searchMoviesLoading = useSelector(
    (state) => state.homepage.searchMoviesLoading
  );

  const noResultFound = () => {
    let status = false;
    let count = 0;
    if (isItemActive("show_on_demand")) {
      if (searchedOnDemand?.length == 0 && searchedNowShowing?.length == 0 && searchedUpcoming?.length == 0) {
        let temp = searchedOnDemandLabeledMovies?.map((x) => {
          if (x.movies.length > 0) {
            count += 1;
          }
        })
        if (count == 0) {
          status = true;
        } else {
          status = false;
        }
      } else if (selectedLabelFilter && selectedLabelFilter !== 'All') {
        let filterData = searchedOnDemandLabeledMovies.filter((x) => x?.category_link_name?.toLowerCase() == selectedLabelFilter?.toLowerCase())
        if (filterData.length > 0) {
          let arr = filterData?.map((x) => {
            if (x?.movies?.length > 0) {
              count += 1;
            }
          })
        }
        if (count == 0) {
          status = true
        } else {
          status = false
        }

      } else {
        status = false;
      }
    } else if (searchedNowShowing?.length == 0 && searchedUpcoming?.length == 0) {
      status = true;
    } else {
      status = false;
    }
    return status
  }

  return (
    <>
      <div className={`container-fluid search_container`}>
        <section>

        </section>

        <section className="row">
          <article className="col-12">
            <InlineScroll>
            <h6 className="lube">{t('Search Titles related to')}</h6>
              {/*
              {createLabelsList()?.map((label, index) => (
                <span key={index + 'search_tag'}
                  className={`search_tag ${selectedLabelFilter?.toLowerCase() == label?.label?.toLowerCase() ? "active" : ""}`}
                  onClick={() => setLabelFilter(label?.label)}>{getLangSpecificAttribute(
                    label?.language_content,
                    lang_id,
                    "event_name",
                    "lang_id"
                  ) || label?.label}</span>
              ))}
              <button className="search_tag active">Action</button>

              <button className="search_tag active">Comedy</button>
              */}
              {genres?.map(x => JSON.parse(x))?.map((genre, index) => (
                index < 6 &&
                <span key={index + "search_tag"}
                      className={`search_tag ${
                        selectedLabelFilterGenre?.genre_id === genre?.genre_id && searchedQuery == genre[iso_2?.toLowerCase()]
                          ? "active" : ""}`}
                      onClick={() => {
                        setLabelFilterGenre(genre);
                        dispatch(setSearchedQuery(genre[iso_2?.toLowerCase()]))
                      }}
                >
                  {genre[iso_2?.toLowerCase()]}
                </span>
              ))}
            </InlineScroll>
          </article>
        </section>
        <hr className="hori"/>
        <div >
          <span style={{fontSize:"18px",fontWeight:"600"}}>
            {t("Search Result")}
            <span style={{fontSize:"10px",color:"grey",paddingLeft:"13px"}}>
              {merged?.length} {merged?.length && merged?.length > 1 ? t("Movies") : t("Movie")}
            </span>
          </span>
        </div>

        <section className="">
          {merged?.length > 0 && (
            <MoviesNFestsNew
              search={true}
              section={2}
              type="search"
              moviesArray={merged}
              subCategory={""}
              category={""}
              movieCount={merged?.length}
              onClick={() => {
                setSearchComp(false);
              }} />
          )}
        </section>

{/*
<section className="">
{searchedNowShowing?.length > 0 && (
          checkFilter('All') &&
          <MoviesNFestsNew
            search={true}
            section={1}
            moviesArray={searchedNowShowing}
            subCategory={''}
            category={t("Now Showing")}
            movieCount={searchedNowShowing?.length}
            type="now_showing"
            onClick={() => {
              setSearchComp(false);
            }} />
        )}


</section>
{isItemActive("show_on_demand") && searchedOnDemandLabeledMovies?.length > 0 && searchedOnDemandLabeledMovies?.map((category, index) => (
          category?.movies?.length > 0 && checkFilter(category?.category_link_name) && (
            <MoviesNFestsNew
              search={true}
              key={index}
              section={2}
              moviesArray={category?.movies}
              subCategory={getLangSpecificAttribute(
                category?.language_content,
                lang_id,
                "event_name",
                "lang_id"
              ) || category?.category_link_name}
              category={t("On Demand")}
              type="on_demand"
              movieCount={category?.movies?.length} />)
        ))}

        {isItemActive("show_on_demand") && searchedOnDemand?.length > 0 && (
          checkFilter('All') &&
          <MoviesNFestsNew
            search={true}
            section={2}
            type="on_demand"
            moviesArray={searchedOnDemand}
            subCategory={'All'}
            category={t("On Demand")}
            movieCount={searchedOnDemand?.length}
            onClick={() => {
              setSearchComp(false);
            }} />
        )}

        {isItemActive("show_upcoming") && searchedUpcoming?.length > 0 && (
          checkFilter('All') &&
          <MoviesNFestsNew
            search={true}
            section={4}
            moviesArray={searchedUpcoming}
            subCategory={''}
            category={t("Upcoming")}
            movieCount={searchedUpcoming?.length}
            type="show_upcoming"
            onClick={() => {
              setSearchComp(false);
            }} />
        )}
        */}


        <section className="row">
          <div className="col-12 text-center">
            {noResultFound() && (
              <h6 className="mx-auto mb-0">
                {t("common.No movies found")}
              </h6>
            )}
          </div>
        </section>


      </div>
    </>
  );
}

export default withNamespaces()(SearchContainer);
