import React, { useEffect, useRef, useState } from "react";
import { withNamespaces } from "react-i18next";
import useIsMobile from "../../../customHooks/useIsMobile";
import { useLocation } from "react-router-dom";

// Images
import giftCardImg from "@assets/pictures/gift-card.png";
import { useSelector } from "react-redux";
import Spinner from "../../partials/Spinner";
import { currencyFormatter } from "../../../helper/currencyFormatter";

const CashCardInfoPayment = (props) => {
  const { t, classes = "" } = props;
  const {pathname} = useLocation();
  const isMobile = useIsMobile();

  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;

  const [showCheckout, setShowCheckout] = useState(true);
      const giftCardBottomRef = useRef();
  const giftCardInfoRef = useRef();

  useEffect(() => {
    const setGiftCardBottomWidth = () => {
      const giftCardBottom = giftCardBottomRef.current;
      const giftCardInfo = giftCardInfoRef.current;
      if (giftCardBottom && giftCardInfo) {
        giftCardBottom.style.width = giftCardInfo.offsetWidth + "px";
      }
    };
    setGiftCardBottomWidth();
    window.addEventListener("resize", setGiftCardBottomWidth);
    return () => window.removeEventListener("resize", setGiftCardBottomWidth);
  }, [giftCardBottomRef.current, giftCardInfoRef.current]);

  const cashCardReservation = useSelector(s => s.cashCard.cashCardReservation);
  const cashCardReservationLoading = useSelector(s => s.cashCard.cashCardReservationLoading);

  if (cashCardReservationLoading)
    return <div className="row">
      <div className="col-12 text-center">
        <Spinner />
      </div>
    </div>;

  return (
    <>
      <div
        className={`gift_card_info_wrapper movie_info_wrapper payment_details_new ${isMobile ? "sticky_movie_info" : ""}`}
        ref={giftCardInfoRef}>
        <article className="gift_card_info">
          <figure>
            <img src={
              cashCardReservation?.w_cash_card_image_url || giftCardImg
            } alt="websiteCashCard?.w_cash_card_image_url" />
            <h5 className="text_image" style={{ color: `${
                cashCardReservation?.cash_card_detail?.receipeint_detail?.websiteCashCard?.w_font_color || 'white'
            }`}}>
              {
                !!cashCardReservation?.cash_card_detail?.receipeint_detail?.name_on_card
                  ? cashCardReservation?.cash_card_detail?.receipeint_detail?.name_on_card
                  : '...'
              }
            </h5>
          </figure>
          <section className="">
            <h6>
              {t('Name or Text to be printed')}
            </h6>
            <p>{
              !!cashCardReservation?.cash_card_detail?.receipeint_detail?.name_on_card
                  ? cashCardReservation?.cash_card_detail?.receipeint_detail?.name_on_card
                  : '...'
            }</p>
          </section>
{/*
          <section>
            <h6>How to avail gift cards</h6>
            <ul className="pl-4">
              {Array(3).fill(1).map((item, index) => (
                <li key={index}>
                  Text goes here
                </li>)
              )}
            </ul>
          </section>
*/}

          {showCheckout &&
            <>
              <section className="text-white">
                <h6>{t("Checkout Summary")}</h6>
                <article className="payment_details_rows">
                  <section className="">
                    <p className="left_value">
                      <span className="ml-0">(x{cashCardReservation?.cash_card_detail?.quantity} {t("Cards")})</span>

                      <>
                        <p className="d-inline text-nowrap">
                          &nbsp;{t("Cash Card")}
                        </p>
                      </>
                      <span className="">{cashCardReservation?.w_cash_card_name}</span>
                    </p>

                    <p className="right_value">{curr_code}{" "}{currencyFormatter(+(cashCardReservation?.cash_card_detail?.amount || 0))}</p>
                  </section>

                  <section className="">
                    <p className="left_value">
                      <span className="ml-0">(x{cashCardReservation?.cash_card_detail?.quantity} {t("Cards")})</span>
                      <>
                        <p className="d-inline text-nowrap">
                          &nbsp;{t("Processing Fee")}
                        </p>
                      </>
                      <span className="">{''}</span>
                    </p>

                    <p className="right_value">{curr_code}{" "}{currencyFormatter(cashCardReservation?.w_professing_fee)}</p>
                  </section>

                </article>

              </section>
              {isMobile &&
                <article className="payment_details_rows payment_details_total">
                  <section className="">
                    <h5>{t("Grand Total")}

                    </h5>
                    <h4 className="">
                      {curr_code}{" "}{currencyFormatter(
                      (cashCardReservation?.cash_card_detail?.amount * cashCardReservation?.cash_card_detail?.quantity) +
                      (cashCardReservation?.w_professing_fee * cashCardReservation?.cash_card_detail?.quantity)
                    )}
                    </h4>

                  </section>
                  <h6>
                    {t("(Incl. of 2.5% VAT)")}
                  </h6>
                </article>}
            </>}

        </article>

        {(!isMobile && showCheckout) &&
          <>
            <article className="movie_info_scroll_space" />
            <article className="movie-bottom-cash" ref={giftCardBottomRef}>
              <section className="">
                <div className="cash_space">
                  <h5 className="cash_text" >{t("Grand Total")}

                  </h5>
                  <h4 className="cash_text">
                    {curr_code}{" "}{currencyFormatter(
                    (cashCardReservation?.cash_card_detail?.amount * cashCardReservation?.cash_card_detail?.quantity) +
                    (cashCardReservation?.w_professing_fee * cashCardReservation?.cash_card_detail?.quantity)
                  )}
                  </h4>
                </div>


                <h6 className="check_span" style={{marginTop:"6px"}}>
                  {t("(Incl. of 2.5% VAT)")}
                </h6>
              </section>

            </article>
          </>
        }
      </div>
    </>
  );
};

export default withNamespaces()(CashCardInfoPayment);
