import React, {useEffect, useState} from "react"
import {withNamespaces} from "react-i18next"
import {useParams} from "react-router"
import {useHistory} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import moment from 'moment';
import useIsMobile from "../../customHooks/useIsMobile";
// `/now-showing-single-show-booking/${show.ss_id}/${show.screen_id}/${show.md_id}/${show.movie_id}`
//Assets
import dummyPosterStatic from "@assets/pictures/dummyPoster.png";

// Components
import MovieInfo from "./helpers/MovieInfoOndemand";
import {Accordion, Card} from 'react-bootstrap';
import StepOne from "./helpers/StepOne";
import StepTwo from "./helpers/StepTwo";
import StepThree from "./helpers/StepThree";
import GoBack from "@components/partials/GoBack";
import Button from "@components/partials/Button";


//Actions
import {
  fetchMovieDetails,
  // fetchMovieSchedules,
  resetScreeningTypes,
  clearNewOndemandState,
  clearMovieSchedules,
  clearMovieDetails,
  setOndemandScreeningType,
  setOndemandShow, fetchConfirmedList, fetchOtherScreenings, checkCreateYourShowEnabled,
} from "../../store/movies/movieActions";
import {clearSeatSelection, releaseSeatBrij} from "../../store/seatLayout/seatLayoutActions";
import {clearFnbState} from "../../store/foodAndBeverages/foodAndBeveragesActions";
import {sortSeatsString} from "../../store/auth/authActions";
import Spinner from "../partials/Spinner";

//helpers

const BookingFlowContainer = ({t}) => {
  const history = useHistory()
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const {
    movie_id,
    content_lang_id,
    isPrivate,
    //
    md_id,
    screen_id,
    ss_id,
  } = useParams()

  const [activeAccordionTab, setActiveAccordionTab] = useState([false, true, false, false]);
  const [activeAccordionTabIndex, setActiveAccordionTabIndex] = useState("1");
  const [selectedCinema, setSelectedCinema] = useState(null);
  const [unavailableDateLoader, setUnavailableDateLoader] = useState(true);

  const category_id = 2;
  const movieDetails = useSelector((state) => state.movies.movieDetails)
  const movieDetailsLoading = useSelector((state) => state.movies.movieDetailsLoading)
  // const movieFormats = useSelector((state) => state.movies.movieFormats)
  // const movieLanguages = useSelector((state) => state.movies.movieLanguages)
  const selectedCinemaFromLocal = useSelector((state) => state.movies.selectedCinema)

  const loginDetails = useSelector((state) => state.auth);
  const {loggedInUser, userHistory, registerError, loginError} = loginDetails;
  let userSelection = useSelector(state => state.movies.userSelection)
  let seats = useSelector((state) => state.seatLayout.seats_selected)
  const seatString = seats.map((seat) => seat?.sl_seat_name)
  const scheduleDetails = useSelector((state) => state.movies.scheduleDetails);

  const handleAccordionTab = (index) => {
    let newActiveAccordionTab = [false, false, false, false];
    if (activeAccordionTab[index]) {
      setActiveAccordionTabIndex(null)
      newActiveAccordionTab[index] = false
    } else {
      setActiveAccordionTabIndex(String(index));
      newActiveAccordionTab[index] = true
    }
    setActiveAccordionTab(newActiveAccordionTab)
  }


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    if (!movieDetails || movieDetailsLoading) {
      // const spinner = document.getElementById("main-spinner");
      // if (spinner) {
      //   spinner.style.display = "flex";
      //   setTimeout(() => setCalendarPopup(false), 10);
      //   setTimeout(() => {
      //     spinner.style.display = "none";
      //   }, 2000);
      // }
    }
  }, [movieDetails, movieDetailsLoading])

  const reservation_id = useSelector((state) => state.seatLayout.reservation_id);
  useEffect(() => {
    if (reservation_id) {
      dispatch(releaseSeatBrij({reservation_id}));
    }
  }, []);

  useEffect(() => {
    setUnavailableDateLoader(true) // set false in call 2 of fetch sch
    dispatch(clearMovieSchedules())
    .then(() => {
      dispatch(clearMovieDetails())  // not async
      dispatch(clearNewOndemandState()) // not async
      return dispatch(clearSeatSelection())
    })
    .then(() => {
      return dispatch(clearFnbState())
    })
    .then(() => {
      return dispatch(fetchMovieDetails({
        movie_id,
        content_lang_id,
        cinema_id: selectedCinema?.cinema_id
          ? selectedCinema?.cinema_id
          : selectedCinemaFromLocal?.cinema_id
            ? selectedCinemaFromLocal?.cinema_id
            : null,
        cust_id: loggedInUser?.cust_id || null,
      }))
    })
    .then(() => {
      setUnavailableDateLoader(false)
      return
    })
    // dispatch(fetchOtherScreenings({ genre_id, movie_id }));

    return () => {
      dispatch(clearMovieSchedules())
      dispatch(clearMovieDetails())  // not async
      dispatch(clearNewOndemandState()) // not async
    }
  }, [content_lang_id, movie_id])

  useEffect(() => {
    if (
      !!scheduleDetails?.ss_start_date &&
      !!movie_id &&
      moment(scheduleDetails?.ss_start_date).isValid()
    ) {
      dispatch(
        checkCreateYourShowEnabled({
          date: moment(scheduleDetails?.ss_start_date).format("YYYY-MM-DD"),
          cinema_id: selectedCinemaFromLocal.cinema_id,
          movie_id,
        })
      );
    }
  }, [selectedCinemaFromLocal, scheduleDetails, movie_id]);


  console.log({scheduleDetails})

  if (movieDetailsLoading || unavailableDateLoader)
    return (<div className="row">
      <div className="col-12 text-center">
        <Spinner />
      </div>
    </div>)

  return (
    <>
      <div className="booking_flow_container">
        {isMobile ?
          <>
            <Accordion
              activeKey={activeAccordionTabIndex}
              className="booking_accordion">
              {/* STEP ONE ~ CHECK MOVIE DETAILS */}
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="1"
                                  onClick={() => {
                                    handleAccordionTab(1);
                                    // dispatch(setOndemandScreeningType())
                                    // dispatch(resetScreeningTypes())
                                    // dispatch(setOndemandShow())
                                  }}
                                  className={`${activeAccordionTab[0] ? "active" : ""}`}
                >
                  <article>
                    <h2>
                      {/* <span>1.</span> */}
                      {t('Movie Details')}
                    </h2>
                  </article>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <article className="booking_accordion_step">
                    <MovieInfo
                      isPrivate={false}
                      onClickNext={() => handleAccordionTab(2)}
                      fromSchedulePage={true}
                    />
                  </article>
                </Accordion.Collapse>
              </Card>

              {/* STEP TWO ~ SELECT DATE, LANGUAGE & TIME SLOTS */}
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="99"
                                  onClick={() => {
                                  }}
                                  className={`${activeAccordionTab[1] ? "active" : ""}`}
                >
                  <article>
                    {
                      <>
                        {!!scheduleDetails?.ss_start_date ? (
                          <p>
                            {moment(new Date(scheduleDetails?.ss_start_date)).format('MMM DD, YY')}{' '}
                          </p>
                        ) : (<h2>
                          <span>1.</span> {t('Select Date, Language & Time Slots')}
                        </h2>)
                        }
                        {!!userSelection?.selectedSession && (
                          <p>
                            {t(userSelection?.selectedSession.label)}{' '}
                          </p>
                        )}
                        {!!scheduleDetails?.ss_start_show_time && (
                          <p>
                            {scheduleDetails?.ss_start_show_time}
                          </p>
                        )}
                        {!!userSelection?.selectedLang && (
                          <button>
                            {!!(t(userSelection?.selectedLang?.lang_name)) ? t(userSelection?.selectedLang?.lang_name) : userSelection?.selectedLang.lang_name}
                          </button>
                        )}
                      </>
                    }
                  </article>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="99">
                  <article className="booking_accordion_step">
                  </article>
                </Accordion.Collapse>
              </Card>

              {/* STEP THREE ~ SELECT SEATS */}
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="2"
                                  onClick={() => {
                                    if (activeAccordionTab[2]) return
                                    handleAccordionTab(2);
                                  }}
                                  className={`${activeAccordionTab[2] ? "active" : ""}`}>
                  <article>
                    {seats.length === 0 ?
                      <>
                        <h2>
                          <span>2.</span> {t('Pick Seats')}
                        </h2>
                      </>
                      :
                      <>
                        {seats.length > 0 && (
                          <h6 className="text-success">
                            {seatString.sort(sortSeatsString).map((seat, index, arr) => (
                              <React.Fragment key={index}>
                                {seat} {index !== arr.length - 1 ? ',' : ''}
                              </React.Fragment>
                            ))}
                            {' '}{seats.length > 0 && `(${seats?.length})`}
                          </h6>)
                        }
                        {/* <span>(Edit)</span> */}
                      </>}
                  </article>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                  <article className="booking_accordion_step">
                    {/* Condition to give screen and icons proper width */}
                    {activeAccordionTabIndex === "2" && <StepThree isPrivate={false} isSingleShow/> }
                  </article>
                </Accordion.Collapse>
              </Card>
            </Accordion>


          </>
          :
          <section className="row">
            <article className="col-md-4 col-xl-3 pl-md-0" id="movie_info">
              <MovieInfo
                isPrivate={false}
                fromSchedulePage={true}
              />
            </article>

            <article className="col-md-8 col-xl-9 pr-md-0">
              <section className="inner_pages_header">
                <GoBack
                onClick={() => {
                  history.push(`/schedules`)
                }}
                />
                <div className="custom_breadcrumbs">
                  <div className="steps_new">
                    <span className="px-3 bor_new">{t("Now Showing")}</span>

                    <span className="px-3 bor_new1">{t('Food & Beverages')}</span>
                    <span className="px-3 bor_new1">{t("Checkout")}</span>
                  </div>

                  {/*<span onClick={()=>history.push("/now-showing")}>
                  Now Showing</span> <span>
                  Now Showing Booking</span> */}
                </div>
                <div/>
              </section>


              <Accordion
                activeKey={activeAccordionTabIndex}
                className="booking_accordion"
              >
                {/* STEP ONE ~ SELECT DATE, LANGUAGE & TIME SLOTS */}
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey={activeAccordionTab[0]}
                                    onClick={() => {
                                    }}
                                    className={`${activeAccordionTab[0] ? "active" : ""}`}
                  >
                    <article>
                      <h2>
                        <span>1.</span> {t('Select Date, Language & Time Slots')}
                      </h2>
                      {(+activeAccordionTabIndex !== 0 || activeAccordionTabIndex == null) && (<>
                        {scheduleDetails?.ss_start_date && (
                          <p>
                            {moment(new Date(scheduleDetails?.ss_start_date)).format('MMM DD, YY')}{' '}
                          </p>
                        )}
                        {!!userSelection?.selectedSession && (
                          <p>
                            {t(userSelection?.selectedSession.label)}{' '}
                          </p>
                        )}
                        {!!scheduleDetails?.ss_start_show_time && (
                          <p>
                            {scheduleDetails?.ss_start_show_time}
                          </p>
                        )}
                        {!!userSelection?.selectedLang && (
                          <button>
                            {!!(t(userSelection?.selectedLang?.lang_name)) ? t(userSelection?.selectedLang?.lang_name) : userSelection?.selectedLang.lang_name}
                          </button>
                        )}
                        {!!userSelection?.selectedShow && (
                          <span>({t('Edit')})</span>
                        )}
                      </>)}
                    </article>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">

                    <article className="booking_accordion_step">
                    </article>
                  </Accordion.Collapse>
                </Card>

                {/* STEP TWO ~ SELECT SEATS */}
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="1"
                                    onClick={() => {
                                      if (
                                        // (activeAccordionTab[0] || activeAccordionTab[1]) &&
                                        !userSelection.selectedScreeningType ||
                                        (userSelection?.selectedScreeningType?.booking_type_id == 3)
                                      ) return

                                      if (activeAccordionTab[1]) return

                                      handleAccordionTab(1);
                                    }}
                                    className={`${activeAccordionTab[1] ? "active" : ""}`}>
                    <article>
                      <h2>
                        <span>2.</span> {
                        seats.length > 0
                          ? t('Selected Seats')
                          : t('Pick Seats')
                      }
                      </h2>
                      {seats.length > 0 && (
                        <h6 className="text-success">
                          {seatString.sort(sortSeatsString).map((seat, index, arr) => (
                            <React.Fragment key={index}>
                              {seat} {index !== arr.length - 1 ? ',' : ''}
                            </React.Fragment>
                          ))}
                          {' '}{seats.length > 0 && `(${seats?.length})`}
                        </h6>)
                      }
                      {/*{seats.length > 0 && (<span>({t('Edit')})</span>)}*/}
                    </article>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1">
                    <article className="booking_accordion_step">
                      {/* Condition to give screen and icons proper width */}
                      {activeAccordionTabIndex === "1" && <StepThree isPrivate={false} isSingleShow/>}
                    </article>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </article>
          </section>
        }
      </div>
    </>
  );
}

export default withNamespaces()(BookingFlowContainer);
