import React, { useState } from "react";
import { withNamespaces } from "react-i18next";

// Components
import CenteredTitle from "@components/partials/CenteredTitle";
import BreadCrumb from "@components/partials/BreadCrumbs";
import Slider from "react-slick";

// Images
import imageOne from "@assets/pictures/bar-offers-1.png";
import imageTwo from "@assets/pictures/bar-offers-2.png";

const BarOffers = (props) => {
  const { t, classes = "" } = props;
  const [swiping, setSwiping] = useState(false);

  const handleSwipe = () => {
    setSwiping(true);
  };

  const handleClick = () => {
    if (swiping) {
      setSwiping(false);
    } else {
      // Click actions here
    }
  };

  // Slider Settings
  const heroSlider = {
    pauseOnFocus: true,
    onSwipe: handleSwipe,
    arrows: true,
    dots: false,
    infinite: true,
    useTransform: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <>
      <section className={`about_us_bar_offers ${classes} static_page align_new`}>
      <div className="left_right_align">
        <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t("Bar Offers")} />
        <CenteredTitle firstHalf={t("Bar Offers")} secondHalf="" />
        </div>
        
        <div className="row">
          <article className="col-xl-12 mx-auto event_back">

            <div className="row">
              <article className="col-md-6 mb-4 mb-md-0">
                <p className="mb-4 mb-xl-5">
                  {t("For our friendly non-local visitors, Nearby cozy cinema bar, Stüssihof Bar, with simple food, vegan too, has seasonal offers")}
                </p>
                <p>
                  {t("Come by our new modern cinema bar and let us surprise you.")}
                </p>
                <p className="mb-4 mb-xl-5">
                  {t("If you have any special requests, please contact us via the contact form.")}
                </p>
                {/*<p className="static_page_highlight mb-3 mb-xl-4">
                  {t("Our house specialty is the hot dog")}
                </p>
                <p>
                  {t("The best thing at the end, the hot dog is also incredibly cheap despite all the fine ingredients")}: <span className="text-primary"> CHF 9.50 </span>&nbsp;!&nbsp;
                </p>
                <p className="mb-4 mb-xl-5">
                  {t("Good to know! Our hot dog, adapts to the seasons, the seasonal offer")}:
                </p>

                <p className="mb-4 mb-xl-5">
                  {t("For our friendly non-local visitors, Nearby cozy cinema bar, Stüssihof Bar, with simple food, vegan too, has seasonal offers")}:
                </p>
                {Array(2).fill(0).map((item, index) => (
                  <p className="text-primary static_page_highlight" key={index}>
                    Offer {index + 1}
                  </p>
                ))}
                <p className="mt-4 mt-xl-5">
                  {t("Events.anyQuery")} info@stuessihof.ch
                </p>*/}
              </article>
              <article className="col-md-6">
                {/* <Slider {...heroSlider}>
                            {Array(4).fill(0).map((item, index) => (
                                <article key={index}> */}
                <figure onClick={handleClick}>
                  <div>
                    <img src={imageOne} alt="" />
                    <h6>Champus</h6>
                    {/*<p>{t("View Menu")}</p>*/}
                  </div>
                  <div>
                    <img src={imageTwo} alt="" />
                    <h6>Bloody Mary</h6>
                    {/*<p>{t("View Menu")}</p>*/}
                  </div>
                </figure>
                {/* </article>
                            ))}
                        </Slider> */}
              </article>
            </div>
          </article>

        </div>
      </section>
    </>);
};


export default withNamespaces()(BarOffers);
