import React from "react";
import { withNamespaces } from "react-i18next";

const InlineScroll = (props) => {
    const {t, classes, children} = props;
    return (
        <>
            <div className={`inline_scroll ${classes}`}>
                <section>
                    {children}
                </section>
            </div>
        </>
     );
}

// Default Props
InlineScroll.defaultProps = {
    classes: "",
}
 
export default withNamespaces()(InlineScroll);