import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router";

import useIsMobile from "../../customHooks/useIsMobile";

// Components
import { Card, Accordion } from "react-bootstrap";
import GiftCardInfo from "./helper/CashCardInfoPayment";
import CashCardTopupCardInfo from "./helper/CashCardInfoTopupCard";
import TopupStepOne from "./helper/TopupStepOne";
import TopupStepTwo from "./helper/TopupStepTwo";
import GoBack from "@components/partials/GoBack";
import {
  fetchCashCardDetailsFromCardNumber,
  fetchCashCardDetailsFromCardNumberPin
} from "../../store/cashCard/cashCardActions";
import { useDispatch, useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  fetchActivePaymentModes,
  fetchReservationDetails,
  fetchReservationDetailsBrij,
  fetchStripePublisherKey
} from "../../store/movies/movieActions";
import { setReservationID } from "../../store/seatLayout/seatLayoutActions";
import giftCardImg from "@assets/pictures/gift-card.png";

const CashCardTopupComponent = ({ t }) => {
  const isMobile = useIsMobile();
  const history = useHistory();
  let location = useLocation()
  const dispatch = useDispatch();
  const { cash_card_number, topup_id } = useParams();
  const { state } = location

  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;

  // const [loading, setLoading] = useState(true);
  const [activeAccordionTab, setActiveAccordionTab] = useState([true, false, false, false]);
  const [activeAccordionTabIndex, setActiveAccordionTabIndex] = useState("0");

  const cashCardDetails = useSelector((state) => state.cashCard.cashCardDetails);
  const cashCardDetailsLoading = useSelector((state) => state.cashCard.cashCardDetailsLoading);
  const cashCardTopUpSelection = useSelector((state) => state.cashCard.cashCardTopUpSelection);
  const cashCardTopUpSelectionPin = useSelector((state) => state.cashCard.cashCardTopUpSelectionPin);

  const handleAccordionTab = (index) => {
    let newActiveAccordionTab = [false, false, false, false];
    if (activeAccordionTab[index]) {
      setActiveAccordionTabIndex(null);
      newActiveAccordionTab[index] = false;
    } else {
      setActiveAccordionTabIndex(String(index));
      newActiveAccordionTab[index] = true;
    }
    setActiveAccordionTab(newActiveAccordionTab);
  };

  useEffect(() => {
    if (!state.cash_pin) {
      history.push(`/cash-cards/4`)
    }
  }, [state]);

  useEffect(() => {
    if (!!cash_card_number) {
      dispatch(fetchCashCardDetailsFromCardNumber({ cash_card_number: cash_card_number }))
      .then(({ status, message }) => {
        // console.log({ status, message });
      })
      .catch(console.log);
      // .finally(() => { setLoading(false) });
    }
  }, [cash_card_number]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchStripePublisherKey());
    dispatch(fetchActivePaymentModes(2));
  }, []);

  const [firstCashCard, setFirstCashCard] = useState({});
  useEffect(() => {
    if (cashCardDetails && cashCardDetails?.length) setFirstCashCard(cashCardDetails[0]);
  }, [cashCardDetails]);

  return (<>
    <div className="booking_flow_container">
      {isMobile ? // MOBILE DESIGN
        <>
          <Accordion
            activeKey={activeAccordionTabIndex}
            className="booking_accordion">
            {/* STEP ONE ~ GIFT CARD DETAILS */}
            <Card>
              <Accordion.Toggle
                as={Card.Header} eventKey="1"
                onClick={() => {
                  handleAccordionTab(1);
                }}
                className={`${activeAccordionTab[0] ? "active" : ""}`}
              >
                <article>
                  <h2>
                    {/* <span>1.</span> */}
                    {t("Cash Card")} {t("Details")}
                  </h2>
                </article>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <article className="booking_accordion_step">
                  <CashCardTopupCardInfo onClickNext={() => handleAccordionTab(1)} />
                </article>
              </Accordion.Collapse>
            </Card>

            {/* STEP TWO ~ SELECT A VALUE */}
            <Card>
              <Accordion.Toggle
                as={Card.Header} eventKey="1"
                onClick={() => {
                  handleAccordionTab(1);
                }}
                className={`${activeAccordionTab[1] ? "active" : ""}`}
              >
                <article>
                  <><h2>
                    <span>1.</span> {t("Select a value")}
                  </h2>
                  </>
                  {(+activeAccordionTabIndex !== 1 || activeAccordionTabIndex == null) && (<>
                    <p>| {curr_code} {cashCardTopUpSelection?.amount}</p>
                  </>)}
                </article>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <article className="booking_accordion_step">
                  <TopupStepOne
                    onClickNext={() => handleAccordionTab(2)}
                  />
                </article>
              </Accordion.Collapse>
            </Card>

            {/* STEP THREE ~ ENTER RECIPIENT DETAILS */}
            <Card>
              <Accordion.Toggle
                as={Card.Header} eventKey="2"
                onClick={() => {
                  handleAccordionTab(2);
                }}
                className={`${activeAccordionTab[2] ? "active" : ""}`}>
                <article>
                  <>
                    <h2>
                      <span>2.</span> {t("Checkout")}
                    </h2></>
                </article>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <article className="booking_accordion_step">
                  <TopupStepTwo onClickNext={() => handleAccordionTab(3)} isPrivate={true} />
                </article>
              </Accordion.Collapse>
            </Card>

            {/* STEP THREE ~ Confirmation */}
            <Card>
              <Accordion.Toggle
                as={Card.Header} eventKey="3"
                onClick={(e) => {
                  // handleAccordionTab(3);
                  e.preventDefault();
                  e.stopPropagation();
                }}
                className={`${activeAccordionTab[3] ? "active" : ""}`}>
                <article>
                  <>
                    <h2>
                      <span>3.</span> {t("Confirmation")}
                    </h2></>
                </article>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <article className="booking_accordion_step">
                  {/*<TopupStepTwo onClickNext={() => handleAccordionTab(3)} isPrivate={true} />*/}

                  <div className="col-md-10 col-lg-9 col-xl-7 mx-auto text-center payment-success">
                    <div className="">
                      <img
                        src={firstCashCard?.cash_card_image || giftCardImg}
                        alt="Gift Card Img"
                        className=""
                      />
                    </div>
                    <div className="pt-2">
                      {/* <h2 className="font-weight-bold">
                             {t("Enjoy your movie!")}
                          </h2> */}
                      <p className="">
                        {/* {t("Your transaction was successfully processed, check your email for your tickets")} */}
                        {t("CashCard.SuccessMsg")}
                      </p>
                    </div>
                    <div className="mb-0">
                      <button
                        className="btn-main btn-main-lg false false mx-auto"
                        onClick={() => {
                          history.push(`/cash-cards`);
                        }}
                      >
                        {t("Close")}
                      </button>
                    </div>
                  </div>
                </article>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </>
        : // DESKTOP/TABLET DESIGN
        <section className="row">
          <article className="col-md-4 col-xl-3 pl-md-0" id="movie_info">
            <CashCardTopupCardInfo
              isPrivate={true}
            />
          </article>

          <article className="col-md-8 col-xl-9 pr-md-0">
            <section className="inner_pages_header">
              <GoBack
                onClick={() => {
                  if ((+activeAccordionTabIndex) === 0) history.go(-1); else if ((+activeAccordionTabIndex) > 0) {
                    handleAccordionTab(+activeAccordionTabIndex - 1);
                  }
                }}
              />
              <div className="custom_breadcrumbs">
                <span onClick={() => history.push("/cash-cards")}>
                  {t("Cash Cards")?.toUpperCase()}
                </span>
                <span>
                  {t("Cash Cards")?.toUpperCase()} {t("Top-up")?.toUpperCase()}
                </span>
              </div>
              <div />
            </section>

            <Accordion
              activeKey={activeAccordionTabIndex}
              className="booking_accordion"
            >
              {/* STEP ONE ~ SELECT A VALUE */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header} eventKey={"0"}
                  onClick={() => {
                    handleAccordionTab(0);
                  }}
                  className={`${activeAccordionTab[0] ? "active" : ""}`}
                >
                  <article>
                    <h2>
                      <span>1.</span> {t("Select a value")}
                    </h2>
                    {(+activeAccordionTabIndex !== 0 || activeAccordionTabIndex == null) && (<>
                      <p>| {curr_code} {cashCardTopUpSelection?.amount}</p>
                    </>)}
                  </article>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">

                  <article className="booking_accordion_step">
                    <TopupStepOne
                      onClickNext={() => handleAccordionTab(1)}
                    />
                  </article>
                </Accordion.Collapse>
              </Card>

              {/* STEP TWO ~ ENTER RECIPIENT DETAILS */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header} eventKey="1"
                  onClick={() => {
                    handleAccordionTab(1);
                  }}
                  className={`${activeAccordionTab[1] ? "active" : ""}`}>
                  <article>
                    <h2>
                      <span>2.</span> {t("Checkout")}
                    </h2>
                  </article>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey="1">
                  <article className="booking_accordion_step">
                    <TopupStepTwo
                      onClickNext={() => {
                        handleAccordionTab(3);
                      }}
                    />
                  </article>
                </Accordion.Collapse>
              </Card>

              {/* STEP THREE ~ Confirmation */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header} eventKey="3"
                  onClick={(e) => {
                    // handleAccordionTab(3);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  className={`${activeAccordionTab[3] ? "active" : ""}`}>
                  <article>
                    <>
                      <h2>
                        <span>3.</span> {t("Confirmation")}
                      </h2></>
                  </article>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="3">
                  <article className="booking_accordion_step">
                    {/*<TopupStepTwo onClickNext={() => handleAccordionTab(3)} isPrivate={true} />*/}

                    <div className="col-md-10 col-lg-9 col-xl-7 mx-auto text-center payment-success">
                      <div className="">
                        <img
                          src={firstCashCard?.cash_card_image || giftCardImg}
                          alt="Gift Card Img"
                          className=""
                        />
                      </div>
                      <div className="pt-2">
                        {/* <h2 className="font-weight-bold">
                             {t("Enjoy your movie!")}
                          </h2> */}
                        <p className="">
                          {/* {t("Your transaction was successfully processed, check your email for your tickets")} */}
                          {t("CashCard.SuccessMsg")}
                        </p>
                      </div>
                      <div className="pt-2 mb-0">
                        <button
                          className="btn-main btn-main-lg false false mx-auto"
                          onClick={() => {
                            history.push(`/cash-cards`);
                          }}
                        >
                          {t("Close")}
                        </button>
                      </div>
                    </div>
                  </article>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </article>
        </section>
      }
    </div>
  </>);
};

const CashCardTopup = ({ t }) => {
  const stripePublisherKeyFromStore = useSelector((state) => state.movies.stripePublisherKey);
  const stripePublisherKey =
    localStorage.getItem("stripe_publisher_key") || stripePublisherKeyFromStore;
  // const stripePromise = loadStripe(stripePublisherKey);
  const [stripePromise] = useState(() => loadStripe(stripePublisherKey));
  return (
    <Elements stripe={stripePromise}>
      <CashCardTopupComponent t={t} />
    </Elements>
  );
};

export default withNamespaces()(CashCardTopup);
