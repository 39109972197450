import React, {useEffect, useRef, useState} from "react";
import {withNamespaces} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

// Components
import BookingScreening from "./BookingScreening";
import Spinner from "../../partials/Spinner";
import SpecialInstructionsPopup from "../../partials/popups/SpecialInstructionsPopup";
import SpecialInstructionsPopupRegular from "../../partials/popups/SpecialInstructionsPopupRegular";
import {privateScreeningReserveSeat} from "../../../store/seatLayout/seatLayoutActions";


const StepTwo = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const {t, classes, onClickNext} = props;
  const {
    genre_id,
    movie_id,
    content_lang_id,
    isPrivate,
  } = useParams();

  let userSelection = useSelector(state => state.movies.userSelection)
  const scheduleDetails = useSelector((state) => state.movies.scheduleDetails);
  const screeningTypesLoading = useSelector((state) => state.movies.screeningTypesLoading);
  const scheduleDetailsLoading = useSelector((state) => state.movies.scheduleDetailsLoading);

  const screeningTypes = useSelector((state) => state.movies.screeningTypes)
  const screen = screeningTypes.find((screen) => screen.booking_type_id === 3);
  let isPrivateScreeninExist = screen?.isPrivateScreeninExist === "Y" ?? false;
  const [showPrivateScreenPopup, setShowPrivateScreenPopup] = useState(false);
  const isPrivateShowClicked = useRef(false);

  const onConfirmPrivateScreeningPopup = (specialRequest) => {
    // dispatch(setPrivateScreeningMessage(specialRequest));
    if (isPrivateShowClicked.current === false) {
      isPrivateShowClicked.current = true;
      console.log("private show reserved");
      dispatch(
        privateScreeningReserveSeat({
          screen_id: userSelection?.selectedShow?.screen_id,
          history,
          mycinema_ss_d_id: scheduleDetails?.mycinema_ss_d_id,
          category_id: 1,
          cinema_id: userSelection?.selectedShow?.cinema_id,
          movie_id,
          content_lang_id,
          genre_id,
          isPrivate,
          specialRequest,
        })
      );
    }
  };

  const [sortedScreeningTypes, setSortedScreeningTypes] = useState(screeningTypes ?? [])
  useEffect(() => {
    if (screeningTypes.length) {
      let [confirmed, crowdsource, privt] = screeningTypes.sort((a,b) => a.booking_type_id - b.booking_type_id)
      setSortedScreeningTypes([privt, confirmed, crowdsource])
    }
  }, [screeningTypes])

  if (screeningTypesLoading) return (<div className="row">
    <div className="col-12 text-center">
      <Spinner/>
    </div>
  </div>)

  return (
    <>
      <section className={`row booking_accordion_step_two ${classes}`}>
        {isPrivateScreeninExist ? (
          <SpecialInstructionsPopup
            showPrivateScreenPopup={showPrivateScreenPopup}
            onConfirmPrivateScreeningPopup={onConfirmPrivateScreeningPopup}
            closeFunc={() => {
              setShowPrivateScreenPopup(false);
            }}
          />
        ) : (
          <SpecialInstructionsPopupRegular
            showPrivateScreenPopup={showPrivateScreenPopup}
            onConfirmPrivateScreeningPopup={onConfirmPrivateScreeningPopup}
            closeFunc={() => {
              setShowPrivateScreenPopup(false);
            }}
          />
        )}

        {sortedScreeningTypes
        .filter(s => s.isAvailable).length === 0 && (
          <div className="col-12 text-center">
            <p className="no-result">
              {t("sorry-on-this-date-private-ondemand-not-available")}
            </p>
          </div>
        )}
        {sortedScreeningTypes.length > 0 && (
          sortedScreeningTypes
          .filter(s => s.isAvailable)
          .map((screeningType, index) => (
            <div className="col-md-6 col-lg-4" key={index}>
              <BookingScreening
                type={screeningType.booking_type_id === 3 ? "private" : screeningType.booking_type_id === 1 ? "confirmed" : ""}
                screeningType={screeningType}
                onClickNext={onClickNext}
                setShowPrivateScreenPopup={setShowPrivateScreenPopup}
              />
            </div>
          ))
        )}
        {/*{!(screeningTypesLoading && scheduleDetailsLoading) &&screeningTypes.length === 0 && (
          <div className="row">
            <div className="col-12 text-center">
              <p className="no-result">
                {t("sorry-on-this-date-private-ondemand-not-available")}
              </p>
            </div>
          </div>
        )}*/}
      </section>
    </>
  );
}

// Default Props
StepTwo.defaultProps = {
  classes: "",
  onClickNext: () => {
  }
}

export default withNamespaces()(StepTwo);
