import React from "react";
import { withNamespaces } from "react-i18next";

// Components
import CountryCodeDropdown from "@components/partials/CountryCodeDropdown";


const CustomInput = (props) => {
  const {
    t,
    classes = "",
    onChange,
    value,
    type,
    textarea,
    autoComplete,
    autoFocus,
    label,
    subLabel,
    errorStatus,
    errorMsg,
    placeholder,
    countryCode,
    onSelectCountry = () => {},
  } = props;
    return (
        <div className={`custom_input ${classes}`}>
            <label>
                <p>
                    {label}
                    {subLabel && <span>({subLabel})</span>}
                </p>
                {textarea
                  ? <textarea rows="5" value={value} onChange={onChange} placeholder={placeholder} />
                  :
                    <>
                        <div className="d-flex">
                         {countryCode && <CountryCodeDropdown className="mr-2" onSelectCountry={onSelectCountry} />}
                            <input type={type} autoComplete={autoComplete} autoFocus={autoFocus} value={value} onChange={onChange} placeholder={placeholder} />
                        </div>
                    </>}
            </label>
           {errorStatus && <p className="error">{errorMsg}</p>}
        </div>
     );
}

export default withNamespaces()(CustomInput);
