import React, {useRef, useState} from "react";
import {withNamespaces} from "react-i18next";

// Images
// import { ReactComponent as StandardSeat } from "@assets/pictures/svgs/seat-standard.svg";
// import { ReactComponent as SelectedSeat} from "@assets/pictures/svgs/seat-standard-selected.svg";
// import { ReactComponent as UnavailableSeat } from "@assets/pictures/svgs/seat-standard-unavailable.svg";
// import { ReactComponent as Wheelchair } from "@assets/pictures/svgs/seat-wheel-chair.svg";
// import { ReactComponent as SelectedWheelChair} from "@assets/pictures/svgs/seat-wheel-chair-selected.svg";
// import { ReactComponent as UnavailableWheelChair} from "@assets/pictures/svgs/seat-wheel-chair-unavailable.svg";
// import {ReactComponent as LoungerSeat} from "@assets/pictures/svgs/seat-lounger.svg";
// import {ReactComponent as SelectedLoungerSeat} from "@assets/pictures/svgs/seat-lounger-selected.svg";
// import {ReactComponent as UnavailableLoungerSeat} from "@assets/pictures/svgs/seat-lounger-unavailable.svg";
import {ReactComponent as Seat} from "@assets/pictures/svgs/seat_new.svg";


const SeatCell = (props) => {
  const {t, classes, char, disabled, onClick, isPrivate} = props;
  const seatCellRef = useRef();
  const [isSelected, setIsSelected] = useState(isPrivate);

  return (
    <div
      className={`seat_cell ${classes} ${disabled ? "disabled" : isSelected ? "selected" : ""}`}
      onClick={() => {
        if (isPrivate) return

        onClick(seatCellRef);
        setIsSelected(!isSelected);
      }}
      ref={seatCellRef}
    >
      {char ? <span>{char}</span> : <Seat/>}
    </div>
  )
}

// Default Props
SeatCell.defaultProps = {
  char: false,
  disabled: false,
  onClick: () => {
  },
  classes: ""
}

export default withNamespaces()(SeatCell);
