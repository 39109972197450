import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import RangeSlider from 'react-bootstrap-range-slider';
// Components
import Button from "@components/partials/Button";

// Images
import { ReactComponent as IncreaseIcon } from "@assets/pictures/svgs/gift-card-increase.svg";
import { ReactComponent as DecreaseIcon } from "@assets/pictures/svgs/gift-card-decrease.svg";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../partials/Spinner";
import { SET_CASHCARD_BUY_AMOUNT, SET_CASHCARD_BUY_QUANTITY } from "../../../store/cashCard/cashCardTypes";

const CashCardStepOne = (props) => {
  const dispatch = useDispatch();
  const {
    t, classes = "", onClickNext = () => {
    }
  } = props;

  const [loading, setLoading] = useState(true);
  const websiteCashCard = useSelector(s => s.cashCard.websiteCashCard) ?? [];
  const websiteCashCardLoading = useSelector(s => s.cashCard.websiteCashCardLoading) ?? false;
  const cashCardBuySelection = useSelector((state) => state.cashCard.cashCardBuySelection);

  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;

  useEffect(() => {
    setLoading(websiteCashCardLoading);
  }, [websiteCashCardLoading]);

  useEffect(() => {
    if (
      websiteCashCard?.w_minimum_topup >= 0
    ) {
      dispatch({
        type: SET_CASHCARD_BUY_AMOUNT,
        payload: (websiteCashCard?.w_minimum_topup ?? 0)
      });
    }
  }, [websiteCashCard]);

  if (loading || websiteCashCardLoading)
    return <div className="row">
      <div className="col-12 text-center">
        <Spinner />
      </div>
    </div>;

  let rangeStart = (websiteCashCard?.w_minimum_topup ?? 0);
  let rangeEnd = rangeStart + 250;
  let rangeStep = 5;
  let topUpValues = [rangeStart + 20, rangeStart + 60, rangeStart + 100, rangeStart + 200, rangeEnd].map(v => ({ amount: v }));

  return (
    <>
      <div className={`gift_card_step_one ${classes}`}>
        <section className="row align-items-center">
          <article className="col-xl-6 mb-4">
            <section className="gift_card_value_btn_group">
              {topUpValues.map((step, index) => (
                <div
                  key={index}
                  className={
                    `gift_card_value_btn ${step.amount == cashCardBuySelection?.amount ? "active" : ""}`
                  }
                  onClick={(e) => {
                    dispatch({ type: SET_CASHCARD_BUY_AMOUNT, payload: step.amount });
                  }}
                >
                  {step.amount}{".00"}
                </div>
              ))}
            </section>

          </article>

          <article className="col-xl-6 mb-4">

            <label className="custom_input_range">
              <span>{curr_code} {rangeStart}.00</span>
              {/*<input
                type="range"
                value={cashCardBuySelection?.amount ?? 20}
                min={rangeStart}
                max={rangeEnd}
                step={rangeStep}
                onChange={(e) => {
                  console.log(e?.target?.value);
                  dispatch({ type: SET_CASHCARD_BUY_AMOUNT, payload: e?.target?.value ?? 20 });
                }}
              />*/}
              <div style={{ width: '100%', }}>
                <RangeSlider
                  tooltipPlacement="top"
                  tooltip="auto"
                  min={rangeStart}
                  max={rangeEnd}
                  step={rangeStep}
                  value={cashCardBuySelection?.amount}
                  onChange={e => {
                    dispatch({ type: SET_CASHCARD_BUY_AMOUNT, payload: e?.target?.value ?? 20 })
                  }}
                />
              </div>
              <span>{curr_code} {rangeEnd}.00</span>
            </label>
          </article>
          <article className="col-xl-6 mb-4 mb-xl-0">
            <div className="gift_card_input_group">
              <label>
                <p>{t("Amount")}</p>
                <input
                  readOnly
                  type="text"
                  value={`${curr_code} ${(cashCardBuySelection?.amount)}.00`}
                />
              </label>
              <label>
                <p>{t("Quantity")}</p>
                <article className="gift_card_input_modifier">
                  <DecreaseIcon
                    onClick={() => {
                      dispatch({
                        type: SET_CASHCARD_BUY_QUANTITY,
                        payload: cashCardBuySelection?.quantity - 1
                      });
                    }} />
                  <input
                    readOnly
                    value={cashCardBuySelection?.quantity}
                    type="number"
                  />
                  <IncreaseIcon
                    onClick={() => {
                      dispatch({
                        type: SET_CASHCARD_BUY_QUANTITY,
                        payload: cashCardBuySelection?.quantity + 1
                      });
                    }} />
                </article>
              </label>
            </div>
          </article>
          <article className="col-xl-6">
            <Button text="Next" size="lg" classes={`mx-auto mx-xl-0`} onClick={onClickNext} />
          </article>
        </section>
      </div>

    </>
  );
};

export default withNamespaces()(CashCardStepOne);
