import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import useIsMobile from "../../customHooks/useIsMobile";
import moment from "moment";
import { getLangSpecificAttribute } from "@helper/languages";
import { useSelector, useDispatch } from "react-redux";

// Components
import Slider from "react-slick";

// Images
import banner from "@assets/pictures/festival_banner.png";
import miniFestArtWork from "@assets/pictures/mini_fest_sample.png";

const MiniFestivalsBanner = (props) => {
    const { t, festival } = props;
    const isMobile = useIsMobile();
    const history = useHistory();
    const selected_language = useSelector(
        (state) => state.movies.selected_language
    );
    const { iso_2, lang_id, lang_name } = selected_language;

    // STATE
    const [swiping, setSwiping] = useState(false);

    // Slider Settings
    const heroSlider = {
        pauseOnFocus: true,
        onSwipe: () => setSwiping(true),
        arrows: false,
        dots: true,
        infinite: true,
        useTransform: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const getFestivalLanguagesArray = (movies) => {
        let tempArray = [];
        let MoviesLanguage = movies?.map((x) => {
            if (!tempArray.includes(x.lang_name) && x.lang_name) {
                tempArray.push(x.lang_name);
            }
        })
        return tempArray;
    }


    const handleBannerClick = () => {
        if (swiping) {
            setSwiping(false);
        } else {
            // Actual Click Actions
            history.push("/booking-flow")
        }
    }
    return (
        <>
            <Slider {...heroSlider} className="mini_festivals_banner">
                {festival?.length > 0 && festival?.map((item, index) => (
                    <div className="" key={index}>
                        <section className="hero-section" onClick={handleBannerClick}>
                            <article className="hero-img-section">
                                <img
                                    src={item?.category_link_image}
                                    alt="Movie Banner"
                                    className="hero-img-1"
                                />
                            </article>
                            <article className="hero-text align-items-end">
                                <div className="container-fluid">
                                    <figure className="mini_festivals_banner_artwork">
                                        <img
                                            src={item?.category_link_image}
                                            alt="Movie Banner"
                                            class="img-aspect"
                                        />
                                    </figure>
                                    <h6 className="mini_festivals_banner_duration">{moment(item?.from_date).format("Do MMM")} -{" "}
                                        {moment(item?.to_date).format("Do MMM")}</h6>
                                    {!isMobile && <div className="mini_festivals_banner_languages">
                                        {getFestivalLanguagesArray(item?.movie_data?.data)?.map((item, index) => (
                                            <article key={index}>{item}</article>
                                        ))
                                        }
                                    </div>}
                                    <hr />
                                    <p className="mini_festivals_banner_description">
                                        {getLangSpecificAttribute(
                                            item?.language_content,
                                            lang_id,
                                            "language_content",
                                            "lang_id"
                                        ) || item?.event_description}
                                    </p>
                                </div>
                            </article>
                        </section>
                    </div>
                ))}
            </Slider>
        </>
    );
}

export default withNamespaces()(MiniFestivalsBanner);
