import {
  FETCH_CASH_CARD_DETAILS,
  FETCH_CASH_CARD_DETAILS_SUCCESS,
  FETCH_CASH_CARD_DETAILS_ERROR,
  SET_CASHCARD_TOPUP_AMOUNT,
  SET_CASHCARD_BUY_AMOUNT,
  SET_CASHCARD_NAME_TO_PRINT,
  SET_CASHCARD_BUY_QUANTITY,
  SET_CASHCARD_REDEMPTION_AMOUNT,
  FETCH_CASH_CARD_GALLERY,
  FETCH_CASH_CARD_GALLERY_SUCCESS,
  FETCH_CASH_CARD_GALLERY_ERROR,
  FETCH_WEBSITE_CASH_CARD,
  FETCH_WEBSITE_CASH_CARD_SUCCESS,
  FETCH_WEBSITE_CASH_CARD_ERROR,
  FETCH_CASH_CARD_RESERVATION,
  FETCH_CASH_CARD_RESERVATION_SUCCESS,
  FETCH_CASH_CARD_RESERVATION_ERROR,
  FETCH_CASH_CARD_BUY_TRX,
  FETCH_CASH_CARD_BUY_TRX_SUCCESS,
  FETCH_CASH_CARD_BUY_TRX_ERROR,
} from "./cashCardTypes";

let initialState = {
  cashCardDetails: [],
  cashCardDetailsLoading: false,
  cashCardDetailsError: false,
  cashCardTopUpSelection: {
    amount: 1
  },
  cashCardBuySelection: {
    amount: null,
    quantity: 1,
    nameToPrint: "",
  },
  cashCardRedemptionSelection: {
    amount: 0
  },
  cashCardGallery: [],
  cashCardGalleryLoading: false,
  cashCardGalleryError: false,

  websiteCashCard: null,
  websiteCashCardLoading: false,
  websiteCashCardError: false,

  cashCardReservation: null,
  cashCardReservationLoading: false,
  cashCardReservationError: null,

  cashCardBuyTrx: null,
  cashCardBuyTrxLoading: false,
  cashCardBuyTrxError: null,
};

const cashCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CASH_CARD_DETAILS:
      return {
        ...state,
        cashCardDetails: [],
        cashCardDetailsLoading: true,
        cashCardDetailsError: false,
        cashCardTopUpSelection: initialState.cashCardTopUpSelection
      };
    case FETCH_CASH_CARD_DETAILS_SUCCESS:
      return {
        ...state,
        cashCardDetails: action.payload,
        cashCardDetailsLoading: false,
        cashCardDetailsError: false,
        cashCardTopUpSelection: initialState.cashCardTopUpSelection
      };
    case FETCH_CASH_CARD_DETAILS_ERROR:
      return {
        ...state,
        cashCardDetails: [],
        cashCardDetailsLoading: false,
        cashCardDetailsError: action.payload,
        cashCardTopUpSelection: initialState.cashCardTopUpSelection
      };
    case SET_CASHCARD_TOPUP_AMOUNT:
      return {
        ...state,
        cashCardTopUpSelection: {
          ...state.cashCardTopUpSelection,
          amount: !action?.payload ? 10 : action.payload
        }
      };
    case SET_CASHCARD_BUY_AMOUNT:
      return {
        ...state,
        cashCardBuySelection: {
          ...state.cashCardBuySelection,
          amount: !action?.payload ? 10 : action.payload
        }
      };
    case SET_CASHCARD_NAME_TO_PRINT:
      return {
        ...state,
        cashCardBuySelection: {
          ...state.cashCardBuySelection,
          nameToPrint: action.payload ?? ""
        }
      };
    case SET_CASHCARD_BUY_QUANTITY:
      let quantity = (!action?.payload || action.payload <= 0)
        ? 1
        : action.payload;
      return {
        ...state,
        cashCardBuySelection: {
          ...state.cashCardBuySelection,
          quantity
        }
      };
    case SET_CASHCARD_REDEMPTION_AMOUNT:
      return {
        ...state,
        cashCardRedemptionSelection: {
          ...state.cashCardRedemptionSelection,
          amount: action.payload
        }
      };
    case FETCH_CASH_CARD_GALLERY:
      return {
        ...state,
        cashCardGallery: [],
        cashCardGalleryLoading: true,
        cashCardGalleryError: null
      };
    case FETCH_CASH_CARD_GALLERY_SUCCESS:
      return {
        ...state,
        cashCardGallery: action.payload,
        cashCardGalleryLoading: false,
        cashCardGalleryError: null
      };
    case FETCH_CASH_CARD_GALLERY_ERROR:
      return {
        ...state,
        cashCardGallery: [],
        cashCardGalleryLoading: false,
        cashCardGalleryError: action?.payload ?? true
      };

    case FETCH_WEBSITE_CASH_CARD:
      return {
        ...state,
        websiteCashCard: null,
        websiteCashCardLoading: true,
        websiteCashCardError: null
      };
    case FETCH_WEBSITE_CASH_CARD_SUCCESS:
      return {
        ...state,
        websiteCashCard: action.payload,
        websiteCashCardLoading: false,
        websiteCashCardError: null
      };
    case FETCH_WEBSITE_CASH_CARD_ERROR:
      return {
        ...state,
        websiteCashCard: null,
        websiteCashCardLoading: false,
        websiteCashCardError: action?.payload ?? true
      };

    case FETCH_CASH_CARD_RESERVATION:
      return {
        ...state,
        cashCardReservation: null,
        cashCardReservationLoading: true,
        cashCardReservationError: null
      };
    case FETCH_CASH_CARD_RESERVATION_SUCCESS:
      return {
        ...state,
        cashCardReservation: action.payload,
        cashCardReservationLoading: false,
        cashCardReservationError: null
      };
    case FETCH_CASH_CARD_RESERVATION_ERROR:
      return {
        ...state,
        cashCardReservation: null,
        cashCardReservationLoading: false,
        cashCardReservationError: action.payload ?? true
      };

    case FETCH_CASH_CARD_BUY_TRX:
      return {
        ...state,
        cashCardBuyTrx: null,
        cashCardBuyTrxLoading: true,
        cashCardReservationError: null
      };
    case FETCH_CASH_CARD_BUY_TRX_SUCCESS:
      return {
        ...state,
        cashCardBuyTrx: action.payload,
        cashCardBuyTrxLoading: false,
        cashCardReservationError: null
      };
    case FETCH_CASH_CARD_BUY_TRX_ERROR:
      return {
        ...state,
        cashCardBuyTrx: null,
        cashCardBuyTrxLoading: false,
        cashCardReservationError: action.payload ?? true
      };
    default:
      return state;
  }
};

export default cashCardReducer;
