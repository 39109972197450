import React from "react";
import { withNamespaces } from "react-i18next";

// Images
import { ReactComponent as ScreenSl } from "@assets/pictures/svgs/screen-sl.svg";
import { ReactComponent as ScreenS2} from "@assets/pictures/svgs/screen-s2-gold.svg";
const SeatPlanScreen = (props) => {
    const {t, classes,isPrivate = false} = props;
    return (<>
        <div className={`seat_plan_screen ${classes}`}>

                  <span className={isPrivate ? "isPrivate" : "!isPrivate"}>{t("Screen")}</span>
                  {
                      isPrivate 
                      ? <ScreenS2 className="prvt-svg" />
                      : <ScreenSl className="nowshowing-svg" /> 
                  }
                </div>
    </>);
}

// Default Props
SeatPlanScreen.defaultProps = {
    classes: "",
};
 
export default withNamespaces()(SeatPlanScreen);