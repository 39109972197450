import { withNamespaces } from "react-i18next";
import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import {
  fetchTodaysScreenings,
  fetchCreateOwnShow,
  fetchComingSoon,
  fetchFormatList,
  fetchLanguageList,
  fetchFestivalList,
  fetchMiniFestivals,
  fetchBanners
} from "../store/homepage/homepageActions";

// Components
import Banner from "@components/partials/Banner";
import MoviesNFestsNew from "@components/partials/MoviesNFestsNew";
import OnDemandInfo from "@components/partials/OnDemandInfo";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useIsMobile from "../customHooks/useIsMobile";
import useIsDesktop from "../customHooks/useIsDesktop";
import { getLangSpecificAttribute } from "@helper/languages";
import { Dropdown, Modal, Spinner } from "react-bootstrap";
import SpinnerComp from "./partials/Spinner";

// Images

const ShowMovieListStacked = ({ t }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { category_id, section_id } = useParams()

  const createOwnShowMovies = useSelector(
    (state) => state.homepage.createOwnShow
  );
  const todaysScreeningsMovies = useSelector(
    (state) => state.homepage.todaysScreenings
  );
  const comingSoonMovies = useSelector((state) => state.homepage.comingSoon);

  const organization = useSelector((state) => state.movies.organizationDetails);
  const selected_language = useSelector(
    (state) => state.movies.selected_language
  );
  const { iso_2, lang_id, lang_name } = selected_language;

  const uniqueCreateOwnShowMovies = {
    ...createOwnShowMovies,
    data: [
      ...new Map(
        createOwnShowMovies?.data?.map((item) => [item["movie_id"], item])
      ).values(),
    ],
  };

  const uniqueTodaysScreeningMovies = {
    ...todaysScreeningsMovies,
    data: [
      ...new Map(
        todaysScreeningsMovies?.data?.map((item) => [item["movie_id"], item])
      ).values(),
    ],
  };

  const uniqueComingSoonMovies = {
    ...comingSoonMovies,
    data: [
      ...new Map(
        comingSoonMovies?.data?.map((item) => [item["movie_id"], item])
      ).values(),
    ],
  };

  const miniFestivalsData = useSelector(
    (state) => state.homepage.miniFestivals
  );

  const createOwnShowLoading = useSelector(
    (state) => state.homepage.createOwnShowLoading
  );

  const miniFestivalsLoading = useSelector(
    (state) => state.homepage.miniFestivalsLoading
  );

  const onDemandLabeledMovies = useSelector(
    (state) => state.homepage.onDemandLabeledMovies
  );

  const todaysScreeningsLoading = useSelector(
    (state) => state.homepage.todaysScreeningsLoading
  );

  const [loader, setLoader] = useState(true);
  useEffect(() => {
    if (!!category_id) {
      // now_showing
      if (category_id == 1) {
        dispatch({ type: `FETCH_TODAYS_SCREENINGS` });
        dispatch(
          fetchTodaysScreenings({
            limit: 1000,
            currentPage: 1
          })
        )
        .finally(() => {
          setLoader(false);
        });
      }
      // on_demand
      else if (category_id == 2) {
        dispatch({ type: `FETCH_CREATE_OWN_SHOW` });
        dispatch(
          fetchCreateOwnShow({
            limit: 1000,
            currentPage: 1
          })
        )
        .finally(() => {
          setLoader(false);
        });
      }
      // show_upcoming
      else if (category_id == 3) {
        dispatch({ type: `FETCH_COMING_SOON` });
        dispatch(
          fetchComingSoon({
            limit: 1000,
            currentPage: 1
          })
        )
        .finally(() => {
          setLoader(false);
        });
      }
      // miniFestivals
      else if (category_id == 4) {
        dispatch({ type: "FETCH_MINI_FESTIVALS" });
        dispatch(
          fetchMiniFestivals({
            currentPage: 1,
            index: null,
            category_link_name: null,
            category_id: 4,
            unique_id: section_id || undefined
          })
        )
        .finally(() => {
          setLoader(false);
        });
      }
      // not found
      else {
        setLoader(false);
      }
    }
  }, [category_id, section_id]);

  if ((loader || createOwnShowLoading || miniFestivalsLoading || todaysScreeningsLoading))
    return (<div className="row">
      <div className="col-12 text-center">
        <SpinnerComp />
      </div>
    </div>);

  return (
    <>
      <div className="container-fluid mt-4">

        {category_id == 4 && (<>
        {/* Movie Sections */}
        {miniFestivalsData?.Records?.length > 0 &&
          miniFestivalsData?.Records?.filter(x => x.unique_id == section_id).map((category, index) => (
          category?.movie_data?.data?.length > 0 && (<MoviesNFestsNew key={index} section={2} moviesArray={category?.movie_data?.data} subCategory={getLangSpecificAttribute(
            category?.language_content,
            lang_id,
            "event_name",
            "lang_id"
          ) || category?.category_link_name} category={t("On Demand")} type="on_demand" movieCount={category?.movie_data?.data?.length} />)
        ))}

          {miniFestivalsData?.Records?.length === 0 && (<>
            <section className="row">
              <div className="col-12 text-center">
                <h6 className="mx-auto mb-0">
                  {t("common.No movies found")}
                </h6>
              </div>
            </section>
          </>)}
        </>)}

        {category_id == 3 && (<>
        {uniqueComingSoonMovies?.data?.length > 0 && (
          <MoviesNFestsNew  section={4} type="show_upcoming" moviesArray={uniqueComingSoonMovies?.data} subCategory={""} category={t("Upcoming")} movieCount={uniqueComingSoonMovies?.data?.length}  />
        )}

          {uniqueComingSoonMovies?.data?.length === 0 && (<>
            <section className="row">
              <div className="col-12 text-center">
                <h6 className="mx-auto mb-0">
                  {t("common.No movies found")}
                </h6>
              </div>
            </section>
          </>)}
        </>)}

        {category_id == 2 && (<>
        {uniqueCreateOwnShowMovies?.data?.length > 0 && (
          <MoviesNFestsNew  section={2} type="on_demand" moviesArray={uniqueCreateOwnShowMovies?.data} subCategory={""} category={t("On Demand")} movieCount={uniqueCreateOwnShowMovies?.data?.length}  />
        )}

          {uniqueCreateOwnShowMovies?.data?.length === 0 && (<>
            <section className="row">
              <div className="col-12 text-center">
                <h6 className="mx-auto mb-0">
                  {t("common.No movies found")}
                </h6>
              </div>
            </section>
          </>)}
        </>)}

        {category_id == 1 && (<>
         {uniqueTodaysScreeningMovies?.data?.length > 0 && (
            <MoviesNFestsNew  section={1} moviesArray={uniqueTodaysScreeningMovies?.data} subCategory={''} category={t("Now Showing")} movieCount={uniqueTodaysScreeningMovies?.data?.length} type="now_showing" />
          )}

          {uniqueTodaysScreeningMovies?.data?.length === 0 && (<>
            <section className="row">
              <div className="col-12 text-center">
                <h6 className="mx-auto mb-0">
                  {t("common.No movies found")}
                </h6>
              </div>
            </section>
          </>)}
        </>)}

      </div>
    </>);
}

export default withNamespaces()(ShowMovieListStacked);
