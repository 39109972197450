import MovieService from "@apiService/MovieService";
import GenericService from "@apiService/GenericService";
import {
  FETCH_TODAYS_SCREENINGS,
  FETCH_TODAYS_SCREENINGS_SUCCESS,
  FETCH_LABELED_MOVIES,
  FETCH_TODAYS_SCREENINGS_ERROR,
  FETCH_CREATE_OWN_SHOW,
  FETCH_CREATE_OWN_SHOW_SUCCESS,
  FETCH_LABELED_ONDEMAND_MOVIES,
  FETCH_CREATE_OWN_SHOW_ERROR,
  FETCH_COMING_SOON,
  FETCH_COMING_SOON_SUCCESS,
  FETCH_COMING_SOON_ERROR,
  FETCH_FORMAT_LIST,
  FETCH_FORMAT_LIST_SUCCESS,
  FETCH_FORMAT_LIST_ERROR,
  FETCH_LANGUAGE_LIST,
  FETCH_LANGUAGE_LIST_SUCCESS,
  FETCH_LANGUAGE_LIST_ERROR,
  FETCH_FESTIVAL_LIST,
  FETCH_FESTIVAL_LIST_SUCCESS,
  FETCH_FESTIVAL_LIST_ERROR,
  FETCH_BANNERS,
  FETCH_BANNERS_SUCCESS,
  FETCH_BANNERS_ERROR,
  SEARCH_MOVIE,
  SEARCH_MOVIE_SUCCESS,
  SEARCH_MOVIE_ERROR,
  QUICK_BOOK,
  QUICK_BOOK_MOVIE,
  QUICK_BOOK_MOVIE_ERROR,
  QUICK_BOOK_MOVIE_SUCCESS,
  QUICK_BOOK_LANG_SUCCESS,
  QUICK_BOOK_FORMAT_SUCCESS,
  QUICK_BOOK_CINEMA_SUCCESS,
  QUICK_BOOK_DATE_SUCCESS,
  QUICK_BOOK_TIME_SUCCESS,
  QUICK_BOOK_ERROR,
  FETCH_FILTERS,
  FETCH_FILTERS_SUCCESS,
  FETCH_FILTERS_ERROR,
  SET_FILTER,
  SET_QB_STAGE,
  FETCH_MINI_FESTIVALS,
  FETCH_MINI_FESTIVALS_SUCCESS,
  FETCH_MINI_FESTIVALS_ERROR,
  SET_SELECTED_MINI_FESTIVAL,
  FETCH_CMS,
  FETCH_CMS_SUCCESS,
  FETCH_CMS_ERROR,
  SET_ON_DEMAND_CMS,
  SET_SOCIALMEDIA_CMS,
  SET_CMS_SETTINGS,
  SET_HEADERS_CMS,
  SET_FOOTER_CMS,
  SET_MOVIE_CMS,
} from "./homepageTypes";

export const fetchTodaysScreenings = (payload) => async (dispatch) => {
  try {
    // dispatch({ type: FETCH_TODAYS_SCREENINGS });
    const { data } = await MovieService.GetTodaysScreening(payload);
    if (data.status && data.Records.data.length > 0) {
      dispatch({
        type: FETCH_TODAYS_SCREENINGS_SUCCESS,
        payload: data.Records,
      });
      if (data.groupedData) {
        dispatch({
          type: FETCH_LABELED_MOVIES,
          payload: data.groupedData,
        });
      }
    } else {
      dispatch({ type: FETCH_TODAYS_SCREENINGS_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: FETCH_TODAYS_SCREENINGS_ERROR, payload: error });
  }
};

export const fetchCreateOwnShow = (payload) => async (dispatch) => {
  try {
    payload.limit = 1000
    payload.onDemand = true;
    if (localStorage.getItem("user")) {
      payload.isUserLogged = "Y";
    } else {
      payload.isUserLogged = "N";
    }
    // dispatch({ type: FETCH_CREATE_OWN_SHOW });
    const { data } = await MovieService.GetCreateOwnShow(payload);
    if (data?.status && data?.Records?.data?.length > 0) {
      dispatch({
        type: FETCH_CREATE_OWN_SHOW_SUCCESS,
        payload: data?.Records,
      });
      if (data?.groupedData) {
        dispatch({
          type: FETCH_LABELED_ONDEMAND_MOVIES,
          payload: data.groupedData,
        });
      }
    } else {
      // dispatch({ type: FETCH_CREATE_OWN_SHOW_ERROR, payload: data.message });
      dispatch({
        type: FETCH_CREATE_OWN_SHOW_SUCCESS,
        payload: data?.Records,
      });
    }
  } catch (error) {
    dispatch({ type: FETCH_CREATE_OWN_SHOW_ERROR, payload: error });
  }
};

export const fetchComingSoon = (payload) => async (dispatch) => {
  try {
    // dispatch({ type: FETCH_COMING_SOON });
    const { data } = await MovieService.GetComingSoon(payload);
    if (data.status && data.Records.data.length > 1) {
      dispatch({
        type: FETCH_COMING_SOON_SUCCESS,
        payload: data.Records,
      });
    } else {
      dispatch({
        type: FETCH_COMING_SOON_SUCCESS,
        payload: data.Records,
      });
    }
  } catch (error) {
    dispatch({ type: FETCH_COMING_SOON_ERROR, payload: error });
  }
};

export const fetchFormatList = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_FORMAT_LIST });
    const { data } = await MovieService.GetFormatList(payload);
    if (data.status && data.Records.data.length > 0) {
      dispatch({
        type: FETCH_FORMAT_LIST_SUCCESS,
        payload: data.Records.data,
      });
    } else {
      dispatch({ type: FETCH_FORMAT_LIST_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: FETCH_FORMAT_LIST_ERROR, payload: error });
  }
};

export const fetchLanguageList = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_LANGUAGE_LIST });
    const { data } = await MovieService.GetLanguageList(payload);
    if (data.status && data.Records.data.length > 0) {
      dispatch({
        type: FETCH_LANGUAGE_LIST_SUCCESS,
        payload: data.Records.data,
      });
    } else {
      dispatch({ type: FETCH_LANGUAGE_LIST_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: FETCH_LANGUAGE_LIST_ERROR, payload: error });
  }
};

export const fetchFestivalList = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_FESTIVAL_LIST });
    const { data } = await MovieService.GetFestivalList(payload);
    if (data.status && data.Records.data.length > 0) {
      dispatch({
        type: FETCH_FESTIVAL_LIST_SUCCESS,
        payload: data.Records,
      });
    } else {
      dispatch({ type: FETCH_FESTIVAL_LIST_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: FETCH_FESTIVAL_LIST_ERROR, payload: error });
  }
};

export const fetchBanners = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_BANNERS });
    const { data } = await MovieService.GetBanners(payload);
    if (data.status && data.Records.length > 0) {
      dispatch({
        type: FETCH_BANNERS_SUCCESS,
        payload: data.Records,
      });
    } else {
      dispatch({ type: FETCH_BANNERS_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: FETCH_BANNERS_ERROR, payload: error });
  }
};

export const quickBookMovieList = (payload) => async (dispatch) => {
  try {
    dispatch({ type: QUICK_BOOK_MOVIE });
    const { data } = await MovieService.QuickBookMovieList(payload);
    if (data.status && data.Records.data.length > 0) {
      dispatch({
        type: QUICK_BOOK_MOVIE_SUCCESS,
        payload: data.Records.data,
      });
      // if (payload?.type === `quickBook`) {
      //   dispatch({
      //     type: QUICK_BOOK_MOVIE_SUCCESS,
      //     payload: data.Records.data,
      //   });
      // }
    } else {
      dispatch({ type: QUICK_BOOK_MOVIE_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: QUICK_BOOK_MOVIE_ERROR, payload: error });
  }
};

export const setSearchMovies = (payload) => ({
  type: SEARCH_MOVIE_SUCCESS,
  payload,
});

export const searchMovie = (payload) => async (dispatch) => {
  try {
    dispatch({ type: SEARCH_MOVIE });
    const { data } = await MovieService.SearchMovie(payload);
    if (data.status && data.Records.data.length > 0) {
      dispatch(setSearchMovies(data.Records.data));
      if (payload?.type === `quickBook`) {
        dispatch({
          type: QUICK_BOOK_MOVIE_SUCCESS,
          payload: data.Records.data,
        });
      }
    } else {
      dispatch({ type: SEARCH_MOVIE_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: SEARCH_MOVIE_ERROR, payload: error });
  }
};

export const quickBook = ({
  movie_id,
  lang_id,
  mf_id,
  cinema_id,
  fromDate,
}) => async (dispatch, getState) => {
  // dispatch({ type: QUICK_BOOK });
  try {
    const { data } = await MovieService.QuickBook({
      movie_id,
      lang_id,
      mf_id,
      cinema_id,
      fromDate,
    });
    if (data.status) {
      const { qbStage } = getState().homepage;
      if (movie_id && qbStage === 1) {
        dispatch({ type: QUICK_BOOK_LANG_SUCCESS, payload: data.Records.data });
      }
      if (lang_id && qbStage === 2) {
        dispatch({
          type: QUICK_BOOK_FORMAT_SUCCESS,
          payload: data.Records.data,
        });
      }
      if (mf_id && qbStage === 3) {
        dispatch({
          type: QUICK_BOOK_CINEMA_SUCCESS,
          payload: data.Records.data,
        });
      }
      if (cinema_id && qbStage === 4) {
        dispatch({ type: QUICK_BOOK_DATE_SUCCESS, payload: data.Records.data });
      }
      if (fromDate && qbStage === 5) {
        dispatch({ type: QUICK_BOOK_TIME_SUCCESS, payload: data.Records.data });
      }
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: QUICK_BOOK_ERROR, payload: error });
  }
};

export const fetchFilters = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_FILTERS });
    const { data } = await MovieService.GetFilters(payload);
    if (data.status && data.filterData.length > 0) {
      dispatch({
        type: FETCH_FILTERS_SUCCESS,
        payload: data.filterData,
      });
    } else {
      dispatch({ type: FETCH_FILTERS_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: FETCH_FILTERS_ERROR, payload: error });
  }
};

export const setFilter = (filter) => async (dispatch) => {
  try {
    dispatch({ type: SET_FILTER, payload: filter });
  } catch (error) {
    console.error(error);
  }
};

export const setQbStage = (payload) => async (dispatch) =>
  dispatch({ type: SET_QB_STAGE, payload });

export const fetchMiniFestivals = ({
  currentPage,
  category_link_name,
  index,
  category_id = null
}) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_MINI_FESTIVALS });
    const { data } = await MovieService.GetMiniFestivals({
      currentPage,
      category_link_name,
      category_id
    });
    if (data.status) {
      dispatch({
        type: FETCH_MINI_FESTIVALS_SUCCESS,
        payload: { data, index },
      });
    } else {
      dispatch({ type: FETCH_MINI_FESTIVALS_ERROR, payload: data.message });
    }
  } catch (error) {
    console.log("🚀 ~ file: movieActions.js ~ line 1585 ~ error", error);
    dispatch({ type: FETCH_MINI_FESTIVALS_ERROR, payload: error });
  }
};

export const setSelectedMiniFestival = (festival) => async (dispatch) =>
  dispatch({ type: SET_SELECTED_MINI_FESTIVAL, payload: festival });

export const fetchSectionCMS = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_CMS });
    const { data } = await GenericService.GetSectionCMS(payload);
    if (data.status && data.Records.length > 0) {
      console.log("data fetchSectionCMS", data.Records);
      data.Records.map((x) => {
        if (x.section_id == 1) {
          dispatch({
            type: SET_ON_DEMAND_CMS,
            payload: x.data.keydata,
          });
        } else if (x.section_id == 2) {
          dispatch({
            type: SET_SOCIALMEDIA_CMS,
            payload: x.data.keydata,
          });
        } else if (x.section_id == 3) {
          dispatch({
            type: SET_CMS_SETTINGS,
            payload: x.data.keydata,
          });
        } else if (x.section_id == 4) {
          dispatch({
            type: SET_HEADERS_CMS,
            payload: x.data.keydata,
          });
        } else if (x.section_id == 5) {
          dispatch({
            type: SET_FOOTER_CMS,
            payload: x.data.keydata,
          });
        } else if (x.section_id == 6) {
          dispatch({
            type: SET_MOVIE_CMS,
            payload: x.data.keydata,
          });
        }
      });
      dispatch({
        type: FETCH_CMS_SUCCESS,
        payload: data.Records,
      });
    } else {
      dispatch({ type: FETCH_CMS_ERROR, payload: data.message });
    }
  } catch (error) {
    dispatch({ type: FETCH_CMS_ERROR, payload: error });
  }
};
